import React, { useEffect, useReducer, useState } from "react";
import icEye from "../../../images/ic-eye.svg";
import { DataGrid } from "@mui/x-data-grid";
import Header from "../Citations-Header/container";
import OperatorHeader from "../../../components/common/Header/container";
import leftArrow from "../../../images/left-arrow.svg";
import DatePicker from "react-datepicker";
import { useNavigate, useLocation } from "react-router-dom";
import Download from "../../../components/svgIcons/Download";
import moment from "moment-timezone";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../store/actioncreator";
import { Pagination } from "antd";
import DetailActivity from "./detail/DetailActivity";
import ClearDates from "../../../utils/clearDates/ClearDates";

const ACTIVITIES_HEADERS = [
  "Taggr (1099)",
  "Lot Name",
  "Lot Address",
  "Check-In",
  "Check-Out",
  "Searched LP",
  "Tagged LP",
];

const formatField = (field) => {
  return field && field.includes(",") ? `"${field}"` : field;
};

function generateActivitiesCSVData(data, tz) {
  return [
    formatField(data?.fullname ?? "-"),
    formatField(data?.lotData?.lotname ?? "-"),
    formatField(data?.lotData?.address ?? "-"),
    moment(data?.checkIn)?.tz(tz)?.format("h:mm:ss A MM-DD-YY") ?? "-",
    data?.isEndSession ? moment(data?.checkOut)?.tz(tz)?.format("h:mm:ss A MM-DD-YY") : "N/A",
    data?.totalSearchedLicensePlates ?? 0,
    data?.totalTaggedLicensePlates ?? 0,
  ];
}

const Taggr_activities = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const tz = moment.tz.guess();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [detailActivity, setDetailActivity] = useState(null);
  const [showDetailActivity, setShowDetailActivity] = useState(false);
  const [user_role, set_user_role] = useState(null)
  const [lots, setLots] = useState([]);
  const [selectedLot, setSelectedLot] = useState("All")

  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10,
    activities: [],
  };

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage && next?.isPagechange)
      getTaggrActivities(next?.currentPage);
    return { ...prev, ...next };
  }, initialPageState);

  const columns = [
    {
      field: "fullname",
      headerName: "Taggr (1099)",
      width: 250,
      hideable: false,
      editable: false,
      pinned: "left",
    },
    {
      field: "lotData",
      headerName: "Lot Address",
      width: 280,
      valueGetter: (params) => { return params?.address || ''},
    },
    {
      field: "checkIn",
      headerName: "Check-In",
      width: 200,
      valueFormatter: (params) =>
        moment(params)?.tz(tz)?.format("h:mm:ss A MM-DD-YY"),
    },
    {
      field: "checkOut",
      headerName: "Check-out",
      width: 200,
      valueGetter: (params, data) => data?.isEndSession ? moment(params)?.tz(tz).format("h:mm:ss A MM-DD-YY") : "N/A"
    },
    {
      field: "totalSearchedLicensePlates",
      headerName: "Searched LP",
      width: 200,
    },
    {
      field: "totalTaggedLicensePlates",
      headerName: "Tagged LP",
      width: 200,
    },
    {
      field: "",
      headerName: "Actions",
      width: 200,
      renderCell: (data) => (
        <button
          className="btn"
          onClick={() => {
            setDetailActivity(data?.row);
            setShowDetailActivity(true);
            navigate(`/taggr_activities?q=${data?.row?.userId}`);
          }}
        >
          View
        </button>
      ),
    },
  ];

  function handleSubmit() {
    if (!startDate || !endDate) {
      dispatch(setAlert("error", "Please select start date and end date"));
    } else {
      dispatch(setLoading(true));
      getTaggrActivities(1);
    }
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 300);
  }

  function clearDates() {
    setStartDate(null)
    setendDate(null)
    dispatch(setLoading(true));
    getTaggrActivities(1, selectedLot, false);
    setTimeout(() => {
      dispatch(setLoading(false));
    }, 300);
  }

  const getLotList = async (id, role) => {
    const {value} = await props.getAllLotsList({id, role});
    console.log(value, "<<--lots")
    if (value?.lots) {
      setLots(value?.lots);
    }
  };

  const fetchMe = async () => {
    try {
      const { value } = await props.user();
      if (value?.success) {
          set_user_role(value?.user?.role);
          getLotList(value?.user?._id, value?.user?.role);
      }
      else {
        dispatch(setAlert('error', value?.message))
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  };

  useEffect(() => {
    fetchMe()
    const queryParams = new URLSearchParams(location?.search);
    const hasQuery = queryParams.has("q");
    if (!hasQuery) {
      setShowDetailActivity(false);
      getTaggrActivities(1);
    }
  }, [location]);

  async function getTaggrActivities(page, lotId, dateFilter=true) {
    dispatch(setLoading(true));
    try {
      const { value } = await props?.getTaggrActivities({
        page,
        size: 10,
        startDate: dateFilter ? startDate : null,
        endDate: dateFilter ? endDate : null,
        lotId: lotId?.length > 0 ? lotId : selectedLot
      });
      if (value?.success) {
        setPageState({ total: value?.total, activities: value?.activities });
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  }

  const getCurrentDateTime = () => {
    const now = moment().tz(tz);
    return now.format("YYYY-MM-DD_HH-mm-ss");
  };

  async function getCSVReport() {
    try {
      const { value } = await props?.getTaggrActivities({ startDate, endDate, lotId: selectedLot });
      if (value?.success) {
        const data = [
          ACTIVITIES_HEADERS,
          ...value?.activities?.map((activity) =>
            generateActivitiesCSVData(activity, tz)
          ),
        ];
        const csv = data.map((row) => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        const reportType = "Taggr Activities";
        link.download = `${reportType}-${getCurrentDateTime()}.csv`;
        link.click();
        URL.revokeObjectURL(url);
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  }

  return (
    <>
      <section className="adminDashboard">
        {user_role === "operator" || user_role === "manager" ? <OperatorHeader /> : <Header />}
        <div
          className="adminreport-wrapper taggr-list-wrapper"
          id="profile-wrapper"
        >
          {showDetailActivity ? (
            <>
              <div className="d-flex align-items-center">
                <img
                  src={leftArrow}
                  className="left-arrow"
                  onClick={() => {
                    setShowDetailActivity(false);
                    navigate("/taggr_activities"); // Navigate back to the main list
                  }}
                />
                <h4>Activities</h4>
              </div>
              <DetailActivity {...detailActivity} />
            </>
          ) : (
            <>
              <div className="report-list-wrapper secondndtab">
                <div className="pd-block">
                  <div className="date-picker-block">
                    <div className="d-flex align-items-center border-block">
                      <div className="date-picker-list">
                        <label>Start Date</label>
                        <DatePicker
                          selected={startDate}
                          onChange={(date) =>
                            setStartDate(moment(date).tz(tz).toDate())
                          }
                          placeholderText="--/--/----"
                          className="date-picker"
                          maxDate={new Date()}
                        />
                      </div>
                      <div className="date-picker-list">
                        <label>End Date</label>
                        <DatePicker
                          selected={endDate}
                          onChange={(date) => {
                            const newDate = moment(date).tz(tz);
                            newDate.set({ hour: 23, minute: 59, second: 59 });
                            setendDate(newDate.toDate());
                          }}
                          placeholderText="--/--/----"
                          className="date-picker"
                          minDate={startDate}
                          maxDate={new Date()}
                          disabled={!startDate}
                        />
                      </div>
                    </div>
                    <div className="operator-btns">
                      <div className="border-block-btn">
                        <button className="btn DarkBtn" onClick={handleSubmit}>
                          Submit
                        </button>
                      </div>
                        <ClearDates onClick={clearDates} className="me-2"/>
                      <button
                        className="btn DarkBtn report-btn"
                        onClick={async () => {
                          await getCSVReport();
                        }}
                      >
                        <Download />
                        All Report
                      </button>
                      <select
                        className="minimal"
                        value={selectedLot}
                        style={{ minWidth: '100px', maxWidth: '200px'}}
                        onChange={(e) => {
                          setSelectedLot(e.target?.value);
                          getTaggrActivities(1, e.target?.value);
                          console.log("Selected Lot", e.target.value);
                        }}
                      >
                        <option value="All">All</option>
                        {lots?.map((lot, idx) => (
                          <option key={idx} value={lot?._id}>
                            {lot?.address}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="table-block mt-3 ">
                    <div className="table-responsive">
                      <DataGrid
                        rows={pageState?.activities}
                        columns={columns}
                        pageSize={pageState.pageSize}
                        rowsPerPageOptions={[10]}
                        hideFooter
                        pagination
                        onPageChange={(params) =>
                          setPageState({ currentPage: params.page })
                        }
                        rowCount={pageState.total}
                        paginationMode="server"
                        loading={props.loading}
                        getRowId={(row) => row?.checkInId}
                        sx={{ minHeight: 400 }}
                      />
                    </div>
                    <div className="pagination-block">
                      <Pagination
                        //  hideOnSinglePage
                        defaultCurrent={pageState?.defaultCurrentPage}
                        onChange={(page) =>
                          setPageState({
                            currentPage: page,
                            isPagechange: true,
                          })
                        }
                        current={pageState?.currentPage}
                        pageSize={pageState?.pageSize}
                        total={pageState?.total}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Taggr_activities;
