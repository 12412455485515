import { connect } from "react-redux";
import { logins, user, citation_reconcilation, searchallCitations, refund_citation, void_citation, send_sms } from "../../../store/home/duck";
import PayoutCitationsComponent from "./payout_citation_component";

const PayoutCitationsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    citation_reconcilation,
    searchallCitations,
    refund_citation,
    void_citation,
    send_sms
  }
)(PayoutCitationsComponent);

export default PayoutCitationsContainer;
