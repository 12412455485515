import fetch from "isomorphic-fetch";

const handleHTTPErrors = (res) => {
  if (res.ok) return res;
  return res.json().then((err) => {
    throw err;
  });
};

export default function fetchUrl(url, options) {
  const jwtToken = localStorage.getItem("token");
  if (jwtToken) {
    let authAddedOptions = options;
    if (typeof options !== "object") {
      authAddedOptions = {};
    }
    if (typeof authAddedOptions.headers !== "object") {
      authAddedOptions.headers = {};
    }
    authAddedOptions.headers = {
      ...authAddedOptions.headers,
      Authorization: "Bearer " + jwtToken,
    };
    return fetch(url, authAddedOptions).then(handleHTTPErrors);
  } else {
    return fetch(url, options).then(handleHTTPErrors);
  }
}
