import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useLocation, Navigate } from "react-router-dom";
import imgProfile from "../../../images/img-profile.png";
import brandLogo from "../../../images/brand-logo.svg";
import icMenu from "../../../images/ic-menu.svg";
import logoutIcon from "../../../images/logout-icon.svg";
import passsword from "../../../images/password.svg";
import { useDispatch } from "react-redux";
import { setAlert } from "../../../store/actioncreator";
import "./styles.scss";
import { throttleOnce } from "../../../global";

const Header = (props) => {

  const dispatch = useDispatch()
  const location = useLocation()
  const pathname = location?.pathname?.split("/")[1]

  const arr = ["/traininglesson", "/quiz", "/quizretake"];
  const getlocation = window.location.pathname;
  const isShow = arr.includes(getlocation);
  const [show, setShow] = useState(false);
  const [menuopen, setmenu] = useState(false);
  const [name, setName] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchName();
  }, []);


  const fetchName = useCallback(
    throttleOnce(
      async () => {
        try {
          const { value } = await props.user();
          if (value?.success) {
            if (value?.user?.role === "superAdmin" || value?.user?.role === "admin" || value?.user?.role === "callcenter" || value?.user?.role === "operator" || value?.user?.role === "manager") {
              setName(value);
            }
            else {
              navigate("/login")
            }
    
          }
          else {
            if (value?.message === "Authentication token expired | Unauthorized") {
              navigate("/login")
            }
            dispatch(setAlert('error', value?.message))
          }
        } catch (error) {
          dispatch(setAlert('error', error?.message))
        }
      }, 1000
    ), [props]
  )
 ;

  const toggleMenu = () => {
    var element = document.getElementById("profile-wrapper");
    var menu = document.getElementById("test_menu");
    console.log("TOGGLING MENU to " , !menu.classList.contains("showmenu") )
    if (menu.classList.contains("showmenu")) {
      element.classList.add("left-wrapper");
      menu.classList.remove("showmenu");
    } else {
      element.classList.remove("left-wrapper");
      menu.classList.add("showmenu");
    }
  }

  const showMenu = () => {
    console.log("TOGGLING MENU to show SHOWMENU")
    var element = document.getElementById("profile-wrapper");
    var menu = document.getElementById("test_menu");
    element.classList.remove("left-wrapper");
    menu.classList.add("showmenu");
  }
  
  const hideMenu = () => {
    console.log("TOGGLING MENU to hide HIDEMENU")
    var element = document.getElementById("profile-wrapper");
    var menu = document.getElementById("test_menu");
    element.classList.add("left-wrapper");
    menu.classList.remove("showmenu");
  }

  const hideMenuWithDelay = () =>{
    setTimeout(() => hideMenu(), 100)
  }

  useEffect(() => {

    const checkAuth = localStorage.getItem('token')
    if (checkAuth === null) {
      window.location.pathname = '/login'
    }
    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(navigator.userAgent)) {

    //   showMenu()
    // }
    var a = document.querySelectorAll(".navbar-nav a");
    for (var i = 0, length = a.length; i < length; i++) {
      a[i].onclick = function () {
        var b = document.querySelector(".navbar-nav li.active");
        // if (b) b.classList.remove("active");
        // this.parentNode.classList.add("active");
      };
    }
  }, []);

  const logout = () => {
    localStorage.removeItem('token');
  };

  const tireTagMenuLink = (
    <li className={pathname === "tire-tags" ? "nav-item active" : "nav-item"} >
      <Link
        className="nav-link"
        to="/tire-tags"
      >
        Tire Tags
      </Link>
    </li>
  );

  const TAGGR_MENU_LINK = (
    <li className={pathname === "1099" ? "nav-item active" : "nav-item"}>
      <Link onClickCapture={hideMenuWithDelay} className="nav-link " to="/1099">
        1099
      </Link>
    </li>
  )

  const REFUND_REQUEST_LINK = (
    <li className={pathname === "requests" ? "nav-item active" : "nav-item"}>
      <Link className="nav-link " to="/requests">
        Refund/Void Requests
      </Link>
    </li>
  )

  return (
    <>
      <header className="Header">
        <nav className="navbar  navbar-dark">
          <div className="container-fluid p-0">
            <div
              onClick={toggleMenu}
              id="test_menu"
              className="primary-menu-container showmenu"
            >
              <div className="sidebar-container" onClick={(e) => e.stopPropagation()}>
                <ul className="navbar-nav me-auto">
                  {
                    name?.user?.role !== "callcenter" ?
                      <>
                        <li className={pathname === "admin" ? "nav-item active" : "nav-item"} id="operators" onClick={hideMenuWithDelay} >
                          <Link
                            className="nav-link "
                            to="/admin"
                          >
                            Dashboard
                          </Link>
                        </li>
                        <li className={pathname === "operators" ? "nav-item active" : "nav-item"} onClick={hideMenuWithDelay} >
                          <Link
                            className="nav-link "
                            to="/operators"
                          >
                            Operator
                          </Link>
                        </li>
                        <li className={pathname === "1099" ? "nav-item active" : "nav-item"}>
                          <Link
                          onClickCapture={hideMenuWithDelay}

                            className="nav-link "
                            to="/1099"
                          >
                            1099
                          </Link>
                        </li>
                        <li className={pathname === "adminreport" ? "nav-item active" : "nav-item"}
                          onClick={hideMenuWithDelay}
                        >
                          <Link
                            className="nav-link "
                            to="/adminreport"
                          >
                            Report
                          </Link>
                        </li>
                        <li className={pathname === "payouts" ? "nav-item active" : "nav-item"}
                          onClick={hideMenuWithDelay}
                        >
                          <Link
                            className="nav-link "
                            to="/payouts"
                          >
                            Payouts
                          </Link>
                        </li>

                        {/* <li className={pathname === "old-payouts" ? "nav-item active" : "nav-item"}
                          onClick={hideMenuWithDelay}
                        >
                          <Link
                            className="nav-link "
                            to="/old-payouts"
                          >
                            Old Payouts
                          </Link>
                        </li> */}
                        { name?.user?.role === "superAdmin" && 
                          <li className={pathname === "admincitations" ? "nav-item active" : "nav-item"}
                          onClick={hideMenuWithDelay}
                        >
                          <Link
                            className="nav-link "
                            to="/admincitations"
                          >
                            Tags Deployed
                          </Link>
                        </li>}
                        { name?.user?.role === "superAdmin" && 
                        <li className={pathname === "current-payout" ? "nav-item active" : "nav-item"}
                          onClick={hideMenuWithDelay}
                        >
                          <Link
                            className="nav-link "
                            to="/current-payout"
                          >
                            Current Payouts
                          </Link>
                        </li>}
                        { name?.user?.role === "operator" &&
                        <li className={pathname === "operator-current-payout" ? "nav-item active" : "nav-item"}>
                          <Link className="nav-link" to="/operator-current-payout">
                            Current Payout
                          </Link>
                        </li>
                        }
                        {/* <li className={pathname === "admin-mail-in-citations" ? "nav-item active" : "nav-item"}
                          onClick={hideMenuWithDelay}
                        >
                          <Link
                            className="nav-link "
                            to="/admin-mail-in-citations"
                          >
                            Mail-In Tags Deployed
                          </Link>
                        </li> */}
                        {(name?.user?.role === "superAdmin" || name?.user?.role === "admin") && (
                          <li
                            className={
                              pathname === "inventory" ? "nav-item active" : "nav-item"
                            }
                          >
                            <Link className="nav-link " to="/inventory">
                              Inventory
                            </Link>
                          </li>
                        )}
                        {name?.user?.role === "superAdmin" && tireTagMenuLink}
                        <li className={pathname === "adminDisputedCitations" ? "nav-item active" : "nav-item"}
                          onClick={hideMenuWithDelay}
                        >
                          <Link
                            className="nav-link "
                            to="/adminDisputedCitations"
                          >
                            Disputed Citations
                          </Link>
                        </li>
                        <li className={pathname === "taggr_activities" ? "nav-item active" : "nav-item"}
                          onClick={hideMenuWithDelay}
                        >
                          <Link
                            className="nav-link "
                            to="/taggr_activities"
                          >
                            Visits
                          </Link>
                        </li>
                        {name?.user?.role === "superAdmin" && REFUND_REQUEST_LINK}
                      </>
                      :
                      <React.Fragment>
                        <li className={pathname === "admincitations" ? "nav-item active" : "nav-item"} >
                          <Link
                            className="nav-link "
                            to="/admincitations"
                          >
                            Tags Deployed
                          </Link>
                        </li>
                        {tireTagMenuLink}
                        {TAGGR_MENU_LINK}
                      </React.Fragment>
                  }
                </ul>
              </div>
            </div>
            <div className="MenuWidth">
              <button
                id="menu--btn"
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mynavbar"
                onClick={toggleMenu}
              >
                <img src={icMenu} alt="" />
              </button>
            </div>
            <a className="navbar-brand">
              {" "}
              <img src={brandLogo} className="BrandLogo" alt="taggr logo" />
            </a>
            <div className="dropdown">
              <button
                type="button"
                className="btn btn-primary dropdown-toggle"
                data-bs-toggle={!isShow ? "dropdown" : ""}
              >
                <div>
                  <img src={name?.user?.avatar} alt="" className="ProfileImage" />
                </div>
                <div className="dropdown-text">
                  <h5>
                    {name?.user?.fullname}
                  </h5>
                  <h6>{name?.user?.email}</h6>
                </div>
                {!isShow && <span className="dropdown-toggle-arrow"></span>}
              </button>
              <ul className="dropdown-menu">
                <li>
                  <Link to="/change_password" className="dropdown-item">
                    Change Password
                    <img src={passsword} alt="" />
                  </Link>
                </li>
                <li>
                  {/* <Link to="/logout" className="dropdown-item">
                    Logout <img src={logoutIcon} alt="" />
                  </Link> */}
                  <Link onClick={logout} to="/login" className="dropdown-item">
                    Logout  <img src={logoutIcon} alt="" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
export default Header;
