import { connect } from "react-redux";
import { lotprovider,user, deleteyourlot, addlots, connectWithStripe ,contactsupport} from "../../store/home/duck";
import ContactSupportComponent from "./component";

const ContactSupportContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    lotprovider,
    user,
    deleteyourlot,
    addlots,
    connectWithStripe,
    contactsupport
  }
)(ContactSupportComponent);

export default ContactSupportContainer;
