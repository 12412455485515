import { connect } from "react-redux";
import { forgotpassw} from "../../store/home/duck";
import PassRecComponent from "./component"

const PasswrdRecvryContainer = connect(
  // Map state to props
  (state) => ({
  }),
  // Map actions to dispatch and props
  {

   forgotpassw,

  }
)(PassRecComponent);

export default PasswrdRecvryContainer;
