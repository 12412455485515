import React, { useEffect, useState, useReducer } from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import { useParams, useNavigate } from "react-router-dom";
import { delayLoading, returnShotLotName } from "../../global";
import leftArrow from "../../images/left-arrow.svg";
import Header from "../../components/common/Header/container";
import Form from "react-bootstrap/Form";
import checkbox from "../../images/Check.svg";
import Plus from "../../images/Plus.svg";
import Minus from "../../images/Minus.png";
import uploadFile from "../../images/upload-file.svg";
import close from "../../images/Close.svg";
import CloseWhite from "../../images/closewhite.svg";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
export default function EditLotComponent(props) {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const [errmessage8, seterrmessage8] = useState()
    const ali = new URLSearchParams(window.location.search)?.get("is_ali");

    const clearerr = () => {
        seterrmessage8(false)
        seterrmessage(false)
        seterrmessage1(false)
        seterrmessagefacilityId(false)
        seterrmessagepmc_locationId(false)

        set_dbcode_blank_error(false)
        set_facilityId_blank_error(false)
        set_pmc_locationId_blank_error(false)
        set_proParkId_blank_error(false)
        set_dropboxDescription_blank_error(false)
        set_name_blank_error(false)
        set_address_blank_error(false)
        set_lotrule_blank_error(false)
        set_holdingfee_blank_error(false)
        set_selectedProviders_blank_error(false)
        set_taggingType_blank_error(false)
        set_selectedOffences_blank_error(false)
        set_parkinglot_image_blank_error(false)
        set_dropbox_image_blank_error(false)
        set_signage_image_blank_error(false)
    }



    const initialState = {
        lot: {},
        offences: [],
        isOffenceShow: false,
        isLoading: true,
        isPaymentProviderShow: false,
        providers: [],
        providersImgs: [],
        selectedOffencesName: [],
        selectedProvidersImgs: [],
        parkingLotImageName: "",
        dropBoxImageName: "",
        signageImageName: "",
        lotRule: "",
        grace_period: 0,
        allowUnpaidCitations: 0,
        dropBoxImagePreview: [],
        signageImagePreview: [],
        parkingLotImagePreview: [],
        lot_space: 0
    }
    const initialPayload = {
        lotname: "",
        address: "",
        dropbox: {
            dropboxCode: "",
            dropboxDescription: ""
        },
        lotRules: [],
        offences: [],
        parkingLot: [],
        dropBox: [],
        signage: [],
        paymentProviders: [],
        permitAllowed: true,
        taggingType: [],
        holdingFee: 0,
        grace_period: 0,
        allowUnpaidCitations: 0,
        coordinates: { lat: null, lang: null },
        split_address: {},
        internalAccountingId: null,
        lot_space: 0
    }
    const [pageState, setPageState] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, initialState)
    const [state, updateState] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, initialPayload)

    const [providers, setProviders] = useState([]);
    const [selectedProviders, setSelectedProviders] = useState([])
    const [selectedProvidersImgs, setSelectedProvidersImgs] = useState([])
    const [proof_images, setProof_images] = useState([]);
    const [facilityId, setfacilityId] = useState();
    const [pmc_locationId, setpmc_locationId] = useState();
    const [proParkId, setProParkId] = useState();

    const { lot } = pageState;


    const handleOffences = (offence, offenceName) => {
        const isAlreadySelected = state?.offences?.indexOf(offence);
        const isAlreadySelectedOffenceName = pageState?.selectedOffencesName?.indexOf(offenceName);
        if (isAlreadySelected === -1) {
            updateState({
                offences: [...state?.offences, offence],
            });
            setPageState({
                selectedOffencesName: [...pageState?.selectedOffencesName, offenceName]
            })
        } else {
            let oldOffences = state?.offences;
            let oldOffencesName = pageState?.selectedOffencesName;
            oldOffences?.splice(isAlreadySelected, 1);
            oldOffencesName?.splice(isAlreadySelectedOffenceName, 1);
            updateState({
                offences: [...oldOffences],
            })
            setPageState({
                selectedOffencesName: [...oldOffencesName]
            })
        }
    };

    useEffect(() => {
        getLotsById()
    }, [])

    const handleRemoveOptionalImage = (index) => {
        const updatedItems = proof_images.filter((_, i) => i !== index);
        setProof_images(updatedItems);
    };

    const handleUpdateOptionalImage = (index, updatedValues) => {
        const updatedItem = { ...proof_images[index], ...updatedValues };
        const updatedItems = [...proof_images.slice(0, index), updatedItem, ...proof_images.slice(index + 1)];
        setProof_images(updatedItems);
    };
    async function getLotsById() {
        try {
            const [lotdata, providersData, offenceData, operator] = await Promise.all([
                props.getLotsById(id),
                props.getProviders(),
                props.getActiveOffenceByOperator(),
                props.user()
            ])
            if (lotdata?.value?.success && providersData?.value?.success && offenceData?.value?.success && operator?.value?.success) {
                const { lot } = lotdata?.value,
                    { providers } = providersData?.value;
                setPageState({
                    lot,
                    isLoading: false,
                    offences: offenceData?.value?.offences,
                    selectedOffencesName: lot?.offences?.map(({ offenceName }) => offenceName),
                    parkingLotImagePreview: lot?.parkingLotImage,
                    dropBoxImagePreview: lot?.dropBoxImage,
                    signageImagePreview: lot?.signageImage,
                })
                updateState({
                    operator: operator?.value?.user?._id,
                    isParkmobileEnabled: operator?.value?.user?.isParkmobileEnabled,
                    isT2Enabled: operator?.value?.user?.isT2Enabled,
                    allowed_pp: operator?.value?.user?.allowed_pp || operator?.value?.user?.operatorInfo?.allowed_pp,
                    lotname: lot?.lotname,
                    address: lot?.address,
                    dropbox: lot?.dropbox,
                    lotRules: lot?.lotRules,
                    offences: lot?.offences?.map(({ _id }) => _id),
                    parkingLot: lot?.parkingLotImage,
                    dropBox: lot?.dropBoxImage,
                    signage: lot?.signageImage,
                    proof_images: lot?.proof_images,
                    paymentProviders: lot?.paymentProviders?.map(pp => pp?._id),
                    permitAllowed: lot?.permitAllowed,
                    taggingType: lot?.taggingType,
                    holdingFee: lot?.holdingFee,
                    grace_period: lot?.grace_period || 0,
                    allowUnpaidCitations: lot?.allowUnpaidCitations || 0,
                    coordinates: lot?.coordinates,
                    split_address: lot?.split_address,
                    internalAccountingId: lot?.internalAccountingId || "",
                    lot_space: lot?.lot_space || 0
                })
                const updatedProofImages = lot?.proof_images?.map(url => ({
                    ImagePreview: url
                }));
                if(updatedProofImages){
                    setProof_images(updatedProofImages);
                }else{setProof_images([]);}
                setfacilityId(lot?.facilityId)
                setpmc_locationId(lot?.pmc_locationId)
                setProParkId(lot?.pro_park_lotId)

                setSelectedProvidersImgs(lot?.paymentProviders?.map(pp => pp?.logoUrl))
                setSelectedProviders(lot?.paymentProviders?.map(pp => pp?._id))
                setProviders(providers);
            }
            else {
                dispatch(setAlert("error", lotdata?.value?.message || providersData?.value?.message || offenceData?.value?.message || operator?.value?.message))
                // alert(lotdata?.value?.message || providersData?.value?.message || offenceData?.value?.message || operator?.value?.message)
                setPageState({ isLoading: false })
            }
        } catch (error) {
            dispatch(setAlert("error", error?.message))
            // alert(error?.message)
            setPageState({ isLoading: false })
        }
    }

    const handleProvider = provider => {
        const isAlreadySelected = state?.paymentProviders?.indexOf(provider?._id)
        const isAlreadySelectedLogo = selectedProvidersImgs?.indexOf(provider?.logoUrl)
        if (isAlreadySelected === -1 && isAlreadySelectedLogo === -1) {
            updateState({
                paymentProviders: [...state?.paymentProviders, provider?._id]
            })
            // setSelectedProviders([...selectedProviders, provider?._id])
            setSelectedProvidersImgs([...selectedProvidersImgs, provider?.logoUrl])
        }
        else {
            let oldProviders = state?.paymentProviders
            let oldProvidersImgs = selectedProvidersImgs
            oldProviders?.splice(isAlreadySelected, 1)
            oldProvidersImgs?.splice(isAlreadySelectedLogo, 1)
            updateState({
                paymentProviders: [...oldProviders]
            })
            // setSelectedProviders([...oldProviders])
            setSelectedProvidersImgs([...selectedProvidersImgs])
        }
    }


    const [errmessage, seterrmessage] = useState()
    const [errmessage1, seterrmessage1] = useState()
    const [errmessagefacilityId, seterrmessagefacilityId] = useState()
    const [errmessagepmc_locationId, seterrmessagepmc_locationId] = useState()
    const [errmessageproParkId, seterrmessageproParkId] = useState()

    const onChange = e => {
        const [section, key] = e.target.name.split(".");
        if (key)
            updateState({
                [section]: {
                    ...state?.[section],
                    [key]: e?.target?.value
                }
            })
        else
            updateState({ [e?.target?.name]: e?.target?.value })
        set_name_blank_error(false)
        set_address_blank_error(false)
    }

    const handleTaggingType = e => {
        const indx = state?.taggingType?.indexOf(e?.target?.value)
        if (~indx) {
            state?.taggingType?.splice(indx, 1)
            updateState({
                [e?.target?.name]: state?.taggingType
            })
        }
        else {
            updateState({
                [e?.target?.name]: [...state?.taggingType, e?.target?.value]
            })
        }
    }


    const [name_blank_error, set_name_blank_error] = useState()
    const [address_blank_error, set_address_blank_error] = useState()
    const [dbcode_blank_error, set_dbcode_blank_error] = useState()
    const [facilityId_blank_error, set_facilityId_blank_error] = useState()
    const [pmc_locationId_blank_error, set_pmc_locationId_blank_error] = useState()
    const [proParkId_blank_error, set_proParkId_blank_error] = useState()
    const [dropboxDescription_blank_error, set_dropboxDescription_blank_error] = useState()
    const [lotrule_blank_error, set_lotrule_blank_error] = useState()
    const [holdingfee_blank_error, set_holdingfee_blank_error] = useState()
    const [selectedProviders_blank_error, set_selectedProviders_blank_error] = useState()
    const [taggingType_blank_error, set_taggingType_blank_error] = useState()
    const [selectedOffences_blank_error, set_selectedOffences_blank_error] = useState(false)
    const [parkinglot_image_blank_error, set_parkinglot_image_blank_error] = useState()
    const [dropbox_image_blank_error, set_dropbox_image_blank_error] = useState()
    const [signage_image_blank_error, set_signage_image_blank_error] = useState()




    const EditLot = async () => {
        setPageState({ isLoading: true })

        let err

        if (state?.lotname?.trim() === "") {
            err = true
            set_name_blank_error("Please enter name")
        }

        if (!state?.address) {
            err = true
            set_address_blank_error("Please enter address")
        }

        if (!state?.dropbox?.dropboxCode) {
            err = true
            set_dbcode_blank_error("Please enter dropbox code")
        }

        if (!state?.dropbox?.dropboxDescription) {
            err = true
            set_dropboxDescription_blank_error("Please enter dropbox description")
        }

        if (state?.lotRules?.length === 0) {
            err = true
            set_lotrule_blank_error("Please enter lot rule")
        }

        if (state?.holdingFee?.length === 0) {
            err = true
            set_holdingfee_blank_error("Please enter holding fee")
        }

        if (state?.paymentProviders?.length === 0) {
            err = true
            set_selectedProviders_blank_error("Please select payment provider")
        }

        if (state?.taggingType?.length === 0) {
            err = true
            set_taggingType_blank_error("Please select enforcement Options")
        }

        if (state?.offences?.length === 0) {
            err = true
            set_selectedOffences_blank_error(true)
        }
        if (!state?.parkingLot || state?.parkingLot?.length <= 0) {
            err = true
            set_parkinglot_image_blank_error("Please select parking lot image")
        }

        if (!state?.dropBox || state?.dropBox?.length <= 0) {
            err = true
            set_dropbox_image_blank_error("Please select dropbox image")
        }

        if (!state?.signage || state?.signage?.length <= 0) {
            err = true
            set_signage_image_blank_error("Please select signage image")
        }

        setPageState({ isLoading: false })

        if (!err) {
            setPageState({ isLoading: true })
            try {              
                const { value } = await props.addlots({
                    ...state,
                    lotRules: JSON.stringify(state?.lotRules),
                    paymentProviders: JSON.stringify(state?.paymentProviders),
                    dropbox: JSON.stringify({
                        dropboxCode: state?.dropbox?.dropboxCode,
                        dropboxDescription: state?.dropbox?.dropboxDescription,
                    }),
                    offences: JSON.stringify(state?.offences),
                    proof_images: proof_images,
                    facilityId: facilityId ? parseInt(facilityId): '',
                    pmc_locationId: pmc_locationId,
                    pro_park_lotId: proParkId
                }, id, true)
                if (value?.success) {
                    updateState(initialPayload)
                    setPageState({ isLoading: false })
                    dispatch(setAlert("success", value?.message))
                    navigate(-1)
                }
                else {
                    // alert(value?.message)
                    setPageState({ isLoading: false })
                    dispatch(setAlert("error", value?.message))
                }
            } catch (error) {
                setPageState({ isLoading: false })
                dispatch(setAlert("error", error?.message))
            }
        }
    }
    const offencesArray = pageState?.selectedOffencesName;
    const offencesArrayWithObjects = offencesArray.map(offence => ({ offenceName: offence }));



    return (

        <>
            <section className="mainWrapper">
                <Header />
                <div className="main-wrapper paddzero" id="profile-wrapper">
                    <div className="HeadingSection addlote-title">
                        <img src={leftArrow} className="left-arrow" onClick={() => navigate(-1)} />
                        <h4>Edit Lot</h4>
                    </div>
                    {
                        pageState?.isLoading ?
                            <div className="add-lot-block DetailsWrapper">Loading...</div>
                            :
                            <div className="add-lot-block DetailsWrapper">

                                <Form className="row add-lots">
                                    <Form.Group
                                        className="mb-3 col-lg-6 col-sm-12"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Lot Name<span className="mandatory Error">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Lot name"
                                            onClick={clearerr}
                                            maxLength={35}
                                            // required
                                            name="lotname"
                                            value={state?.lotname}
                                            onChange={onChange}
                                        // onChange={(e) => {
                                        //     const inputValue = e.target.value;
                                        //     const regex = /^[A-Za-z\s]*$/; 
                                        //     if (!regex.test(inputValue)) {

                                        //         // alert("Only alphabetic characters and spaces are allowed!"); 
                                        //         seterrmessage("Text not allowed!")
                                        //         return; 
                                        //     }
                                        //     onChange(e); 
                                        //     seterrmessage(false)
                                        // }}
                                        />
                                        {errmessage && <p className="Error">{errmessage}</p>}
                                        {name_blank_error && <p className="Error">{name_blank_error}</p>}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-3 col-lg-6 col-sm-12"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Address<span className="mandatory Error">*</span></Form.Label>
                                        {/* <Form.Control
                                            placeholder="Address"
                                            name="address"
                                            onClick={clearerr}
                                            // maxLength={50}
                                            value={state?.address}
                                            onChange={onChange}
                                        /> */}
                                        <PlacesAutocomplete
                                            apiKey={process.env.REACT_APP_GOOGLE_API_KEY_FOR_ADDRESS}
                                            apiOptions={{ language: 'en', region: 'us' }}
                                            autocompletionRequest={{
                                                componentRestrictions: {
                                                    country: ["us", "in", "pk"], //to set the specific country
                                                },
                                            }}

                                            selectProps={{
                                                defaultInputValue: state?.address,
                                                isClearable: true,
                                                onChange: (e) => {
                                                    updateState({ address: e?.label })
                                                    set_address_blank_error(false)
                                                    geocodeByAddress(e?.label)
                                                        .then(async (results) => {
                                                            const { lat, lng } = await getLatLng(results[0]);
                                                            // Extract address components
                                                            const addressComponents = results[0].address_components;
                                                            let street, city, state, zipcode;
                                                            for (let i = 0; i < addressComponents.length; i++) {
                                                                const component = addressComponents[i];
                                                                const componentType = component.types[0];

                                                                if (componentType === 'street_number') {
                                                                    street = component.long_name;
                                                                } else if (componentType === 'route') {
                                                                    street += ' ' + component.long_name;
                                                                } else if (componentType === 'locality') {
                                                                    city = component.long_name;
                                                                } else if (componentType === 'administrative_area_level_1') {
                                                                    state = component.short_name;
                                                                } else if (componentType === 'postal_code') {
                                                                    zipcode = component.long_name;
                                                                }
                                                            }

                                                            console.log('Street address:', street);
                                                            console.log('City:', city);
                                                            console.log('State:', state);
                                                            console.log('Zip code:', zipcode);

                                                            updateState({
                                                                coordinates: { lat, lng }, split_address: {
                                                                    street,
                                                                    city: city?.toLocaleLowerCase(),
                                                                    state,
                                                                    zipcode
                                                                }
                                                            })
                                                        })
                                                        .catch((error) => console.error('Error', error));
                                                }
                                            }}
                                            onLoadFailed={(error) => {
                                                console.log(error)
                                                dispatch(setAlert("error", error?.message || 'An unknown error occurred!'))
                                            }}
                                        />
                                        {address_blank_error && <p className="Error">{address_blank_error}</p>}
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-4 col-lg-6 col-sm-12"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        <Form.Label>Drop Box Details<span className="mandatory Error">*</span></Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Drop Box Code"

                                            onClick={clearerr}
                                            maxLength={8}
                                            name="dropbox.dropboxCode"
                                            value={state?.dropbox?.dropboxCode}
                                            // onChange={onChange}
                                            onChange={(e) => {
                                                const regex = /^[0-9\b]+$/;
                                                if (e.target.value === '' || regex.test(e.target.value)) {
                                                    onChange(e);
                                                    seterrmessage1(false)
                                                    set_name_blank_error(false)
                                                    set_dbcode_blank_error(false)
                                                } else {
                                                    if (e.target.value?.length === 0) {
                                                        onChange(e);
                                                    }
                                                    seterrmessage1("Text are not allowed")
                                                    set_dbcode_blank_error(false)
                                                }
                                            }}
                                        />
                                        {errmessage1 && <p className="Error">{errmessage1}</p>}
                                        {dbcode_blank_error && <p className="Error">{dbcode_blank_error}</p>}
                                        <textarea
                                            placeholder="Describe the location of your drop box"
                                            className="textarea-style mt-3"
                                            name="dropbox.dropboxDescription"
                                            onClick={clearerr}
                                            value={state?.dropbox?.dropboxDescription}
                                            onChange={onChange}
                                        />
                                        {dropboxDescription_blank_error && <p className="Error">{dropboxDescription_blank_error}</p>}

                                        <div className=" d-flex align-items-center justify-content-between vendor-block">
                                            <label>Select Payment Vendors:<span className="mandatory Error">*</span></label>
                                            <div className="payment-vendor">
                                                <button
                                                    id="toggleProvider"
                                                    type="button"
                                                    className="paymentVendorBtn"
                                                    onClick={() => {
                                                        seterrmessage8(false)
                                                        set_selectedProviders_blank_error(false)
                                                        setPageState({ isPaymentProviderShow: true });
                                                        document
                                                            .getElementById("toggleProvider")
                                                            ?.classList?.toggle("addVendore");
                                                    }}
                                                >
                                                    <div className="add-vendor">
                                                        {selectedProvidersImgs?.length > 0 &&
                                                            selectedProvidersImgs?.map((e, index) => {
                                                                if (index < 3) {
                                                                    return (
                                                                        <img
                                                                            src={e}
                                                                            alt="payment provider"
                                                                            key={index}
                                                                        />
                                                                    );
                                                                }
                                                            })}
                                                        {selectedProvidersImgs?.length > 3 && (
                                                            <span>
                                                                +{selectedProvidersImgs?.length - 3}
                                                            </span>
                                                        )}
                                                    </div>
                                                    <span className="dropdown-arrow"></span>
                                                </button>
                                                {pageState?.isPaymentProviderShow && (
                                                    <div className="paymentWrapper" onClick={() => setPageState({ isPaymentProviderShow: false })}>
                                                        <div>
                                                            <div className="paymentVendorblock" onClick={e => e.stopPropagation()}>
                                                                <img
                                                                    src={close}
                                                                    onClick={() => setPageState({ isPaymentProviderShow: false })}
                                                                    className="close-btn"
                                                                />
                                                                {/* <ul className="paymentVendorlist">
                                                                    {providers?.length > 0 && providers?.map((provider, index) => {
                                                                        return (
                                                                            <li
                                                                                key={index}
                                                                                onClick={() => handleProvider(provider)}
                                                                            >
                                                                                <div>
                                                                                    <img
                                                                                        src={provider?.logoUrl}
                                                                                        alt={provider?.logoUrl}

                                                                                    />
                                                                                    {provider?.provider}
                                                                                </div>
                                                                                <div className="vendor-checkbox">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id={`weekday-mon-${index}`}
                                                                                        class="weekday"
                                                                                        checked={state?.paymentProviders?.indexOf(provider?._id) !== -1}
                                                                                    />
                                                                                    <label
                                                                                        for={`weekday-mon-${index}`}
                                                                                    ></label>
                                                                                </div>
                                                                            </li>
                                                                        );
                                                                    })}
                                                                </ul> */}
                                                                <ul className="paymentVendorlist">
                                                                    {providers?.length > 0 && providers?.map((provider, index) => {
                                                                        if (state?.allowed_pp?.length > 0 ? state?.allowed_pp.includes(provider?.provider) : (provider?.provider === "Spot Hero" ||
                                                                         provider?.provider === "Pro Park" ||
                                                                         provider?.provider === "Dock" ||
                                                                         provider?.provider === "PMC" ||
                                                                         provider?.provider === "Dock 2.0" ||
                                                                         provider?.provider === "Premium Parking" ||
                                                                          state?.isT2Enabled && (provider?.provider === "T2 Mobile Pay") ||
                                                                           state?.isParkmobileEnabled  && (provider?.provider === "Parkmobile"))) {
                                                                            return (
                                                                                <li key={index} onClick={() => {handleProvider(provider);clearerr();}}>
                                                                                    <div>
                                                                                        <img src={provider?.logoUrl} alt={provider?.logoUrl} />
                                                                                        {provider?.provider}
                                                                                    </div>
                                                                                    <div className="vendor-checkbox">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id={`weekday-mon-${index}`}
                                                                                            className="weekday"
                                                                                            checked={state?.paymentProviders?.indexOf(provider?._id) !== -1}
                                                                                        />
                                                                                        <label htmlFor={`weekday-mon-${index}`}></label>
                                                                                    </div>
                                                                                </li>
                                                                            );
                                                                        }
                                                                        return null;
                                                                    })}
                                                                </ul>

                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {selectedProviders_blank_error && <p className="Error">{selectedProviders_blank_error}</p>}

                                        <div className="d-flex justify-content-between vendor-block mb-4">
                                            <label>Permits Allowed<span className="mandatory Error">*</span></label>
                                            <select
                                                className="form-select"
                                                aria-label="Default example"
                                                value={state?.permitAllowed}
                                                name="permitAllowed"
                                                onChange={onChange}
                                            >
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </div>
                                        <Form.Group
                                            className="col-12"
                                            controlId="exampleForm.ControlTextarea1"
                                        >
                                            <div>
                                                <label className=" form-label">Upload Images<span className="mandatory Error">*</span></label>
                                                <ul className="ParkingList marginBotmw">
                                                    <li className="posirel">
                                                        {pageState?.parkingLotImagePreview?.length > 0 ? (
                                                            <div>
                                                                <img
                                                                    src={CloseWhite}
                                                                    alt="X"
                                                                    onClick={() => {
                                                                        setPageState({
                                                                            parkingLotImageName: "",
                                                                            parkingLotImagePreview: []
                                                                        })
                                                                    }}
                                                                    className="CloseBtn"
                                                                />
                                                                <div className="ImgBox">
                                                                    <img
                                                                        src={pageState?.parkingLotImagePreview[0]}
                                                                        alt="Parking lot"
                                                                        className="uploaded-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <div className="file-upload">
                                                                    <img
                                                                        src={uploadFile}
                                                                        alt="imagess" />
                                                                    <p>Parking Lot</p>
                                                                    <input
                                                                        type="file"
                                                                        name="file"
                                                                        accept="image/*"
                                                                        onChange={(e) => {
                                                                            updateState({
                                                                                parkingLot: e?.target?.files[0],
                                                                            })
                                                                            setPageState({
                                                                                parkingLotImagePreview: [URL.createObjectURL(e?.target?.files[0])],
                                                                                parkingLotImageName: e?.target?.files[0].name
                                                                            })
                                                                        }}
                                                                    />

                                                                </div>
                                                                {parkinglot_image_blank_error && <p className="Error">{parkinglot_image_blank_error}</p>}
                                                            </div>
                                                        )}
                                                    </li>
                                                    <li className="posirel">
                                                        {pageState?.dropBoxImagePreview?.length > 0 ? (
                                                            <div>
                                                                <img
                                                                    src={CloseWhite}
                                                                    alt="X"
                                                                    onClick={() => {
                                                                        updateState({
                                                                            dropBox: [],
                                                                        })
                                                                        setPageState({
                                                                            dropBoxImageName: "",
                                                                            dropBoxImagePreview: [],
                                                                        })
                                                                    }}
                                                                    className="CloseBtn"
                                                                />
                                                                <div className="ImgBox">
                                                                    <img
                                                                        src={pageState?.dropBoxImagePreview[0]}
                                                                        alt="Drop box"
                                                                        className="uploaded-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <div className="file-upload">
                                                                    <img src={uploadFile} />
                                                                    <p>Drop Box</p>
                                                                    <input
                                                                        type="file"
                                                                        name="file"
                                                                        accept="image/*"
                                                                        onChange={(e) => {
                                                                            updateState({
                                                                                dropBox: e?.target?.files[0],
                                                                            })
                                                                            setPageState({
                                                                                dropBoxImageName: e?.target?.files[0].name,
                                                                                dropBoxImagePreview: [URL.createObjectURL(e?.target?.files[0])],
                                                                            })
                                                                        }}
                                                                    />

                                                                </div>
                                                                {dropbox_image_blank_error && <p className="Error">{dropbox_image_blank_error}</p>}
                                                            </div>
                                                        )}
                                                    </li>
                                                    <li className="posirel">
                                                        {pageState?.signageImagePreview?.length > 0 ? (
                                                            <div>
                                                                <img
                                                                    src={CloseWhite}
                                                                    alt="X"
                                                                    onClick={() => {
                                                                        updateState({
                                                                            signage: [],
                                                                        })
                                                                        setPageState({
                                                                            signageImageName: "",
                                                                            signageImagePreview: []
                                                                        })
                                                                    }}
                                                                    className="CloseBtn"
                                                                />
                                                                <div className="ImgBox">
                                                                    <img
                                                                        src={pageState?.signageImagePreview[0]}
                                                                        alt="Signage"
                                                                        className="uploaded-img"
                                                                    />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div>
                                                                <div className="file-upload">
                                                                    <img src={uploadFile} />
                                                                    <p>Signage</p>
                                                                    <input
                                                                        type="file"
                                                                        name="file"
                                                                        accept="image/*"
                                                                        onChange={(e) => {
                                                                            updateState({
                                                                                signage: e?.target?.files[0],
                                                                            })
                                                                            setPageState({
                                                                                signageImageName: e?.target?.files[0].name,
                                                                                signageImagePreview: [URL.createObjectURL(e?.target?.files[0])],
                                                                            })
                                                                        }}
                                                                    />

                                                                </div>
                                                                {signage_image_blank_error && <p className="Error">{signage_image_blank_error}</p>}
                                                            </div>
                                                        )}
                                                    </li>
                                                </ul>
                                            </div>
                                        </Form.Group>

                                        <div className="d-flex flex-column justify-content-start align-items-start vendor-block mb-4">
                                            <label className="mb-4">Proof Images</label>
                                            {proof_images?.length > 0 ? (
                                                <Form.Group
                                                    className="col-12"
                                                    controlId="exampleForm.ControlTextarea1"
                                                >
                                                <div>
                                                    <ul className="ParkingList mb-4">
                                                        {proof_images?.map((item, index) => (
                                                            <li className="posirel">
                                                                {item.ImagePreview ? (
                                                                    <div>
                                                                        <img
                                                                            src={CloseWhite}
                                                                            alt="X"
                                                                            onClick={() => handleRemoveOptionalImage(index)}
                                                                            className="CloseBtn"
                                                                        />
                                                                        <div className="ImgBox">
                                                                            <img
                                                                                src={item.ImagePreview}
                                                                                alt={`Proof ${index + 1}`}
                                                                                className="uploaded-img"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <img
                                                                            src={CloseWhite}
                                                                            alt="X"
                                                                            onClick={() => handleRemoveOptionalImage(index)}
                                                                            className="CloseBtn"
                                                                        />
                                                                        <div className="file-upload">
                                                                            <img src={uploadFile} />
                                                                            <p>Proof {index + 1}</p>
                                                                            <input
                                                                                type="file"
                                                                                name="file"
                                                                                accept="image/*"
                                                                                onChange={(e) => {
                                                                                    handleUpdateOptionalImage(index, {
                                                                                        file: e?.target?.files[0],
                                                                                        ImageName: e?.target?.files[0].name,
                                                                                        ImagePreview: [URL.createObjectURL(e?.target?.files[0])],
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>

                                                </Form.Group>
                                            ) : null}
                                            <button
                                                className="btn btn-secondary"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    if(proof_images.length < 4)
                                                    {
                                                        setProof_images((prevImages) => [...prevImages, {}]);
                                                    }else{
                                                        dispatch(setAlert("error", "Maximum 7 images allowed"))
                                                    }
                                                }}
                                            >
                                                Add Proof Image
                                            </button>
                                        </div>
                                    </Form.Group>
                                    <Form.Group
                                        className="mb-2 col-lg-6 col-sm-12"
                                        controlId="exampleForm.ControlInput1"
                                    >
                                        {/* <Form.Label>Lot Rules<span className="mandatory Error">*</span></Form.Label>
                                        <div class="input-group control-group after-add-more mb-3">
                                            <div className="add-lots-feild">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Lot Rules"
                                                    name="lotRule"
                                                    onClick={clearerr}
                                                    value={pageState?.lotRule}
                                                    onChange={(e) => {
                                                        setPageState({
                                                            [e?.target?.name]: e?.target?.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div class="input-group-btn mb-3">
                                                <button
                                                    class="btn  add-more"
                                                    type="button"
                                                    onClick={() => {
                                                        if (pageState?.lotRule.length < 5) {
                                                            // alert("Lot rule must be at least 5 characters long.");
                                                            seterrmessage8("Lot rule must be at least 5 characters long.")
                                                        } else {
                                                            console.log(pageState, "><<page state");
                                                            updateState({
                                                                lotRules: [...state?.lotRules, pageState?.lotRule]
                                                            });
                                                            setPageState({ lotRule: "" })
                                                        }
                                                    }}

                                                >
                                                    <img src={Plus} alt="Plus icon" />
                                                </button>
                                            </div>
                                        </div>
                                        {errmessage8 && <p className="Error">{errmessage8}</p>}
                                        <div className="add-field">
                                            {state?.lotRules?.length > 0 &&
                                                state?.lotRules?.map((data, index) => {
                                                    return (
                                                        <p key={index} >
                                                            {data}
                                                            <span
                                                                onClick={() => {
                                                                    state?.lotRules?.splice(index, 1)
                                                                    updateState({
                                                                        lotRules: state?.lotRules
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Minus} alt="minus icon" />
                                                            </span>
                                                        </p>
                                                    );
                                                })}
                                        </div> */}
                                        <Form.Label>Lot Rules<span className="mandatory Error">*</span></Form.Label>
                                        <div className="input-group control-group after-add-more">
                                            <div className="add-lots-feild">
                                                <Form.Control
                                                    type="text"
                                                    placeholder="Enter Lot Rules"
                                                    name="lotRule"
                                                    onClick={clearerr}
                                                    value={pageState?.lotRule}
                                                    onChange={(e) => {
                                                        setPageState({
                                                            [e?.target?.name]: e?.target?.value
                                                        })
                                                        seterrmessage8(false)
                                                    }}
                                                    onKeyDown={(e) => {
                                                        if (e.key === 'Enter') {
                                                            e.preventDefault();
                                                            if (pageState?.lotRule.length < 5) {
                                                                seterrmessage8("Lot rule must be at least 5 characters long.")
                                                            } else {
                                                                console.log(pageState, "><<page state");
                                                                updateState({
                                                                    lotRules: [...state?.lotRules, pageState?.lotRule]
                                                                });
                                                                setPageState({ lotRule: "" })
                                                            }
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <div className="input-group-btn mb-3">
                                                <button
                                                    className="btn  add-more"
                                                    type="button"
                                                    onClick={() => {
                                                        if (pageState?.lotRule.length < 5) {
                                                            seterrmessage8("Lot rule must be at least 5 characters long.")
                                                        } else {
                                                            console.log(pageState, "><<page state");
                                                            updateState({
                                                                lotRules: [...state?.lotRules, pageState?.lotRule]
                                                            });
                                                            setPageState({ lotRule: "" })
                                                        }
                                                    }}
                                                >
                                                    <img src={Plus} alt="Plus icon" />
                                                </button>
                                            </div>
                                        </div>
                                        {lotrule_blank_error && <p className="Error">{lotrule_blank_error}</p>}
                                        {errmessage8 && <p className="Error">{errmessage8}</p>}
                                        <div className="add-field mt-3">
                                            {state?.lotRules?.length > 0 &&
                                                state?.lotRules?.map((data, index) => {
                                                    return (
                                                        <p key={index} >
                                                            {data}
                                                            <span
                                                                onClick={() => {
                                                                    state?.lotRules?.splice(index, 1)
                                                                    updateState({
                                                                        lotRules: state?.lotRules
                                                                    })
                                                                }}
                                                            >
                                                                <img src={Minus} alt="minus icon" />
                                                            </span>
                                                        </p>
                                                    );
                                                })}
                                        </div>


                                        <div className="d-flex align-items-center justify-content-between">
                                            <Form.Label>Holding Fees<span className="mandatory Error">*</span></Form.Label>
                                            <div className="InputDollorWrap w-25">
                                                <span className="DollorSign">$</span>
                                                <input className="form-control InputStyle" placeholder="$0" type="text"
                                                    name="holdingFee"
                                                    value={state?.holdingFee}
                                                    onClick={clearerr}
                                                    // onChange={(e) =>  onChange(e)}
                                                    onChange={(e) => {
                                                        const inputValue = e?.target?.value;
                                                        const regex = /^\$?[0-9]*$/;
                                                        if (regex.test(inputValue)) {
                                                            onChange(e);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            {holdingfee_blank_error && <p className="Error">{holdingfee_blank_error}</p>}
                                            <Form.Label style={{paddingLeft: 15}}>Grace Time</Form.Label>
                                            <div className="InputDollorWrap w-25">
                                                <input className="form-control InputStyle" placeholder="0-60" type="text"
                                                    name="grace_period"
                                                    value={state?.grace_period}
                                                    onClick={clearerr}
                                                    // onChange={(e) =>  onChange(e)}
                                                    onChange={(e) => {
                                                        const inputValue = e?.target?.value;
                                                        const regex = /^\$?[0-9]*$/;
                                                        if (regex.test(inputValue) && inputValue <=60 ) {
                                                            onChange(e);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <Form.Label >Unpaid Notices</Form.Label>
                                            <div className="InputDollorWrap w-25 ms-3 mt-3">
                                                <input className="form-control InputStyle" placeholder="0" type="text"
                                                    name="allowUnpaidCitations"
                                                    value={state?.allowUnpaidCitations}
                                                    onClick={clearerr}
                                                    onChange={(e) => {
                                                        const inputValue = e?.target?.value;
                                                        const regex = /^\$?[0-9]*$/;
                                                        if (regex.test(inputValue)) {
                                                            onChange(e);
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <Form.Label >Internal <br /> Accounting ID</Form.Label>
                                            <div className="InputDollorWrap w-25 ms-3 mt-3">
                                                <input className="form-control InputStyle" type="text"
                                                    name="internalAccountingId"
                                                    value={state?.internalAccountingId}
                                                    onClick={clearerr}
                                                    onChange={onChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            <Form.Label className="me-5 pe-3">Lot Space</Form.Label>
                                            <div className="InputDollorWrap w-25 ms-3 mt-3">
                                                <input className="form-control InputStyle" placeholder="0" type="text"
                                                    name="lot_space"
                                                    value={state?.lot_space}
                                                    onClick={clearerr}
                                                    onChange={(e) => {
                                                        const inputValue = e?.target?.value;
                                                        const regex = /^\$?[0-9]*$/;
                                                        if (regex.test(inputValue)) {
                                                            onChange(e);
                                                        }
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <label className="form-label mt-3">Enforcement Options<span className="mandatory Error">*</span></label>
                                        <div className="d-flex align-item-center ">

                                            <div className="tagging-types form-control ">
                                                <div className="tagging-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        value="mailIn"
                                                        id="weekday-mon-0"
                                                        className="weekday"
                                                        onClick={clearerr}
                                                        name="taggingType"
                                                        checked={~state?.taggingType?.indexOf("mailIn")}
                                                        onChange={handleTaggingType}
                                                    />
                                                    <label htmlFor="weekday-mon-0"></label>
                                                </div>
                                                <p>Mail-in</p>
                                            </div>
                                            <div className="tagging-types form-control ">
                                                <div className="tagging-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        value="enforcement_lite"
                                                        id="weekday-mon-0"
                                                        className="weekday"
                                                        onClick={clearerr}
                                                        name="taggingType"
                                                        checked={~state?.taggingType?.indexOf("enforcement_lite")}
                                                        onChange={handleTaggingType}
                                                    />
                                                    <label htmlFor="weekday-mon-0"></label>
                                                </div>
                                                <p>Enforcement Lite</p>
                                            </div>

                                            <div className="tagging-types form-control">
                                                <div className="tagging-checkbox">
                                                    <input
                                                        type="checkbox"
                                                        value="tagVehicle"
                                                        id="weekday-mon-0"
                                                        className="weekday"
                                                        onClick={clearerr}
                                                        name="taggingType"
                                                        checked={~state?.taggingType?.indexOf("tagVehicle")}
                                                        onChange={handleTaggingType}
                                                    />
                                                    <label htmlFor="weekday-mon-0"></label>
                                                </div>
                                                <p>Tag Vehicle</p>
                                            </div>
                                        </div>
                                        {taggingType_blank_error && <p className="Error">{taggingType_blank_error}</p>}
                                        <div className="offence-block mt-3">
                                            <label className="form-label">Notices<span className="mandatory Error">*</span></label>
                                            <button type="button" className="offence-btn mb-3  form-control" onClick={() => setPageState({ isOffenceShow: !pageState?.isOffenceShow })}>
                                                <div className="d-flex align-items-center justify-content-between">
                                                    <div className="add-offiences">
                                                        Select notices
                                                    </div>
                                                    {/* {returnShotLotName(                                                      
                                                        offencesArrayWithObjects,
                                                        40,
                                                        true,
                                                        "offenceName"
                                                    )} */}
                                                    <span className="dropdown-arrow"></span>
                                                </div>


                                            </button>
                                            {
                                                pageState?.['isOffenceShow'] &&
                                                <div className="paymentWrapper" onClick={() => setPageState({ isOffenceShow: false })}>
                                                    <div>
                                                        <div className="paymentVendorblock" onClick={e => e.stopPropagation()}>
                                                            <img
                                                                src={close}
                                                                onClick={() => {
                                                                    setPageState({ isOffenceShow: false })
                                                                }}
                                                                className="close-btn"
                                                            />
                                                            <ul className="Offencelist">
                                                                {
                                                                    pageState['offences']?.map((offence, index) => {
                                                                        return (
                                                                            <li key={index} onClick={() => {
                                                                                handleOffences(offence?._id, offence?.offenceName)
                                                                                clearerr()
                                                                            }}>
                                                                                <div>{offence?.['offenceName']}</div>
                                                                                <div className="vendor-checkbox">
                                                                                    <input type="checkbox" id="weekday-mon-0" className="weekday"
                                                                                        checked={
                                                                                            state?.offences?.indexOf(
                                                                                                offence?._id
                                                                                            ) !== -1
                                                                                        }
                                                                                    />
                                                                                    <label htmlFor="weekday-mon-0"></label>
                                                                                </div>
                                                                            </li>
                                                                        )
                                                                    })
                                                                }

                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            {/* [
                                            "shorlike",
                                            "fdjdksf"
                                           ]
                                                     
                            [ 
                           {
                           offenceName: "shorline"
                            },
                           {
                  offenceName: "xyz"
                }
              ] */}

                                            <div className="show-offences">
                                                {
                                                    pageState?.selectedOffencesName?.map((offence, index) => {
                                                        return (
                                                            <h5>
                                                                <img src={checkbox} />
                                                                {offence}
                                                            </h5>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                        {selectedOffences_blank_error && <p className="Error">Please select notice</p>}
                                        {ali && <Form.Label className="mt-3">PMC location Id</Form.Label>}
                                        {ali && <Form.Control
                                            type="text"
                                            placeholder="PMC Location Id"
                                            value={pmc_locationId}
                                            autoComplete="off"
                                            onClick={clearerr}
                                            onChange={(e) => {
                                                const regex = /^[0-9\b]+$/;
                                                // if (e.target.value === '' || regex.test(e.target.value)) {
                                                    setpmc_locationId(e.target.value)
                                                    seterrmessagepmc_locationId(false)
                                                // } else {
                                                //     if (e.target.value?.length === 1) {
                                                //         setpmc_locationId()
                                                //     }
                                                //     // alert("Text are not allowed")
                                                //     seterrmessagepmc_locationId("Text are not allowed")
                                                //     set_pmc_locationId_blank_error(false)
                                                // }
                                            }}

                                        />}
                                        {ali && errmessagepmc_locationId && <p className="Error">{errmessagepmc_locationId}</p>}
                                        {ali && pmc_locationId_blank_error && <p className="Error">{pmc_locationId_blank_error}</p>}
                                        {ali && <Form.Label className="mt-3">Spot Hero Id</Form.Label>}
                                        {ali && <Form.Control
                                            type="text"
                                            placeholder="Facility Id"
                                            value={facilityId}
                                            maxLength={8}
                                            autoComplete="off"
                                            onClick={clearerr}
                                            onChange={(e) => {
                                                const regex = /^[0-9\b]+$/;
                                                if (e.target.value === '' || regex.test(e.target.value)) {
                                                    setfacilityId(e.target.value)
                                                    seterrmessagefacilityId(false)
                                                } else {
                                                    if (e.target.value?.length === 1) {
                                                        setfacilityId()
                                                    }
                                                    // alert("Text are not allowed")
                                                    seterrmessagefacilityId("Text are not allowed")
                                                    set_facilityId_blank_error(false)
                                                }
                                            }}

                                        />}
                                        {ali && errmessagefacilityId && <p className="Error">{errmessagefacilityId}</p>}
                                        {ali && facilityId_blank_error && <p className="Error">{facilityId_blank_error}</p>}
                                        {ali && <Form.Label className="mt-3">Pro Park Id</Form.Label>}
                                {ali && <Form.Control
                                    type="text"
                                    placeholder="Pro Park Id"
                                    value={proParkId}
                                    maxLength={8}
                                    autoComplete="off"
                                    onClick={clearerr}
                                    onChange={(e) => {
                                        setProParkId(e.target.value)
                                        seterrmessageproParkId(false)
                                        if (e.target.value !== '') {
     
                                        } else {
                                            // alert("Text are not allowed")
                                            // seterrmessageproParkId("Text are not allowed")
                                            // set_proParkId_blank_error(false)
                                        }
                                    }}

                                />}
                                {ali && errmessageproParkId && <p className="Error">{errmessageproParkId}</p>}
                                {ali && proParkId_blank_error && <p className="Error">{proParkId_blank_error}</p>}
                                    </Form.Group>
                                    <Form.Group
                                        className="col-12"
                                        controlId="exampleForm.ControlTextarea1"
                                    >
                                        <button
                                            type="button"
                                            className="btn DarkBtn"
                                            onClick={() => EditLot()}
                                        >
                                            Save
                                        </button>
                                    </Form.Group>
                                </Form>
                            </div>
                    }
                </div>
            </section>

        </>
    );
}
