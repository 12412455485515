import React, { useEffect, useState } from 'react'
import CloseNotification from "../../images/ic-close-btn.svg";
import success from "../../images/ic-success.svg";
import warning from "../../images/ic-warning.svg";
import error from "../../images/ic-error.svg";
import info from "../../images/ic-informational.svg";
// import { capitalize } from '../../global/constants';
import { capitalize } from '../../global';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../store/actioncreator';
// import audioFile from "./error-sound.wav"

const images = {
    success,
    warning,
    error,
    info
}


function Alert() {
    // const play = () => {
    //     const audio = new Audio(audioFile)
    //     audio.play()
    // }

    const dispatch = useDispatch();
    const alert = useSelector(state => state?.alert)
    let { message, status } = alert
    const error_message_time = status == 'error' ? 6000 : 2000;
    // if(message === "Failed to fetch") message = "Something went wrong, please try again later!"
    useEffect(() => {
        // status === "success" && play()
        setTimeout(() => {
            dispatch(setAlert("hide", ""))
        }, error_message_time)
    }, [])
    return (
        <>
            <div className="NotificationButtonWrapper ">
                <button className={`${status == 'error' ? 'NotificationUnSuccessful': 'NotificationSuccessful'}`}>
                    <div className="Flexsec">
                        <img src={images[status]} className="SuccessIcon" />
                        <h5>{capitalize(status)}!</h5>
                        <span>
                            <img src={CloseNotification}
                                onClick={
                                    () => dispatch(setAlert("hide", ""))
                                } />
                        </span>
                    </div>
                    <p>
                        {message}
                    </p>
                </button>
            </div>

        </>
    )
}


export default Alert
