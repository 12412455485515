import React, { useEffect, useReducer, useState } from "react";
import brandLogo from "../../images/brand-logo-black.svg";
import ImgLogin from "../../images/login-img.png";
import icEye from "../../images/ic-eye.svg";
import icEyec from "../../images/ic-eyec.svg"
import { Link } from "react-router-dom";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ActionCorrect from "../../images/ic-correct-id.svg";
import ActionIncorrect from "../../images/ic-incorrect-id.svg";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
export default function CreatePasswordComponent(props) {

    const dispatch = useDispatch()

    const [validEmail, setValidEmail] = useState(null);
    const [email, setEmail] = useState(null);

    const [showpassw, setShowPassw] = useState(false);
    const [showpassw1, setShowPassw1] = useState(false)

    const [img, setImg] = useState(icEyec)
    const [img1, setImg1] = useState(icEyec)

    const [lengtherror, setlengtherror] = useState('')
    const [lengtherror1, setlengtherror1] = useState('')
    const [confirmpass_err, setconfirmpass_err] = useState("")

    const visiblePassw = (e) => {
        e.preventDefault()
        setShowPassw(!showpassw)
        if (img === icEye) {
            setImg(icEyec)
        } else {
            setImg(icEye)
        }
    };

    const visiblePassw1 = (e) => {
        e.preventDefault()
        setShowPassw1(!showpassw1)
        if (img1 === icEye) {
            setImg1(icEyec)
        } else {
            setImg1(icEye)
        }
    }

    const navigate = useNavigate()
    const search = useLocation()?.search?.slice(1).split("&")
    const initialState = {
        email: "",
        password: "",
        confirmPassword: ""
    }
    const initialPageState = {
        token: "",
        errorMessage: "",
        isError: false
    }
    const [state, updateState] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, initialState)
    const [pageState, setPageState] = useReducer((prev, next) => {
        return { ...prev, ...next }
    }, initialPageState)

    const getEmailByBuffer = async () => {
        try {
            const { value } = await props.getEmailByBuffer(search[1])
            if (value?.success) {
                updateState({
                    email: value?.email
                })
                setPageState({
                    token: search[0],
                })
            }
            else {
                dispatch(setAlert("error", value?.message))
            }
        } catch (error) {
            dispatch(setAlert("error", error?.message))
        }
    }

    const createPasswordForOperator = async () => {
        try {
            let err = false

            if (state?.password.trim() === '') {
                setlengtherror("Please enter password")
                err = true
            }

            if (state?.confirmPassword.trim() === '') {
                setlengtherror1("Please enter confirm password")
                err = true
            }


            else if (state?.password.length < 8) {
                setlengtherror("Your password must have atleast 8 characters")
                err = true
            } else if (state?.password !== state?.confirmPassword) {
                err = true
                setconfirmpass_err("Password does not match")
            }

            // if(state?.password !== state?.confirmPassword){
            //     err = true
            //     setconfirmpass_err("Password does not match")
            // }

            // if (state?.password !== state?.confirmPassword) {
            //     err = true
            //     setPageState({
            //         isError: true,
            //         errorMessage: "Confirm password doesn't match!"
            //     })
            // }
            if (!err) {
                const { value } = await props.createPasswordForOperator(pageState?.token, { password: state?.password })
                if (value?.success) {
                    navigate("/login")
                }
                else {
                    dispatch(setAlert("error", value?.message))
                }
            }
            console.log(pageState)
        } catch (error) {
            dispatch(setAlert("error", error?.message))
        }
    }
    useEffect(() => {
        getEmailByBuffer()
    }, [])

    const onChange = e => {
        setPageState({
            isError: false
        })
        updateState({
            [e?.target?.name]: e?.target?.value
        })
        setlengtherror(false)
        setlengtherror1()
        setconfirmpass_err(false)
    }

    // const handleEmailChange = (event) => {
    //     // event.preventDefault();
    //     const emailRegex = /^\S+@\S+\.\S+$/;
    //     setEmail(event.target.value)
    //     const isValid = emailRegex.test(event.target.value);
    //     setValidEmail(isValid);
    // };

    const clearerr = () => {
        setlengtherror(false)
        setlengtherror1()
        setconfirmpass_err(false)
    }

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            createPasswordForOperator();
        }
    };

    return (
        <>
            <div>
                <section className="MainLoginWrapper">
                    <div className="FlexDiv">
                        <div className="LeftSection">
                            <div className="LeftSectionInner">
                                <div>
                                    <img src={brandLogo} className="BrandLogo" alt="taggr logo" />
                                    <h3>Enforcement simplified </h3>
                                </div>
                                <div className="FlexCenter">
                                    <div className="SectionDetails">
                                        <h2>Create Password</h2>
                                        <p>Please Create New Password.</p>
                                        <div className="FormBg">
                                            <form>
                                                <div className="mb-3 mt-3">
                                                    <label htmlFor="email" className="form-label">
                                                        Email Address
                                                    </label>
                                                    <div className="InputWrap">
                                                        <input
                                                            type="email"
                                                            className="form-control  InputStyle"
                                                            id="email"
                                                            placeholder=" Enter your email"
                                                            name="email"
                                                            value={state?.email}
                                                            disabled
                                                        // onChange={onChange}
                                                        // onChange={handleEmailChange}
                                                        />
                                                        <span className="ActionImg">
                                                            {validEmail ? (
                                                                <img src={ActionCorrect} />
                                                            ) : email && !validEmail ? (
                                                                <img src={ActionIncorrect} />
                                                            ) : <></>}

                                                        </span>
                                                    </div>
                                                </div>

                                                <div className="mb-3">
                                                    <label for="pwd" className="form-label">
                                                        Password
                                                    </label>
                                                    <div className="flex-password">
                                                        <input
                                                            // type='password'
                                                            type={!showpassw ? "password" : "text"}
                                                            className="form-control PasswordStyle"
                                                            id="pwd"
                                                            placeholder=""
                                                            onClick={clearerr}
                                                            name="password"
                                                            onKeyDown={handleKeyPress}
                                                            // value={email}
                                                            onChange={onChange}
                                                        />
                                                        <img
                                                            src={img}
                                                            onClick={(e) => visiblePassw(e)}
                                                            alt=""
                                                            className="passwordbtn"
                                                        />
                                                    </div>
                                                    {lengtherror && <div className="Error">{lengtherror}</div>}
                                                </div>
                                                <div className="mb-3">
                                                    <label for="pwd" className="form-label">
                                                        Confirm Password
                                                    </label>
                                                    <div className="flex-password">
                                                        <input
                                                            // type='password'
                                                            type={!showpassw1 ? "password" : "text"}
                                                            className="form-control PasswordStyle"
                                                            id="pwd1"
                                                            placeholder=""
                                                            onClick={clearerr}
                                                            name="confirmPassword"
                                                            onChange={onChange}
                                                            onKeyDown={handleKeyPress}
                                                        />
                                                        <img
                                                            src={img1}
                                                            onClick={(e) => visiblePassw1(e)}
                                                            alt=""
                                                            className="passwordbtn"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="invalid-feedback">
                                                </div>
                                                {confirmpass_err && <div className="Error">{confirmpass_err}</div>}
                                                {lengtherror1 && <div className="Error">{lengtherror1}</div>}
                                                {/* {
                                                    pageState?.isError && <div className="Error">{pageState?.errorMessage}</div>
                                                } */}

                                                <p className="TermsText">By registering an account with
                                                    Taggr Solutions LLC, you agree to our
                                                    <a href="https://taggrsolutions.com/terms-of-use" target="_blank"> Terms of Use</a> and
                                                    <a href="https://taggrsolutions.com/privacy-policy" target="_blank"> Privacy Policy</a>.
                                                </p>

                                                <button
                                                    type="button"
                                                    className="btn BtnDark mt-4"
                                                    onClick={() => {
                                                        createPasswordForOperator()
                                                    }}
                                                >
                                                    Create Password
                                                </button>

                                            </form>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="RightSection">
                            <div className="ImageBox">
                                <img src={ImgLogin} alt="" />
                                <h3 className="Heading">The future of enforcement!!</h3>
                            </div>
                        </div>
                    </div>

                </section>
            </div>
        </>
    );
}
