import React from "react";
import { Two_decimals, capitalize } from "../../../global";
import moment from "moment-timezone";
import { Link } from "react-router-dom";

export default function PayoutLog({ data }) {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-header">
              <h5>Refund/Payout Logs</h5>
            </div>
            {data?.map((logs, idx) => {
              return (
                <React.Fragment key={logs?._id}>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="d-flex justify-content-between">
                          <h6>Details Citation - {idx + 1}</h6>
                          <Link
                            to={`/citationsInformation/${logs?.citation_id}`}
                          >
                            Citation→
                          </Link>
                        </div>
                        <table className="table table-borderless">
                          <tbody>
                            <tr>
                              <th>Type:</th>
                              <td>
                                {capitalize(logs?.payout_logs?.[0]?.type)}
                              </td>
                            </tr>
                            <tr>
                              <th>Total Refunded Amount:</th>
                              <td>
                                ${logs?.payout_logs?.[0]?.total_refund_amount}
                              </td>
                            </tr>
                            <tr>
                              <th>Role:</th>
                              <td>
                                {capitalize(
                                  logs?.payout_logs?.[0]?.split_party || ""
                                )}
                              </td>
                            </tr>
                            <tr>
                              <th>Refund Amount (Split):</th>
                              <td>${logs?.payout_logs?.[0]?.split_amount}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <hr />

                    <div className="row">
                      <div className="col-md-12">
                        <table className="table table-striped">
                          <thead>
                            <tr>
                              <th>Log Time</th>
                              <th>Payout Amount</th>
                              <th>Refund Amount</th>
                              <th>Remaining Deduction(-)</th>
                              <th>After Deduction Amount(+)</th>
                            </tr>
                          </thead>
                          <tbody>
                            {logs?.payout_logs
                              ?.slice(1)
                              ?.map((payoutLogs, index) => {
                                return (
                                  <tr key={index}>
                                    <td>
                                      {moment(payoutLogs?.createdAt)
                                        ?.tz("America/New_York")
                                        ?.format("HH:mm MM/DD/YY")}
                                    </td>
                                    <td className="text-success">
                                      +
                                      {Two_decimals(
                                        payoutLogs?.payout_amount / 100
                                      )}
                                    </td>
                                    <td className="text-danger">
                                      -
                                      {Two_decimals(
                                        payoutLogs?.refund_amount / 100
                                      )}
                                    </td>
                                    <td className="text-yellow">
                                      -
                                      {Two_decimals(
                                        payoutLogs?.extra_refund_amount_after_deduction_of_party_amount
                                      )}
                                    </td>
                                    <td>
                                      {payoutLogs?.payout_amount >=
                                      payoutLogs?.refund_amount
                                        ? Two_decimals(
                                            (payoutLogs?.payout_amount -
                                              payoutLogs?.refund_amount) /
                                              100
                                          )
                                        : 0}
                                    </td>
                                    {/* <td>{Two_decimals((payoutLogs?.payout_amount - payoutLogs?.refund_amount)/100)}</td> */}
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              );
            })}
            <div className="card-body">
              <div className="row">
                <div className=" align-items-center mb-3 mx-3">
                  Total Refund Amount:{" "}
                  <strong>
                    -$
                    {Two_decimals(
                      data?.reduce(
                        (a, it) => (a += it?.payout_logs?.[0]?.split_amount),
                        0
                      )
                    )}
                    {/* -${Two_decimals(data?.reduce((a, it) => {
                  return (a += it?.payout_logs
                    ?.slice(1)
                    ?.reduce(
                      (b, bit) =>
                        b +
                        (bit?.payout_amount > bit?.refund_amount ? bit?.refund_amount : bit?.payout_amount),
                      0
                    ));
                }, 0)/100)} */}
                  </strong>
                </div>
                <div className="align-items-center mb-3 mx-3">
                  Total Deduction/cover Amount:{" "}
                  <strong>
                    +$
                    {Two_decimals(
                      data?.reduce((a, it) => {
                        return (a += it?.payout_logs
                          ?.slice(1)
                          ?.reduce(
                            (b, bit) =>
                              b +
                              (bit?.payout_amount - bit?.refund_amount > 0
                                ? bit?.refund_amount
                                : bit?.payout_amount),
                            0
                          ));
                      }, 0) / 100
                    )}
                  </strong>
                </div>
              </div>
              <div className="row">
                <div className="text-danger align-items-center mb-3 mx-3">
                  Total Remaining Deduction:{" "}
                  <strong>
                    -$
                    {data?.reduce((a, it) => {
                      return (a += it?.payout_logs
                        ?.slice(1)
                        ?.reduce((b, bit) => {
                          if (
                            bit?.extra_refund_amount_after_deduction_of_party_amount !==
                            0
                          ) {
                            b +=
                              bit.extra_refund_amount_after_deduction_of_party_amount;
                          } else {
                            b = 0;
                          }
                          return b;
                        }, 0));
                    }, 0)}
                  </strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
