import { connect } from "react-redux";
import { logins , forgotpassw, newpassword} from "../../store/home/duck";

import NewPasswFrgtComponent from "./component";

const NewPasswContainer = connect(
  // Map state to props
  (state) => ({
  }),
  // Map actions to dispatch and props
  {
    logins,

    forgotpassw,
    newpassword,
  }
)(NewPasswFrgtComponent);

export default NewPasswContainer;
