import React, { useEffect, useState } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import Loading from "../../utils/loading/Loading";
import Header from "../../components/common/Header/container";
import leftArrow from "../../images/left-arrow.svg";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import { useDispatch } from "react-redux";
import { setAlert } from "../../store/actioncreator";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";

export default function ManagerDetailView(props) {
  const navigate = useNavigate();
  const { managerId } = useParams();
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [manager, setManager] = useState(null);

  useEffect(() => {
    if (managerId) {
      fetchManagerData();
    }
  }, [managerId]);

  async function fetchManagerData() {
    setLoading(true);
    const { value } = await props.getManagerById(managerId);
    setLoading(false);
    if (value?.success) {
      setManager(value?.manager);
    } else {
      dispatch(setAlert("error", value?.message));
    }
  }

  return (
    <>
      {isLoading && <Loading />}
      <section className="mainWrapper">
        <Header />
        <div className="main-wrapper paddzero" id="profile-wrapper">
          <div className="HeadingSection addlote-title">
            <img
              src={leftArrow}
              className="left-arrow"
              onClick={() => navigate(-1)}
              alt="Back"
            />
            <h4>Managers</h4>
          </div>
          <div className="add-lot-block DetailsWrapper">
            <Card sx={{ maxWidth: 600, margin: "auto", boxShadow: 3, padding: 2 }}>
              <CardHeader 
                avatar={
                  <Avatar sx={{ bgcolor: "primary.main" }}>
                    {manager?.fullname?.charAt(0)}
                  </Avatar>
                }
                title={manager?.fullname || "Manager Detail"}
                subheader={` 📧 ${manager?.email} |  📞 ${manager?.phone}`}
              />
              <CardContent>
                {manager ? (
                  <Box display="flex" flexDirection="column" gap={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography 
                          variant="h6" 
                          sx={{ 
                            fontWeight: 'bold',
                            borderBottom: '2px solid #ddd',
                            paddingBottom: 1,
                            marginBottom: 2,
                            color: '#333'
                          }}
                        >
                          Lots Managed
                        </Typography>
                        <Stack direction="column" spacing={2} sx={{ padding: 1 }}>
                          {manager?.lots?.length > 0 ? (
                            manager?.lots?.map((lot) => (
                              <Paper
                                key={lot?._id}
                                variant="outlined"
                                sx={{
                                  padding: 1,
                                  display: "flex",
                                  alignItems: "center",
                                  backgroundColor: "#f9f9f9"
                                }}
                              >
                                <Typography variant="body1">
                                  <Link to={`/lot/details/${lot._id}`} style={{ textDecoration: "none", color: "inherit" }}>
                                    {lot?.lotname} &#x2192; 
                                  </Link>
                                </Typography>
                              </Paper>
                            ))
                          ) : (
                            <Typography variant="body1">No lots managed</Typography>
                          )}
                        </Stack>
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <Typography>No manager data found</Typography>
                )}
              </CardContent>
            </Card>
          </div>
        </div>
      </section>
    </>
  );
}
