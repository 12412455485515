import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { useState } from "react";
import RefundPermissionCard from "./PermissionCards/refundRequestPermissonCard";
import DashboardPermissionCard from "./PermissionCards/dashboardRequestPermissonCard";
import LotsPermissionCard from "./PermissionCards/lotsPermissionCard";
import ReportPermissionCard from "./PermissionCards/reportPermissionCard";
import TagsDeployedPermissionCard from "./PermissionCards/tagsDeployedPermissionCard";
import PermitsPermissionCard from "./PermissionCards/permitsPermissionCard";
import NoticePermissionCard from "./PermissionCards/noticePerimissionCard";
import DisputedCitationsPermissionCard from "./PermissionCards/disputedCitationsPermissionCard";
import VisitsPermissionCard from "./PermissionCards/visitsPermissionCard";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../store/actioncreator";

export const createRolesValidationSchema = Yup.object().shape({
  title: Yup.string().required().label("Role Title"),
});

export default function CreateRole(props) {
  const dispatch = useDispatch();
  const { dialogOpen, onClose, updateParent } = props;
  const [modules, setModules] = useState({});
  const userRole = 100;

  const initialState = {
    title: "",
  };

  const handleCreateRole = async (payload) => {
    dispatch(setLoading(true));
    try {
      const { value } = await props.addRole(payload);
      if (value?.success) {
        updateParent();
        onClose();
        dispatch(setAlert("success", value?.message));
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={onClose}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <DialogTitle sx={{ m: 0, p: 2 }}>Create New Role</DialogTitle>
          <IconButton
            aria-label="close"
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          <Formik
            initialValues={initialState}
            validationSchema={createRolesValidationSchema}
            onSubmit={(value, action) => {
              const payload = {
                ...value,
                modules,
              };
              handleCreateRole({ ...value, modules });
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={2} className="mt-1">
                  <Grid item xs={12}>
                    <TextField
                      name="title"
                      value={props.values.title}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      label="Role Title"
                      fullWidth
                      error={
                        props.errors.title && props.touched.title ? true : false
                      }
                      success={
                        props.errors.title && props.touched.title ? false : true
                      }
                      helperText={
                        props.errors.title && props.touched.title
                          ? props.errors.title
                          : null
                      }
                    />
                  </Grid>

                  <Grid item sx={{ width: "100%" }}>
                    <DashboardPermissionCard
                      modules={modules}
                      setModules={setModules}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <LotsPermissionCard
                      modules={modules}
                      setModules={setModules}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <ReportPermissionCard
                      modules={modules}
                      setModules={setModules}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <TagsDeployedPermissionCard
                      modules={modules}
                      setModules={setModules}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <PermitsPermissionCard
                      modules={modules}
                      setModules={setModules}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <NoticePermissionCard
                      modules={modules}
                      setModules={setModules}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <DisputedCitationsPermissionCard
                      modules={modules}
                      setModules={setModules}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <VisitsPermissionCard
                      modules={modules}
                      setModules={setModules}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <RefundPermissionCard
                      modules={modules}
                      setModules={setModules}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <button className="btn DarkBtn">Create </button>
                    </Box>
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
