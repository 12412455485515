import { connect } from "react-redux";
import { lotprovider,user, deleteyourlot, addlots, getProviders, getLotsById, getOffenceByOperator, getActiveOffenceByOperator } from "../../store/home/duck";
import AddLotsComponent from "./add_lot_component";

const AddLotsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    lotprovider,
    user,
    deleteyourlot,
    addlots,
    getProviders,
    getLotsById,
    getOffenceByOperator,
    getActiveOffenceByOperator
  }
)(AddLotsComponent);

export default AddLotsContainer;
