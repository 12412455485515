import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./app/App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";

const loadGoogleMapsScript = () => {
  // Check if the script is already in the document
  const existingScript = document.querySelector('script[src^="https://maps.googleapis.com/maps/api/js"]');
  
  if (!existingScript) {
    // Create the script element
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY_FOR_ADDRESS}&libraries=places,drawing`;
    script.async = true;
    script.defer = true;

    // Append the script to the document body
    document.body.appendChild(script);
  }
};

loadGoogleMapsScript()

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
