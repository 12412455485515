import React from "react";
import violation from "../../../images/violations.svg";

function Refund_Breakdown({data}) {
  console.log(data)
  const amounts = data?.refund?.reduce((acc, obj) => {
    acc[obj?.remainning_amount_that_need_to_be_capture_from] = obj?.total_amount_that_need_to_be_capture;
    return acc;
  }, {});

  return (
    <div className="details-block-wrapper">
      <h6>
        <img src={violation} />
        Refund Breakdown Amount
      </h6>
      <div className="details-wrapper-block">
        {[
          { label: "Taggr amount", key: "taggr" },
          { label: "Operator amount", key: "operator" },
          { label: "Tire tag amount", key: "tire_tag" },
          { label: "Taggr solutions amount", key: "taggr_solutions" },
          { label: "Refund Amount", key: "refunded_amount" },
        ].map(({ label, key }) => (
          <div className="details-list" key={key}>
            <label>{label}</label>
            <input
              value={key === "refunded_amount" ? data?.refund?.[0]?.[key] : amounts?.[key] ?? 0}
              type="text"
              id="text"
              className="form-control"
              disabled
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Refund_Breakdown;