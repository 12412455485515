import { connect } from "react-redux";
// import { user } from '../../../store/home/duck'
import { getEmailByBuffer, changePasswordForOperator,logins,user ,updateUser} from "../../store/home/duck";
// import { getEmailByBuffer, changePasswordForOperator,user ,updateUser} from "../../store/home/duck";

import ChangePasswordComponent from "./change_password_component";

const ChangePasswordContainer = connect(
  // Map state to props
  (state) => ({
  }),
  // Map actions to dispatch and props
  {
    // getEmailByBuffer,
    // changePasswordForOperator
    logins,
    user,
    updateUser
  }
)(ChangePasswordComponent);

export default ChangePasswordContainer;
