import React from "react";
import Wrapper from "./Wrapper";

function App(props) {

  return (
    <>
      <Wrapper props={props}/>
    </>
  );
}

export default App;