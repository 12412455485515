import { connect } from "react-redux";
import { getTaggrActivities, user, getAllLotsList } from "../../../store/home/duck";
import TaggrActivitiesComponent from "./Taggr_activities";

const TaggrActivitiesContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    getTaggrActivities,
    user,
    getAllLotsList
  }
)(TaggrActivitiesComponent);

export default TaggrActivitiesContainer;
