import { connect } from "react-redux";
// import { logins ,user} from "../../../store/home/api";
import { logins ,getusers,user,searchForOperator,statistics_admin} from "../../../store/home/duck"
import adminDashboardComponent from "./admin_dashboard_component";

const adminContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    getusers,
    searchForOperator,
    statistics_admin
  }
)(adminDashboardComponent);

export default adminContainer;
