import React, { useState } from "react";
import brandLogo from "../../images/brand-logo-black.svg"
import ImgLogin from "../../images/login-img.png";
import { Link, Navigate } from "react-router-dom"
import IcRight from "../../images/right-sign.png";
import { useNavigate } from "react-router-dom"
import Modal from "react-modal";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";

export default function PassRecComponent(props) {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [errorus, setErrorUs] = useState()
  // const [error, setError] = useState({})
  const [opnotpModal, setOpnOtpModal] = useState(true)
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [frgtpasswapi, setFrgtpasswapi] = useState("")
  const [errorModal, setErrorModal] = useState(false);

  const [error, setError] = useState();
  const [ErrorMessage1, setErrorMessage1] = useState('')

  const onLogin = async (e) => {
    e.preventDefault();

    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let err = false

    if (email?.trim() === '') {
      err = true
      setError("Please enter email")
    } else {
      if (emailRegex.test(email)) {
      } else {
        err = true
        setErrorMessage1("Please enter valid email")
      }
    }

    if (!err) {
     try{ 
      const { value } = await props.forgotpassw({email:email})
      if (value?.success) {
        setIsOpen(true)
        setEmail('')
      } else {
        // setErrorMessage1(value.message)
        dispatch(setAlert("error", value?.message))
      }
    }catch(error){
      dispatch(setAlert("error", error?.message))
      }

    }

  }


  // const onLogin = (e) => {
  //   e.preventDefault()
  //   const err = {}
  //   if (!email || !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
  //     err.email = 'Please enter valid email'
  //     // setErrorModal(true)
  //   }
  //   setError(err)
  //   if (!Object.keys(err).length) {
  //     const data = {
  //       email,
  //     }
  //   }
  // }
  // const onSendEmail = async (e) => {
  //   const { value } = await props.forgotpassw({email:email})
  //   // const { value } = await props.submit({ userId: user?._id, isTrainingPhase: false })
  //   if (value?.success) {
  //     setFrgtpasswapi(value)
  //     setIsOpen(true)
  //   }else{
  //     console.log('enter',value.message)
  //   }
  // }
  function closeModal() {
    navigate("/login")
    document.body.classList.remove("HideGap");
    setIsOpen(false);
    setErrorModal(false)
  }

  const clearerror = () => {
    setError(false)
    setErrorMessage1(false)
  }

  return (
    <>
      {opnotpModal ? (
        <div>
          <section className="MainLoginWrapper">
            <div className="FlexDiv">
              <div className="LeftSection">
                <div className="FullwidthClass">
                  <div>
                    <img src={brandLogo} className="BrandLogo" alt="taggr logo" />
                    <h3>Enforcement Simplified </h3>
                  </div>

                  <div className="FlexCenter">
                    <div className="SectionDetails">
                      <h2>Reset Password.</h2>
                      <p>
                        Please fill in the email you’ve used to create a Taggr operator account and we’ll send you a reset link
                      </p>
                      <div className="FormBg">
                        <form>
                          <div className="mb-3 mt-3">
                            <label htmlFor="email" className="form-label">
                              Email Address
                            </label>
                            <input
                              type="text"
                              className="form-control InputStyle"
                              placeholder="Youremailid@gmail.com"
                              value={email}
                              // onChange={(e) => setEmail(e.target.value)}
                              onClick={clearerror}
                              onChange={(e) => {
                                setEmail(e.target.value.toLowerCase())
                                setError(false)
                                setErrorMessage1(false)
                              }}
                            />
                            {/* {"email" in error &&
                            <div className='invalid-feedback'>{error.email}</div>
                          } */}

                            {error && <div className="text-danger">{error}</div>}
                            {ErrorMessage1 && <div className="text-danger">{ErrorMessage1}</div>}

                          </div>

                          <button type="submit" className="btn PrimaryDark mt-4"
                            onClick={(e) => {
                              onLogin(e);
                              // onSendEmail(e)
                              // openModal(e);
                            }}>
                            Reset Password
                          </button>
                          <div className="AccountLink">
                            <Link to="/login" className="back-to-login"> Back to Login</Link>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="RightSection">
                <div className="ImageBox">
                  <img src={ImgLogin} alt="" />
                  <h3 className="Heading">The future of enforcement!!</h3>
                </div>
              </div>
            </div>
          </section>
        </div>
      ) : null}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        className="CstModal CstModalSm"
      >
        <div className="ModayBody">
          <div className="text-center pt-3 mb-3">
            <img src={IcRight} alt="" className="RightSign" />{" "}
          </div>
          <p className="text-center SuccessfullMsg mb-0">
            Email Sent successfully!
          </p>
        </div>
        <footer>
          <div className="FooterFlex">
            <button className="BlackBtn mb-3" onClick={closeModal}>
              Ok
            </button>
          </div>
        </footer>
      </Modal>
    </>
  );
}



























