import { connect } from "react-redux";
import { logins ,user, getuserdetails, editprofile , gettaggrdetails, current_payout} from "../../store/home/duck.js";
import OperatorCurrentPayout from "./operator-current-payouts.js";

const OperatorCurrentPayoutPage = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    getuserdetails,
    editprofile,
    gettaggrdetails,
    current_payout
  }
)(OperatorCurrentPayout);

export default OperatorCurrentPayoutPage;
