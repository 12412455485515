import "../../css/layout.css"
import React from "react";
import "../../fonts/Lato/stylesheet.css"
import brandLogo from "../../images/brand-logo-black.svg"
import imgLogin from "../../images/img-login.png"
import icEye from "../../images/ic-eye.svg"
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from "react-router-dom"
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";

export default function NewPasswFrgtComponent(props) {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showpassw, setShowPassw] = useState(false)
  const [showcnfPassw, setShowCnfPassw] = useState(false)
  const [error, setError] = useState({})
  const [confirmpassword, setConfirmPassword] = useState('')
  const [frgtpasswapi, setFrgtpasswapi] = useState("")
  const navigate = useNavigate();
  const params = useParams()

  // function onLogin(e) {
  //   e.preventDefault()
  //   const err = {}

  //   if (password !== confirmpassword) {
  //     err.confirmpassword = 'Password didnt match.'
  //   }
  //   setError(err)
  //   if (!Object.keys(err).length) {
  //     const data = {
  //       email,
  //       password,
  //     }
  //   }
  //   // navigate("/login");
  // }

  const [blankpass_err, setblankpass_err] = useState('')
  const [passerror, setpasserror] = useState('');
  const [confirm_passerror, setconfirm_passerror] = useState('');
  const [matchpass_err, setmatchpass_err] = useState()

  const onSubmit = async (e) => {

    e.preventDefault()

    let errs = false

      if (password?.trim() === '') {
        errs = true
        setblankpass_err("Please enter password")
      } else if (password?.length < 8) {
        errs = true
        setpasserror("Your password must have atleast 8 characters")
      }
      // else if (password !== confirmpassword){
      //   errs = true
      //   setmatchpass_err("Password does not match")
      // }

      if(confirmpassword?.trim() === ''){
        errs = true
        setconfirm_passerror("Please enter confirm password")
      }else if (password !== confirmpassword){
        errs = true
        setmatchpass_err("Password does not match")
      }
     
      if (!errs) {

    try{   
         const { value } = await props.newpassword({ password: password, id: params.id, token: params.token })
    if (value?.success) {
      setFrgtpasswapi(value)
      navigate("/login")
    }else{
      dispatch(setAlert("error", value?.message))
    }
      }catch(error){
        dispatch(setAlert("error", error?.message))
      }
    }
  }

  // const onSubmit = async () => {
  //   const { value } = await props.newpassword({ password: password, id: params.id, token: params.token })
  //   if (value?.success) {
  //     setFrgtpasswapi(value)
  //     //   setIsOpen(true)
  //   }
  // }


  const visiblePassw = (e) => {
    e.preventDefault()
    setShowPassw(!showpassw)
  };
  const visibleCnfPassw = (e) => {
    e.preventDefault()
    setShowCnfPassw(!showcnfPassw)
  };

  const clearerr = () => {
    setpasserror(false)
    setblankpass_err(false)
    setpasserror(false)
    setmatchpass_err(false)
    setconfirm_passerror(false)
  }

  return (
    <>
      <div>
        <section className="WhiteBackground HoneyBg">
          <div className="Flex">
            <div className="LeftSection">
              <img src={brandLogo} className="BrandLogo" alt="taggr logo" />
              <h3> Enforcement Simplified </h3>
              <div className="LoginImageBox">
                <div className="LoginImage">
                  <img src={imgLogin} alt="" />
                </div>
                <span className="YellowCircleRight" />
              </div>
            </div>
            <div className="RightSection">
              <div className="RightSectionInner">
                <h2>Create Password</h2>
                <p>Please Create New Password.</p>
                <div className="FormBg">
                  <form>
                    {/* <div className="mb-3 mt-3">
                      <label htmlFor="email" className="form-label">
                        Email address
                      </label>
                      <input
                        type="email"
                        className="form-control  InputStyle"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        id="email"
                        placeholder
                        name="email"
                      />
                      {"email" in error && (
                        <div className="invalid-feedback">{error.email}</div>
                      )}
                    </div> */}

                    <div className="mb-3">
                      <label for="pwd" className="form-label">
                        New Password
                      </label>
                      <div className="flex-password">
                        <input
                          type={!showpassw ? "password" : "text"}
                          className="form-control PasswordStyle"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value)
                            setpasserror(false)
                            setblankpass_err(false)
                            setpasserror(false)
                            setmatchpass_err(false)
                            setconfirm_passerror(false)
                          }}
                          id="pwd"
                          autocomplete="off"
                          onClick={clearerr}
                          placeholder=""
                          name="pswd"
                        />
                        {/* <button > */}
                          {" "}
                          <img
                            src={icEye}
                            onClick={(e) => visiblePassw(e)}
                            alt=""
                            className="passwordbtn"
                          />
                        {/* </button>{" "} */}
                        {/* {console.log({ password })} */}
                      </div>
                      {blankpass_err && <div className="Error">{blankpass_err}</div>}
                      {passerror && <div className="Error">{passerror}</div>}

                      {/* {"password" in error && (
                        <div className="invalid-feedback">{error.password}</div>
                      )} */}

                    </div>
                    <div className="mb-3">
                      <label for="pwd" className="form-label">
                        Confirm Password
                      </label>
                      <div className="flex-password">
                        <input
                          type={!showcnfPassw ? "password" : "text"}
                          className="form-control PasswordStyle"
                          value={confirmpassword}
                          onChange={(e) => {
                            setConfirmPassword(e.target.value)
                            setpasserror(false)
                            setblankpass_err(false)
                            setpasserror(false)
                            setmatchpass_err(false)
                            setconfirm_passerror(false)
                          }}
                          id="pwd1"
                          autocomplete="off"
                          onClick={clearerr}
                          placeholder=""
                          name="pswd"
                        />
                        {/* <button className="passwordbtn"> */}
                          {" "}
                          <img
                            src={icEye}
                            onClick={(e) => visibleCnfPassw(e)}
                            alt=""
                            className="passwordbtn"
                          />
                        {/* </button>{" "} */}
                      </div>
                    </div>

                    {matchpass_err && <div className="Error">{matchpass_err}</div>}
                    {confirm_passerror && <div className="Error">{confirm_passerror}</div>}

                    {/* {"confirmpassword" in error && (
                      <div className="invalid-feedback">
                        {error.confirmpassword}
                      </div>
                    )} */}
                    <button
                      type="submit"
                      className="btn BtnDark mt-4"
                      onClick={(e) => {
                        // onLogin(e);
                        onSubmit(e)
                      }}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <span className="YellowCircleLeft" />
        </section>
      </div>
    </>
  );
}



























