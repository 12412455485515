import { connect } from "react-redux";
import { logins ,user,operatoCitation,searchForOperator,refund_citation, void_citation} from "../../store/home/duck";
import OperatorMailInCitationComponent from "./operator_mail_in_citation_component";

const OperatorMailInCitationContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,user,operatoCitation,searchForOperator,refund_citation,void_citation
  }
)(OperatorMailInCitationComponent);

export default OperatorMailInCitationContainer;
