import { Pagination } from "antd";
import React, { useReducer, useRef } from "react";
import { Link } from "react-router-dom";
import DarkView from "../../../images/DarkViewImg.svg";
import searchicon from "../../../images/search.svg";
import WhiteView from "../../../images/WhiteViewImg.svg";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { capitalize, debounce } from "../../../global";
import leftArrow from "../../../images/left-arrow.svg";
import { setAlert, setLoading } from "../../../store/actioncreator";
import Header from "../Citations-Header/container";
import moment from "moment-timezone";
import DatePicker from "react-datepicker";
import ClearDates from "../../../utils/clearDates/ClearDates";

const initialPageState = {
  total: 0,
  defaultCurrentPage: 1,
  currentPage: 1,
  pageSize: 10,
};

export default function AdminDisputedCitations(props) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [searchKey, setSearchKey] = useState("");
  const searchInputRef = useRef();
  const [data, setdata] = useState();
  const [operators, setOperators] = useState([]);
  const [operator, setOperator] = useState("");
  const [role, setrole] = useState();
  const [userId, setUserId] = useState("");

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [lots, setLots] = useState([]);

  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedLot, setSelectedLot] = useState("");

  const tz = moment.tz?.guess();

  const fetchName = async () => {
    dispatch(setLoading(true));
    try {
      const { value } = await props.user();
      setrole(value?.user?.role);
      setUserId(value?.user?._id);
      getLotList(value?.user?._id, value?.user?.role);
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  };

  const getLotList = async (id, role) => {
        const {value} = await props.getAllLotsList({id, role});
    console.log(value, "<<--lots")
    if (value?.lots) {
      setLots(value?.lots);
    }
  };

  const fetchOperators = async () => {
    dispatch(setLoading(true));
    try {
      const { value } = await props.getusers("operator");
      if (value?.success) {
        setOperators(value?.users || []);
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  };

  const allDisputedCitationsInfo = async (page, filter = {}) => {
    dispatch(setLoading(true));
    try {
      const { value } = await props.get_all_disputed_citations({
        page,
        limit: 10,
        filter,
      });
      if (value?.success) {
        setdata(value);
        setPageState({ total: value?.total });
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  };

  const search = async (query) => {
    try {
      if (query?.length > 2) {
        const { value } = await props?.search_disputed_citations(query, operator);
        if (value?.success) {
          setdata(value);
        } else {
          dispatch(setAlert("error", value?.message));
        }
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  };

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      allDisputedCitationsInfo(next?.currentPage, { operator, lotId: selectedLot, status: selectedStatus, startDate, endDate });
    }
    return { ...prev, ...next };
  }, initialPageState);

  useEffect(() => {
    if (operator || selectedLot || selectedStatus || startDate || endDate) {
      allDisputedCitationsInfo(1, { operator, lotId: selectedLot, status: selectedStatus, startDate, endDate });
    } else {
      allDisputedCitationsInfo(1);
    }
  }, [operator, selectedLot, selectedStatus, startDate, endDate]);

  useEffect(() => {
    if (!operator) {
      getLotList(userId, role);
      return;
    }

    getLotList(operator, "operator");
  }, [operator])

  useEffect(() => {
    allDisputedCitationsInfo(1);
    fetchOperators();
    fetchName();
  }, []);

  return (
    <>
      <section className="adminCitations">
        <Header />
        <div className="citationsSection paddzero" id="profile-wrapper">
          <div className="title-wrapper">
            <div className="d-flex align-items-center">
              {role !== "callcenter" ? (
                <img
                  src={leftArrow}
                  className="left-arrow "
                  onClick={() => navigate(-1)}
                />
              ) : (
                ""
              )}
              <h4>Disputed Citations</h4>
            </div>
            <div className="admin-citation-page"
            style={{
              display: "flex",
              alignItems: "center"
            }}>
              <div
                className="date-picker-block mb-4 mt-4"
                style={{ borderRight: "1px solid rgba(179, 179, 179, 0.5019607843)", borderRadius: "10px" }}
              >
                <div className="d-flex align-items-center border-block">
                  <div className="date-picker-list">
                    <label>Start Date</label>
                    <DatePicker
                      selected={startDate}
                      // onChange={(date) => setStartDate(date)}
                      onChange={(date) =>
                        setStartDate(
                          moment(date).tz(tz).hour(6).minute(0).toDate()
                        )
                      }
                      placeholderText="--/--/----"
                      className="date-picker"
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="date-picker-list">
                    <label>End Date</label>
                    <DatePicker
                      selected={endDate}
                      // onChange={(date) =>
                      //     setendDate(moment(date).tz(tz).toDate())
                      // }
                      onChange={(date) => {
                        const newDate = moment(date).tz(tz);
                        newDate.set({ hour: 23, minute: 59, second: 59 });
                        setEndDate(newDate.toDate());
                      }}
                      placeholderText="--/--/----"
                      className="date-picker"
                      minDate={startDate}
                      maxDate={new Date()}
                      disabled={!startDate}
                    />
                  </div>
                </div>
              </div>
                <ClearDates
                onClick={() => {
                  setStartDate(null)
                  setEndDate(null)
                  allDisputedCitationsInfo(1, { operator, lotId: selectedLot, status: selectedStatus, startDate, endDate });
                }}
                className="ms-2"/>
            </div>
          </div>
          <div className="cirtation-wrapper">
            <div className="table-responsive">
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ paddingBottom: "10px", paddingRight: "6px" }}>
                    <select
                      className="minimal"
                      onChange={(event) => setOperator(event.target.value)}
                    >
                      <option key="" value="">
                        All Operators
                      </option>
                      {operators.map((operator) => (
                        <option key={operator._id} value={operator._id}>
                          {operator.fullname}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div style={{ paddingBottom: "10px", paddingRight: "6px" }}>
                    <select
                      className="minimal"
                      value={selectedLot}
                      style={{ minWidth: "100px", maxWidth: "200px" }}
                      onChange={(e) => {
                        setSelectedLot(e.target?.value);
                      }}
                    >
                      <option value="">All Lots</option>
                      {lots?.map((lot, idx) => (
                        <option key={idx} value={lot?._id}>
                          {lot?.address}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div style={{ paddingBottom: "10px", paddingRight: "6px" }}>
                    <select
                      className="minimal"
                      value={selectedStatus}
                      style={{ minWidth: "100px", maxWidth: "200px" }}
                      onChange={(e) => {
                        setSelectedStatus(e.target?.value);
                      }}
                    >
                      <option value="">All Actions</option>
                      {["Reduce", "Decline", "Incomplete"]?.map((status, idx) => (
                        <option key={idx} value={status}>
                          {status}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="d-flex align-items-center gap-20">
                  <div
                    className="search-input"
                    style={{ width: "fit-content" }}
                  >
                    <button>
                      <img src={searchicon} />
                    </button>
                    <input
                      type="search"
                      placeholder="Search..."
                      ref={searchInputRef}
                      onChange={async (e) => {
                        setSearchKey(e?.target?.value);
                        e?.target?.value?.length > 2 &&
                          debounce(await search(e?.target?.value), 2000);
                        e?.target?.value?.length === 0 &&
                          allDisputedCitationsInfo(1, { operator, lotId: selectedLot, status: selectedStatus, startDate, endDate });
                      }}
                    />
                  </div>
                </div>
              </div>
              <table className="table offence-table StripTable">
                <thead>
                  <tr>
                    <th>Dispute Date</th>
                    <th>ID</th>
                    {/* <th>Notice Number</th> */}
                    <th>License Plate</th>
                    <th>Notice Issue Date</th>
                    <th>Lot Address</th>
                    <th>Chatbot Action</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {data?.disputedCitations?.length > 0 ? (
                    data?.disputedCitations?.map((val) => {
                      return (
                        <tr>
                          <td align="center">
                            {moment(val.createdAt).format("YYYY-MM-DD hh:mm A")}
                          </td>
                          <td align="center">{val._id}</td>
                          {/* <td align="center">{val.notice_number || "Nil"}</td> */}
                          <td align="center">{val.license_plate || "Nil"}</td>
                          <td align="center">
                            {val.notice_issue_date || "Nil"}
                          </td>
                          <td align="center">
                            {val.citationId?.lotId?.address || "Nil"}
                          </td>
                          <td align="center">
                            {val?.status ? (
                              <span
                                className={
                                  val?.status === "reduce"
                                    ? "green LabelStatus"
                                    : "red LabelStatus"
                                }
                              >
                                {capitalize(val?.status)}
                              </span>
                            ) : (
                              <span className="yellow LabelStatus">
                                Incomplete
                              </span>
                            )}
                          </td>

                          <td align="center">
                            <div className="d-flex align-items-center">
                              <Link
                                to={`/adminDisputedCitations/${val?._id}`}
                                className="eyeBtn"
                              >
                                <img src={DarkView} className="DarkImg" />
                                <img src={WhiteView} className="WhiteImg" />
                              </Link>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  ) : data?.disputedCitations === null ||
                    data?.disputedCitations.length === 0 ? (
                    searchKey && (
                      <div className="dataText">
                        <h5>No Result Found.</h5>
                      </div>
                    )
                  ) : (
                    <div className="dataText">
                      <h5></h5>
                    </div>
                  )}
                </tbody>
              </table>

              {data?.disputedCitations?.length > 0 && (
                <div className="pagination-block">
                  <Pagination
                    hideOnSinglePage
                    defaultCurrent={pageState?.defaultCurrentPage}
                    onChange={(page) => setPageState({ currentPage: page })}
                    current={pageState?.currentPage}
                    pageSize={pageState?.pageSize}
                    total={pageState?.total}
                    showSizeChanger={false}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
