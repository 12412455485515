import { connect } from "react-redux";
import { getusers, get_all_disputed_citations, search_disputed_citations, user, getAllLotsList } from "../../../store/home/duck";
import AdminDisputedCitations from "./AdminDisputedCitations.js";

const AdminDisputedCitationsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    user,
    get_all_disputed_citations,
    search_disputed_citations,
    getusers,
    getAllLotsList
  }
)(AdminDisputedCitations);

export default AdminDisputedCitationsContainer;
