import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../store/actioncreator';

const TaggrProofComponent = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setLoading(true));
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://widget.botcopy.com/js/injection.js';

    const embedder = document.getElementById('botcopy-embedder-d7lcfheammjct');
    if (embedder) {
      embedder.appendChild(script);
    }
    return () => {
      // Cleanup when the component is unmounted
      if (embedder) {
        embedder.removeChild(script);
        dispatch(setLoading(false));
      }
    };
  }, []); // Empty dependency array to run this effect only once

  return (
    <div 
      id="botcopy-embedder-d7lcfheammjct" 
      className="botcopy-embedder-d7lcfheammjct" 
      data-botId="64ff2549f33f9f0008d97818"
    >
      {/* This div is a placeholder for the script */}
    </div>
  );
};

export default TaggrProofComponent;
