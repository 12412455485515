import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Header from "../../components/common/Header/container";
import leftArrow from "../../images/left-arrow.svg";
import { setAlert, setLoading } from "../../store/actioncreator";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Pagination } from 'antd';
import moment from "moment-timezone"
import { DataGrid } from "@mui/x-data-grid";
import { Two_decimals } from "../../global";
import { capitalize } from "@mui/material";

export default function OperatorCurrentPayouts(props) {

    const [role, setRole] = useState("operator")
    const [data, setdata] = useState([])
    const navigate = useNavigate();
    const tz = moment.tz.guess()
    const dispatch = useDispatch();
    const currentDay_of_newyork = moment().tz("America/New_York").format('dddd');
    const currentHour = moment().tz("America/New_York").hour();

    const [currentPage, setCurrentPage] = useState(1);

    const isWednesday = currentDay_of_newyork === 'Wednesday' && currentHour >= 6;
    const current_Wednesday = moment().tz("America/New_York").day(3).hour(6).minute(0).second(0);
    const currentDayy = moment().tz("America/New_York").day();
    const daysToSubtract = currentDayy === 3 ? 7 : currentDayy < 3 ? currentDayy + 4 : currentDayy - 3;
    const currentWednesday = moment().tz("America/New_York").subtract(daysToSubtract, 'days').hour(6).minute(0).second(0);
    const [startDate, setStartDate] = useState(isWednesday ? current_Wednesday.toDate() : currentWednesday.toDate());
    const [endDate, setendDate] = useState(moment().tz("America/New_York").toDate());

    useEffect(() => {
        fetchvalue(role);
    }, [currentPage]);

    
  const fetchUser = async () => {
    try {
      const { value } = await props.user();
      if (value?.success) {
        if (
          value?.user?.role === "operator" ||
          value?.user?.role === "manager"
        ) {
          return value?.user?._id
        } else {
          navigate("/login");
        }
      } else {
        if (value?.message === "Authentication token expired | Unauthorized") {
          navigate("/login");
        }
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  };

  const status_classes ={
    paid: "paid-green",
    pending: "red",
    failed: "red",
    voided: "voided-red"
    }

    async function fetchvalue(role) {
      dispatch(setLoading(true))
      try {
          var userId = await fetchUser()
          const { value } = await props?.current_payout(role, currentPage, 10, { startDate, endDate, userId});
          if (value?.success) {
              setdata(value)
          } else {
              dispatch(setAlert('error', value?.message));
              console.log(value?.message);
          }
      } catch (error) {
          dispatch(setAlert('error', error?.message));
          console.log(error?.message);
      }
      dispatch(setLoading(false))
  }

  const columns = role == 'vendor' ? [
    { 
        field: "taggr_name",
        headerName: "Full Name",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => val,
    },
    { 
      field: "total_taggr_amount",
      headerName: "Amount to be transferred",
      width: 200,
      hideable: false,
      editable: false,
      valueFormatter: (val, row) => '$'+(row?.total_taggr_amount + row?.total_taggr_mail_in_amount),
    },
    { 
      field: "total_withhold_mail_in_citation_amount",
      headerName: "Withhold amount (Mail-In)",
      width: 200,
      hideable: false,
      editable: false,
      valueGetter: (val) => '$'+ Two_decimals(val)
    },
    {
      field: "total_tire_tag_amount",
      headerName: "Tire Tag Amount",
      width: 150,
      valueFormatter: (val) => '$'+(val)
    },
    { field: "total_taggrsolutions_amount", headerName: "Taggr Solutions Amount", width: 150, valueFormatter: (val) => '$'+(val)},
    { field: "total_taggrsolutions_mail_in_amount", headerName: "Taggr Solutions Mail-In Amount", width: 150, valueFormatter: (val) => '$'+(val)},
    {
      field: "citation_ids_count",
      headerName: "Tire tag Paid citations",
      width: 150,
      valueFormatter: (val, row) =>  val

    },
    {
      field: "un_paid_mail_in_citation_ids_count",
      headerName: "UnPaid Mail in citations",
      width: 150,
      valueFormatter: (val, row) =>  val
    },
    {
      field: "withhold_mail_in_citation_ids_count",
      headerName: "Withhold Mail in citations",
      width: 150,
      valueFormatter: (val, row) =>  val?.length
    },
    {
      field: "paid_mail_in_citation_ids_count",
      headerName: "Taggr Paid mail in citations",
      width: 150,
      valueFormatter: (val, row) =>  val

    },
  ] : role == "operator" ? [
    { 
        field: "fullname",
        headerName: "Full Name",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => val,
    },
    { 
      field: "user_amount",
      headerName: "Actual amount transferred",
      width: 200,
      hideable: false,
      editable: false,
      valueFormatter: (val, row) => '$'+val/100,
    },
    { 
        field: "Amount before transferred",
        headerName: "Amount before transferred",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => '$'+row?.break_down_amount?.operator_amount_before_MID/100,
    },
    { 
      field: "captured_refunded_amount",
      headerName: "Collected Refund Amount",
      width: 200,
      hideable: false,
      editable: false,
      valueFormatter: (val) => '-$'+  Two_decimals(val/100),
     },
    { 
        field: "break_down_amount",
        headerName: "Mail in deduction amount",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => '-$'+val?.amount_for_MID/100,
    },
    {
      field: "user_destination",
      headerName: "User account",
      width: 150,
      valueFormatter: (val) => (val),
    },
    {
      field: "user_type",
      headerName: "Type",
      width: 150,
      valueFormatter: (val) => capitalize(val ?? "")
    },
    {
      field: "operator_paid_citations",
      headerName: "Paid citations",
      width: 150,
      valueFormatter: (val, row) =>  val
    },
    {
      field: "refunded_citation_data",
      headerName: "Refunded citations",
      width: 150,
      valueFormatter: (val, row) =>  val?.length ?? 0

    },
    {
      field: "operator_MID_ids",
      headerName: "Taggr Mail in deductions",
      width: 150,
      valueFormatter: (val, row) =>  val
    },
    {
      field: "createdAt",
      headerName: "Created at",
      width: 150,
      valueFormatter: (val, row) => moment(val)?.tz(tz).format('YYYY-MM-DD HH:mm:ss')

    }
  ] : [
    { 
        field: "fullname",
        headerName: "Full Name",
        width: 200,
        hideable: false,
        editable: false,
        valueFormatter: (val, row) => val,
    },
    { 
      field: "user_amount",
      headerName: "Amount transferred",
      width: 200,
      hideable: false,
      editable: false,
      valueFormatter: (val, row) => '$'+val,
    },
    {
      field: "user_destination",
      headerName: "User account",
      width: 150,
      valueFormatter: (val) => (val),
    },
    {
      field: "transfer_group",
      headerName: "Payout transfer Group",
      width: 150,
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      renderCell: (row) => 
      { var val = row?.row;
      return  <td align="center">
                          <span className={status_classes[val?.status] || 'red'}>
                            {capitalize(val?.status ?? "")}
                          </span>
                        </td>
      },
    },
    {
      field: "user_type",
      headerName: "Type",
      width: 150,
      valueFormatter: (val) => capitalize(val ?? "")
    },
    {
      field: "createdAt",
      headerName: "Created at",
      width: 150,
      valueFormatter: (val, row) =>  moment(val)?.tz(tz).format('YYYY-MM-DD HH:mm:ss')
    },
  ];

    return (
        <>
            <section className="adminDashboard">
                <Header />
                <div className="adminreport-wrapper" id="profile-wrapper">
                    <div className="d-flex  align-items-center">
                        <img
                            src={leftArrow}
                            className="left-arrow"
                            onClick={() => navigate(-1)}
                        />
                        <h4>Current Payout</h4>
                    </div>
                    <div className="payoutscardSection">
                        {/* <ul className="nav nav-tabs">
                            <li className="nav-item"
                                onClick={() => {
                                    setRole("vendor")
                                    fetchvalue("vendor")
                                }}>
                                <h6>Taggr</h6>
                                <a className="nav-link active" data-bs-toggle="tab" href="#home">
                                    <div className="payoutsCards">
                                        <h1><span>$</span>{data?.payoutData?.taggr_amount_in_dollars}</h1>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item"
                                onClick={() => {
                                    setRole("operator")
                                    fetchvalue("operator")
                                }}>
                                <h6>Operator</h6>
                                <a className="nav-link" data-bs-toggle="tab" href="#menu1">
                                    <div className="payoutsCards">
                                        <h1><span>$</span>{data?.payoutData?.operator_amount_in_dollars}</h1>
                                    </div>
                                </a>
                            </li>
                        </ul> */}
                        <div className="d-flex align-items-center justify-content-evenly gap-3 border-block">
                            {/* <button disabled={isBackwardDisable} onClick={handleBackward}><img src={payoutleftArrow} /></button> */}
                            <div className=" d-flex align-items-center justify-content-center gap-3">
                                <div className="date-picker-list flex">
                                <div className=" d-flex align-items-center justify-content-center gap-3">
                                <div className="date-picker-list">
                                    <label>Start Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        placeholderText="--/--/----"
                                        className="date-picker"
                                        onChange={(date) => setStartDate(date)}
                                        disabled
                                    />
                                </div>
                                <div className="date-picker-list">
                                    <label>End Date </label>
                                    <DatePicker
                                        selected={endDate}
                                        placeholderText="--/--/----"
                                        className="date-picker"
                                        onChange={(date) => setendDate(date)}
                                        disabled
                                    />
                                </div>
                            </div>
                                </div>
                            </div>
                            {/* {<button disabled={isForwardDisable} onClick={handleForward}><img src={payoutrightArrow} /></button>} */}
                        </div>
                            <div className="table-responsive">
                            {data?.citations_operator?.length > 0 ? <DataGrid
                            rows={data?.citations_operator?.map((citation, index) => (citation)
                            )}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            hideFooterPagination
                            hideFooter
                            // hideFooterSelectedRowCount
                            pagination
                            // onPageChange={(params) => setPageState({ currentPage: params.page })}
                            rowCount={data?.total_operator}
                            paginationMode="server"
                            loading={props.loading}
                            getRowId={(row) => row.userId}
                            />: <h2 style={{
                                maxWidth: "fit-content",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: "10%"
                              }}
                              > No record founds</h2>}
                            {data?.citations_operator?.length > 0 && (
                                <div className='pagination-block'>
                                <Pagination
                                    hideOnSinglePage
                                    defaultCurrent={1}
                                    onChange={page => setCurrentPage(page)}
                                    current={currentPage}
                                    pageSize={10}
                                    total={data?.total_operator}
                                    showSizeChanger={false}
                                />
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
