import React, { useEffect, useState } from "react";
import Header from "../../components/common/Header/container";
import close from "../../../src/images/Close.svg"
import SupportEmail from "../../../src/images/supportemail.svg"
import SupportPhone from "../../../src/images/supportPhone.svg"
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";

export default function ContactSupportComponent(props) {

    const dispatch = useDispatch()
    const [subject, setSubject] = useState("");
    const [message, setMessage] = useState("");
    const [error, seterror] = useState("");

    const submit = async () => {

        if(subject.trim() === "" || message.trim() === ""){
            seterror(true)
            return;
        }

        try {
            const { value } = await props.contactsupport({ subject, message })
            if (value?.success) {
                dispatch(setAlert("success", value?.message))
                setSubject("");
                setMessage("");
            }
            else {
                dispatch(setAlert("error", value?.message))
            }
        } catch (error) {
            dispatch(setAlert("error", error?.message))
        }
    }

    const clearerr = () => {
        seterror(false)
    }   

    const handleKeyPress = (event) => {
        if (event.key === "Enter" && !event.shiftKey ) {
            event.preventDefault();
          submit();
        }
      };

    return (
        <section className="mainWrapper ">
            <Header/>
            <div className="main-wrapper paddingBtm" id="profile-wrapper">
                <div className="SupportSection">
                    <div className="WidthFull">
                        <div className="YellowBg">
                            <div className="HeaderTitle">
                                <h4 className="HeaderTitle">Contact</h4>
                                <button className="HeaderClose">
                                    {/* <img src={close} /> */}
                                </button>
                            </div>
                            <p className="Paragraph">A member from our team will be in touch within 24 hours!</p>
                        </div>
                        <div className="ContactWrapper">
                            <div className="container" style={{width: "100%"}}>
                                <div className="row justify-content-center">
                                    <div className="col-lg-12 mb-4">
                                        <input type="text"
                                            placeholder="Subject"
                                            name="subject"
                                            value={subject}
                                            onClick={clearerr}
                                            onChange={(e) => setSubject(e.target.value)}
                                            className="InputStyle"
                                            onKeyDown={handleKeyPress}
                                            />
                          {error && subject <= 0 ? <div className="Error">Please enter subject</div> : ""}
                                    </div>
                                    <div className="col-lg-12 mb-4">
                                        <textarea
                                            placeholder="Type your message here..."
                                            name="message"
                                            value={message}
                                            onClick={clearerr}
                                            onChange={(e) => setMessage(e.target.value)}
                                            className="TextareaStyle"
                                            onKeyDown={handleKeyPress}
                                            >
                                        </textarea>
                          {error && message <= 0 ? <div className="Error">Please enter message</div> : ""}
                                    </div>
                                    <div className="col-lg-12">
                                        <button className="btn DarkBtn" onClick={() => {
                                            submit()
                                        }} >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-lg-12 FlexContact">
                                        <span className="CustomMarg">
                                            <p>
                                                <img src={SupportEmail} />
                                                Email ID:
                                                <a href="mailto:admin@taggrsolutions.com"> admin@taggrsolutions.com</a>
                                            </p>
                                        </span>
                                        <span className="CustomMarg">
                                            <p>
                                                <img src={SupportPhone} />
                                                Contact:
                                                <a href="tel:+12025550147"> +1-202-555-0147</a>
                                            </p>
                                        </span>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

