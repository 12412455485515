import { connect } from "react-redux";
import { logins ,user, getuserdetails, editprofile , gettaggrdetails,payout_reconcilation,get_payout_dates} from "../../../store/home/duck";
import PayoutsOperator from "./payouts-operator";

const PayoutsOperatorPage = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    getuserdetails,
    editprofile,
    gettaggrdetails,
    payout_reconcilation,
    get_payout_dates
  }
)(PayoutsOperator);

export default PayoutsOperatorPage;
