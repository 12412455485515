import { connect } from "react-redux";
import { logins ,user, getusers,searchForOperator} from "../../../store/home/duck";
import taggr_1099 from "./1099_component";

const AdminoperatorContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    getusers,
    searchForOperator
  }
)(taggr_1099);

export default AdminoperatorContainer;
