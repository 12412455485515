import Loading from "../../utils/loading/Loading";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { delayLoading } from "../../global"
import leftArrow from "../../images/left-arrow.svg";
import Header from "../../components/common/Header/container";
import IcClose from "../../images/ic-close.svg";
import graycheckbox from "../../images/gray-checkbox.svg";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import Download from "../../components/svgIcons/Download";

export default function YourLotsComponent(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [lotData, setLotData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const ali = new URLSearchParams(window.location.search)?.get("is_ali");
  const { id } = useParams();

  const getLotsById = async () => {
    try {
      const { value } = await props.getLotsById(id)
      if (value?.success) {
        setLotData(value?.lot)
      }
      else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
  }

  useEffect(() => {
    getLotsById()
    //eslint-disable-next-line
  }, [])

  const downloadImage = async (imageUrl, imageName) => {
    try {
      const response = await fetch(imageUrl + "?harry=true", {
        headers: {
          "access-control-allow-origin": "*",
          "Content-Type": "application/json",
        },
        method: "GET",
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch image');
      }
  
      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
  
      const link = document.createElement('a');
      link.href = url;
      link.download = `${imageName}_${Date.now()}.jpg`;
      document.body.appendChild(link);
      link.click(); // Trigger download
      link.remove(); // Clean up
  
      URL.revokeObjectURL(url); // Free up memory
    } catch (error) {
      console.error('Error downloading the image:', error);
    }
  };


  return (
    <>
      <section className="mainWrapper">
        <Header/>
        <div className="main-wrapper paddzero" id="profile-wrapper">
          <div className="HeadingSection">
            <img src={leftArrow} className="left-arrow" onClick={() => navigate(-1)} />
            <h4>Lot Details</h4>
          </div>
          <div className="add-lot-block DetailsWrapper">
            <div className="row">
              <div className="col-lg-5 PadRight marginBottom">
                <label class="OutputLabel">Lot Name</label>
                <p className="OutputDetails">{lotData?.lotname}</p>
              </div>
              <div className="col-lg-5 marginBottom">
                <label class="OutputLabel">Address</label>
                <p className="OutputDetails">{lotData?.address}</p>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-5">
                <div className="margin_bottm">
                  <label class="OutputLabel">Drop Box Details</label>
                  <div className="d-flex align-items-center mb-3">
                    <p className="OutputDetails">Drop Box Code</p>
                    <span className="OutputDetails semicolum">:</span>
                    <p className="OutputDetails"> {lotData?.dropbox?.dropboxCode}</p>
                  </div>
                  <div className="d-flex align-items-center mb-3">
                    <p className="OutputDetails">
                      Drop Box Description
                    </p>
                    <span className="OutputDetails semicolum">:</span>
                    <p className="OutputDetails">  {lotData?.dropbox?.dropboxDescription}</p>
                  </div>

                </div>
                <label class="OutputLabel">Payment Vendors</label>
                <div className="payment-vendor">
                  {
                    lotData?.paymentProviders?.map((provider, index) => {
                      return <div className="vendor-list" key={index}>
                        <img src={provider?.logoUrl} alt={provider?.logoUrl} />
                        <p>{provider?.provider}</p>
                      </div>
                    })
                  }
                </div>
                <div className="mt-3 mb-4 d-flex justify-content-between align-items-center vendor-block PadRight">
                  <label className="allowedLabel">Permits Allowed</label>
                  <p className="OutputDetails">Yes</p>
                </div>
                  <label class="OutputLabel">Images</label>
                  <ul className="ParkingList">
                    <li className="posirel">
                      <p>Parking Lot&nbsp;&nbsp;{" "}</p>
                      <div className="ImgBox">
                        <a className="download-icon-overlay" onClick={() => downloadImage(lotData?.parkingLotImage, "Parking_lot_")}>
                          <Download />
                        </a>
                        <img src={lotData?.parkingLotImage} alt={lotData?.parkingLotImage} />
                      </div>
                    </li>
                    <li className="posirel">
                      <p>Drop Box&nbsp;&nbsp;{" "}</p>
                      <div className="ImgBox">
                        <a className="download-icon-overlay" onClick={() => downloadImage(lotData?.dropBoxImage, "Dropbox_")}>
                          <Download />
                        </a>
                        <img src={lotData?.dropBoxImage} alt={lotData?.dropBoxImage} />
                      </div>
                    </li>
                    <li className="posirel">
                      <p>Signage&nbsp;&nbsp;{" "}</p>
                      <div className="ImgBox">
                        <a className="download-icon-overlay" onClick={() => downloadImage(lotData?.signageImage, "Signage_")}>
                          <Download />
                        </a>
                        <img src={lotData?.signageImage} alt={lotData?.signageImage} />
                      </div>
                    </li>
                  </ul>

              </div>
              <div className="col-lg-5">
                <div className="marginBottom">
                  <label class="OutputLabel">Lots Rules</label>
                  <div className="LotsRules">
                    {
                      lotData?.lotRules?.map((rule, index) => {
                        return <p className="OutputDetailsLots" key={index}>
                          {rule}
                        </p>
                      })
                    }
                  </div>
                  <div className="margintopBotm d-flex justify-content-between align-items-center">
                    <label class="allowedLabel">Holding Fees</label>
                    <p class="OutputDetails">${lotData?.holdingFee}</p>
                    <label class="allowedLabel">Grace Period</label>
                    <p class="OutputDetails">{lotData?.grace_period ?? 0} mins</p>
                  </div>
                  <div className="margintopBotm d-flex justify-content-between align-items-center">
                    <label class="allowedLabel">Unpaid Notices</label>
                    <p class="OutputDetails ms-5">{lotData?.allowUnpaidCitations ?? 0}</p>
                    <label class="allowedLabel">Internal <br /> Accounting Id</label>
                    <p class="OutputDetails">{lotData?.internalAccountingId || "N/A"}</p>
                  </div>
                  <div className="margintopBotm d-flex align-items-center">
                    <label class="allowedLabel me-5 pe-3">Lot Space</label>
                    <p class="OutputDetails ms-5">{lotData?.lot_space ?? 0}</p>
                  </div>
                  {ali && <div className="margintopBotm d-flex justify-content-between align-items-center">
                    <label class="allowedLabel">Spot Hero ID</label>
                    <p class="OutputDetails">{lotData?.facilityId}</p>
                  </div>}
                  {ali && <div className="margintopBotm d-flex justify-content-between align-items-center">
                    <label class="allowedLabel">Pro Park ID</label>
                    <p class="OutputDetails">{lotData?.pro_park_lotId}</p>
                  </div>}
                </div>
                {/* <div className="col-lg-5">
                  <div className="marginBottom">
                    <label class="OutputLabel">Enforcement Options</label>
                    <div className="tagging-types">
                      <div className="tagging-checkbox">
                        <img src={graycheckbox} />
                        <label for="weekday-mon-0"></label>
                      </div>
                      <p>{lotData?.taggingType}</p>
                    </div>
                  </div>
                </div> */}

                <div className="col-lg-12">
                  <div className="marginBottom">
                    <label class="OutputLabel">Enforcement Options</label>
                    <div className="taging-block">
                      {lotData?.taggingType?.map((data, index) => (

                        <div className="tagging-types" key={index}>
                          {/* {rule} */}
                          <div className="tagging-checkbox">
                            <img src={graycheckbox} />
                            <label htmlFor={`weekday-mon-${index}`}></label>
                          </div>
                          <p className="tagging-type">{data === "mailIn" ? "Mail-In" : data === "enforcement_lite" ?  "Enforcement Lite":  "Tag Vehicle"}</p>
                        </div>

                      ))}
                    </div>
                  </div>
                </div>
                <label class="OutputLabel">Notices</label>
                <div className="show-offences">
                  {
                    lotData?.offences?.map((offence, index) => {
                      return <h5>
                        <img src={graycheckbox} />
                        {offence?.offenceName}
                      </h5>

                    })
                  }
                </div>
              </div>
              <div className="d-flex flex-column justify-content-start align-items-start vendor-block mb-4">
                  {lotData?.proof_images?.length > 0 && <label className="mb-4">Proof Images</label>}
                  {lotData?.proof_images?.length > 0 ? (
                      <Form.Group
                          className="col-12"
                          controlId="exampleForm.ControlTextarea1"
                      >
                      <div>
                          <ul className="ParkingList mb-4">
                              {lotData?.proof_images.map((url, index) => (
                                  <li className="posirel">
                                    <div>
                                      <div className="ImgBox">
                                          <img
                                              src={url}
                                              alt={`Proof ${index + 1}`}
                                              className="uploaded-img"
                                          />
                                      </div>
                                    </div>
                                  </li>
                              ))}
                          </ul>
                      </div>
                      </Form.Group>
                  ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
