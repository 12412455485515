import { connect } from "react-redux";
import { logins } from "../../store/home/duck";

import login from "./component";

const LoginContainer = connect(
  // Map state to props
  (state) => ({
  }),
  // Map actions to dispatch and props
  {
    logins,
    
  }
)(login);

export default LoginContainer;
