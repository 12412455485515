import { connect } from "react-redux";
import { lotprovider, user, addManager, getManagerById, updateManager } from "../../store/home/duck";
import DetailManagerComponent from "./DetailManagerComponent";

const DetailManagerContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    getManagerById
  }
)(DetailManagerComponent);

export default DetailManagerContainer;
