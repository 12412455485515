import { connect } from "react-redux";
// import { logins ,user} from "../../../store/home/api";
import { logins ,user,get_single_citation_details,getLotsById,update_citation_violation,void_citation} from "../../../store/home/duck";
import Edit_CitationsInformationComponent from "./citations_information_component";

const Edit_CitationsInformationContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase
  }),
  // Map actions to dispatch and props
  {
    logins,user,get_single_citation_details,getLotsById,update_citation_violation,void_citation
  }
)(Edit_CitationsInformationComponent);

export default Edit_CitationsInformationContainer;
