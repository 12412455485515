import React, { useEffect, useState } from "react";
import right from "../../images/right.svg";
import Header from "../../components/common/Header/container";
import { useNavigate } from "react-router-dom";
import leftArrow from "../../images/left-arrow.svg";
import { setLoading } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
export default function ConnectWithStripeComponent(props) {
  console.log(props, "PROPP")
  const navigate = useNavigate()
  const search = new URLSearchParams(window.location.search)
  const code = search?.get("code");
  const userId = search?.get("state");
  const [connected, setConnected] = useState(false)
  const [isConnecting, setIsConnecting] = useState(false)
  const [accountType, setAccountType] = useState("individual")
  const [isUser, setIsUser] = useState({})
  const [isLoaded, setIsLoaded] = useState(false);
  const dispatch = useDispatch();
  let isFetch = false
  console.log(props)
  const types = ["company", "individual"]

  useEffect(() => {
    dispatch(setLoading(true));
    console.log({ code, userId })
    user()
  }, [])

  const sendStripeCodeForConfirmation = async () => {
    setIsConnecting(true)
    isFetch = true
    try {
      const { value } = await props.connectWithStripe({ code, userId })
      if (value?.success) {
        setConnected(true)
        navigate("/connectWithStripe", { replace: true })
      } else {
        setConnected(false)
      }
    } catch (error) {
      setConnected(false)
    }
    setIsConnecting(false);
    setIsLoaded(true);
    dispatch(setLoading(false));
  };

  const handleConnectExpress = async () => {
    setIsConnecting(true)
    isFetch = true
    try {
      const { value } = await props.connectWithStripeExpress({ userId: isUser?._id, type: accountType })
      if (value?.success) {
        setConnected(true)
        window.location.href = value?.message;
      } else {
        setConnected(false)
      }
    } catch (error) {
      setConnected(false)
    }
  }

  const user = async () => {
    dispatch(setLoading(true));
    const { value } = await props.user()
    if (value?.success) {
      setIsUser(value?.user)
      setConnected(value?.user?.stripeConnectAccount?.isStripeConnected)
    }
    if (code && userId && !isFetch) {
      sendStripeCodeForConfirmation();
    } else {
      setIsLoaded(true);
      dispatch(setLoading(false));
    }
  };



  // let connectUrl = `https://connect.stripe.com/express/oauth/authorize?redirect_uri=${process.env.REACT_APP_HOST === "DEVELOPMENT" ?
  //   'http://localhost:3000/connectWithStripe'
  //   : 'https://dashboard.taggrsolutions.com/connectWithStripe'
  //   }&client_id=${process.env.REACT_APP_STRIPE_CLIENT_ID}&state=${isUser?._id}&stripe_user[country]=US`

  // let afterConnectedStripeUrl = "https://dashboard.stripe.com/login"


  return (
    !isLoaded ? (
      <p></p>
    ) : isConnecting ? (
      <p>Connecting...</p>
    ) : (
      <section className="mainWrapper">
        <Header/>
        <div className="main-wrapper" id="profile-wrapper">
        {connected ? <div/> : <div>
          <h4 className="d-flex align-items-center"><img src={leftArrow} className="left-arrow" onClick={() => navigate(-1)} />Connect to Stripe</h4>
          <p className="connect-strip-para">Millions of companies of all sizes-from startups to Fortune 500s -use Stripe's software and APIs to accept payments, send payouts, and manage their businesses online.</p>
          <h6>Create or connect an existing account to instantly start accepting payments.</h6>
          <ul className="accepting-payment-list">
            <li><img src={right} alt="right tick" />Secure payments </li>
            <li><img src={right} alt="right tick" />Transparent pricing</li>
            <li><img src={right} alt="right tick" />135+ currencies</li>
            <li><img src={right} alt="right tick" />35+ countries,  40+ languages </li>
            <li><img src={right} alt="right tick" />Optimized for conversions Connect with stripe</li>
          </ul>
          <div className="mb-2 w-25">
              <label> Onboarding Account type</label>
              {types && (
                  <select
                      className="form-control CustomSelect"
                      value={accountType}
                      onChange={(e) => {
                          setAccountType(e.target.value);
                          console.log("Selected Offense ID:", e.target.value);
                      }}
                  >
                      {types.map((type, index) => (
                          <option key={index} value={type}>
                              {type}
                          </option>
                      ))}
                  </select>
              )}
          </div>
          </div>}
          <button className="btn connectStrip" onClick={handleConnectExpress}>{connected ? "Sign in with"  : "Connect with"} Stripe</button>
          </div>
      </section>
    )
  );
}
