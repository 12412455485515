import React, { useState, useEffect } from 'react'
import { useLottie } from "lottie-react";
import animation from "./noResultFoundIcon.json"

function NoResultFound({ msgType, isSearchResult = true }) {
    const [showComponent, setShowComponent] = useState(false);
    const options = {
        animationData: animation,
        loop: true
    };
    const { View } = useLottie(options);

    useEffect(() => {
        setTimeout(() => {
            setShowComponent(true);
        }, 1000);
    }, []);
    return (
        <>
            {/* {showComponent &&
                < section class="page_404" >
                    <div class="page_container">
                        <div class="row">
                            <div class="col-sm-12 ">
                                <div class="col-sm-10 col-sm-offset-1  text-center">
                                    <div class="">
                                        <h4 class="text-center ">Opps!!</h4>
                                        {View}
                                    </div>
                                    <div class="contant_box_404">
                                        <h3 class="h2">
                                            No result found :(
                                        </h3>
                                        {isSearchResult && <p>We did not find any {msgType}.</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ section>

            } */}
        </>
    )
}

export default NoResultFound
