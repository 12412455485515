import { connect } from "react-redux";
import { logins, createoprtrsignup } from "../../store/home/duck";

import SignupComponent from "./component";

const SignupContainer = connect(
  // Map state to props
  (state) => ({
  }),
  // Map actions to dispatch and props
  {
    logins,
    createoprtrsignup,
  }
)(SignupComponent);

export default SignupContainer;
