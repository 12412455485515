import { connect } from "react-redux";
import {
  addRole,
  deleteRole,
  getRolesByOperator,
  updateRole,
  user,
} from "../../store/home/duck";
import RoleComponent from "./roles_components";

const RoleContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    user,
    addRole,
    getRolesByOperator,
    updateRole,
    deleteRole,
  }
)(RoleComponent);

export default RoleContainer;
