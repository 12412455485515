import React, { useEffect, useState } from "react";
// import "react-datepicker/dist/react-datepicker.css";
import Header from "../../components/common/Header/container";
import moment from "moment-timezone"
import CircularProgressBar from "../../utils/circularProgressBar/CircularProgressBar"
import StarRating from "../../utils/starRating/StarRating";
import TrashIcon from "../../utils/trash/TrashIcon";
import { Two_decimals } from "../../global"

export default function DashboardComponent(props) {
  const tz = moment.tz.guess()
  const [lots, setLots] = useState([]);
  const [selectedLot, setSelectedLot] = useState("All")

  useEffect(() => {
    // checkOperator();
    getOPeratorId();
    statisticsoperator();
  }, []);


  const [statisticsdata, setstatisticsdata] = useState()

  const statisticsoperator = async (lotId) => {
    try {
      const { value } = await props.statistics_operator(lotId ? lotId : selectedLot);
      if (value?.success) {
        setstatisticsdata(value);
      }
    } catch (error) {
      console.log(error.message, "<<<user error");
    }
  };

  const getOPeratorId = async () => {
    const { value } = await props.user();
    if (value?.success) {
      getLotList(value);
    }
  };

  const getLotList = async (value) => {
    const value1 = await props.lotprovider({ id: value.user._id });
    if (value1?.value?.lots) {
      setLots(value1?.value?.lots);
    }
  };

  return (
    <section className="mainWrapper">
      <Header />
      <div className="main-wrapper" id="profile-wrapper">
        <div className="stats-container-with-select">
          <h2>Stats</h2>
          <select
            className="CustomSelect"
            value={selectedLot}
            onChange={(e) => {
              setSelectedLot(e.target?.value);
              statisticsoperator(e.target?.value);
              console.log("Selected Lot", e.target.value);
            }}
          >
            <option value="All">All</option>
            {lots?.map((lot, idx) => (
              <option key={idx} value={lot?._id}>
                {lot?.address}
              </option>
            ))}
          </select>
        </div>
        <ul className="satas-wrapper">
          <li>
            <h1>{statisticsdata?.statistics?.visits ?? 0}</h1>
            <p>Visits This Week</p>
            <p>{moment(statisticsdata?.statistics?.dates?.start_date)?.format("MM/DD/YY")} - {moment(statisticsdata?.statistics?.dates?.end_date)?.format("MM/DD/YY")}</p>
          </li>
          <li>
            <h1>${Two_decimals(statisticsdata?.statistics?.expectedPayout ?? 0)}</h1>
            <p>Expected Payout</p>
          </li>
          <li>
            <h1>{statisticsdata?.statistics?.totalTagVehicleCitations ?? 0}</h1>
            <p>Tire Tags Issued This Week</p>
          </li>
          <li>
            <h1>{statisticsdata?.statistics?.totalPaidTagVehicleCitations ?? 0}</h1>
            <p>Tire Tag # of Paid Notices</p>
          </li>
        </ul>
        <ul className="satas-wrapper">
          <li>
            <h1>{calculatePercentage(statisticsdata?.statistics?.totalTagVehicleCitations, statisticsdata?.statistics?.totalPaidTagVehicleCitations) ?? 0}</h1>
            <p>Percent Paid Tire Tag</p>
          </li>
          <li>
            <h1>${Two_decimals(statisticsdata?.statistics?.expectedTiretagRevenue ?? 0)}</h1>
            <p>
              Expected Revenue for <br />
              Tire Tag Paid Notices
            </p>
          </li>
          <li>
            <h1>{statisticsdata?.statistics?.totalMailInCitations ?? 0}</h1>
            <p>Mail-In Notices Issued</p>
          </li>
          <li>
            <h1>{statisticsdata?.statistics?.totalPaidMailInCitations ?? 0}</h1>
            <p>Mail-In # of Paid Notices</p>
          </li>
        </ul>
        <ul className="satas-wrapper">
          <li>
            <h1>{calculatePercentage(statisticsdata?.statistics?.totalMailInCitations, statisticsdata?.statistics?.totalPaidMailInCitations) ?? 0}</h1>
            <p>
              Percent Paid for <br />
              Mail-In Notices
            </p>
          </li>
          <li>
            <h1>${Two_decimals(statisticsdata?.statistics?.totalPaidMailInAmount ?? 0)}</h1>
            <p>
              Expected Revenue for <br />
              Mail-In Paid Notices
            </p>
          </li>
        </ul>
        <h4>Lot Monitor</h4>
        <ul className="satas-wrapper rating-align-text">
          <li>
            <CircularProgressBar
              percentage={
                statisticsdata?.survey_statistics?.occupancy?.percentage ?? 0
              }
            />
            <p>Occupancy</p>
          </li>
          <li>
            <StarRating
              rating={
                statisticsdata?.survey_statistics?.cleanliness?.percentage ?? 0
              }
              type="cleanlinessRatings"
            />
            <p>Cleanliness</p>
          </li>
          <li>
            <span className="trash-icon-stats">
              <TrashIcon
                fillLevel={
                  statisticsdata?.survey_statistics?.["trash cans"]
                    ?.percentage ?? 0
                }
              />
            </span>
              <p>Trash Cans</p>
          </li>
        </ul>
        <ul className="satas-wrapper rating-align-text">
          <li>
            <StarRating
              rating={
                statisticsdata?.survey_statistics?.landscaping?.percentage ?? 0
              }
              type="landscapingRatings"
            />
            <p>Landscaping</p>
          </li>
          <li>
            <StarRating
              rating={statisticsdata?.survey_statistics?.signs?.percentage ?? 0}
              type="signageRatings"
            />
            <p>Signs</p>
          </li>
          <li hidden></li>
        </ul>
      </div>
    </section>
  );
}


function calculatePercentage(arg1, arg2){
  return (arg2 > 0 && arg1 > 0 ? (arg2 /arg1) * 100 : 0)?.toFixed(2)
}