import { connect } from "react-redux";
import { logins ,user, getuserdetails, editprofile , gettaggrdetails} from "../../../store/home/duck";
import TaggrComponent from "./admin_taggr_component";

const TaggrContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    getuserdetails,
    editprofile,
    gettaggrdetails
  }
)(TaggrComponent);

export default TaggrContainer;
