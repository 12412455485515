import { connect } from "react-redux";
import { lotprovider,user, deleteyourlot, addlots, connectWithStripe, connectWithStripeExpress } from "../../store/home/duck";
import ConnectWithStripComponent from "./connect_with_stripe_component";

const ConnectWithStripContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    lotprovider,
    user,
    deleteyourlot,
    addlots,
    connectWithStripe,
    connectWithStripeExpress
  }
)(ConnectWithStripComponent);

export default ConnectWithStripContainer;
