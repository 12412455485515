import React from "react";
import close from "../../../src/images/Close.svg"

const ConfirmationComponent = ({ title, show, onClose, onSubmit}) => {
  return (
    <div className={`modalContainer ${show ? "show" : ""}`} onClick={onClose}>
      <div className="modalContent">
        <div className="modaHeader">
              <h5 className="text-center">{title}</h5>
              <button onClick={onClose} className='RefundCloseHeader'><img src={close} alt="close" /></button>
        </div>
        <div className="modalBody">
          Your dispute has been started. You will receive a text with a determination and next steps Please allow our team up to 10 minutes to view the provided documentation and make a determination. Click pay now if you would like to proceed with making a payment at this time.
        </div>
        <div className="modalFooter" style={{display: "block"}}>
          <a
            className="DarkBlackBtn"
            href={`${
              process.env.REACT_APP_HOST ===
              "DEVELOPMENT"
                ? "http://localhost:3002"
                : "https://paytaggr.com"
            }`}
          >
            Pay
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationComponent;