import React from "react";
import DatePicker from "react-datepicker";
import { Pagination } from 'antd';
import Modal from "react-modal";
import { Link } from "react-router-dom";
import singleedit from "../../../images/single-edit.svg";
import search from "../../../images/search.svg";
import Download from "../../../components/svgIcons/Download";
import leftArrow from "../../../images/left-arrow.svg";
import Edit from "../../../images/edit.svg";
import Header from "../Citations-Header/container";
import { useEffect, useState, useReducer } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { setAlert, setLoading } from "../../../store/actioncreator";
import { useDispatch } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment-timezone"
import ClearDates from "../../../utils/clearDates/ClearDates";


export default function TaggrComponent(props) {

  const params = useParams();
  const dispatch = useDispatch();
  const tz = moment.tz.guess()


  const [startDate, setStartDate] = useState(null);
  const [endDate, setendDate] = useState(null);
  const [name, setName] = useState()
  const navigate = useNavigate();
  useEffect(() => {
    fetchName();
    callApi();
    gettaggrdetails(1)
    hideMenu();
  }, [])

  const fetchName = async () => {
    const { value } = await props.user()
    if (value?.success) {
      setName(value)
    }
  }
  ///////////////////////////////////////////////////////////////////


  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      gettaggrdetails(next?.currentPage)
    }
    return { ...prev, ...next }
  }, initialPageState)




  const [taggrdata, settaggrdata] = useState({})

  async function gettaggrdetails(page, dateFilter=true) {
    dispatch(setLoading(true))
    try {
      const id = params?.id
      const { value } = await props.gettaggrdetails(id, { page, size: 10, startDate: dateFilter ? startDate : null, endDate: dateFilter ? endDate : null })
      if (value.success) {
        settaggrdata(value)
        setPageState({ total: value?.total })
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
    dispatch(setLoading(false))
  }


  // function handleSubmit() {
  //     gettaggrdetails(1);
  // }

  function handleSubmit() {
    if (!startDate || !endDate) {
      dispatch(setAlert("error", "Please select start date and end date"))
    } else {
      dispatch(setLoading(true))
      gettaggrdetails(1);
    }
    setTimeout(() => {
      dispatch(setLoading(false))
    }, 300);
  }

  function clearDates() {
    setStartDate(null)
    setendDate(null)
    dispatch(setLoading(true))
    gettaggrdetails(1, false);
    setTimeout(() => {
      dispatch(setLoading(false))
    }, 300);
  }



  async function get_all_report_taggr() {
    try {
      const id = params?.id
      const { value } = await props.gettaggrdetails(id, { startDate, endDate })
      if (value?.success) {
        let header = ["Lot Name", "Address", "No. of Tags", "No. of Visits", "Taggr's Revenue($)"];
        const data = [
          header,
          ...value?.lots.map(user => [
            user?._id?.lotname ?? "--",
            `"${user?._id?.address ?? "--"}"`,
            user?.number_of_tags ?? 0,
            user?.visits ?? 0,
            user?.total_operator_revenue_amount ?? "0"
          ])
        ];
        const csv = data.map(row => row.join(",")).join("\n");
        const blob = new Blob([csv], { type: "text/csv" });
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        // link.download = "Alldata.csv";
        link.download = `${taggrname}_all_lots_admin_dashboard_report_${getCurrentDateTime()}.csv`;
        link.click();
        URL.revokeObjectURL(url);
      }
      else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
  }

  const getCurrentDateTime = () => {
    const now = moment().tz(tz);
    return now.format("YYYY-MM-DD_HH-mm-ss");
  };

  ////////////////////////////////////////////////////////////////////


  const [data, setdata] = useState({})
  const [pageData, setPageData] = useState({})
  const [taggrname, settaggrname] = useState()

  const callApi = async () => {
    dispatch(setLoading(true))
    try {
      const id = params.id
      const { value } = await props.getuserdetails(id)
      if (value.success) {
        // setdata(value)
        setdata({
          fullname: value?.user?.fullname,
          address: value?.user?.address,
          email: value?.user?.email,
          phone: value?.user?.phone,
          avatar: value?.user?.avatar
        })
        setPageData(value?.user)
        settaggrname(value?.user?.fullname)
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
    dispatch(setLoading(false))
  }



  const [editMode, setEditMode] = useState(false);
  const [error, seterror] = useState()

  const handleEditClick = async (e) => {
    if (e.key === "Enter" || e === "submit") {

      seterrmessage(false)
      seterrmessage1(false)

      try {

        setEditMode(!editMode);

        if (!!editMode) {
          const id = params?.id

          let err = false

          if (data.fullname.length === 0) {
            err = true
            seterror(true)
          }
          if (data.phone.length === 0) {
            err = true
            seterror(true)
          }
          if (data.email.length === 0) {
            err = true
            seterror(true)
          }
          if (data.address.length === 0) {
            err = true
            seterror(true)
          }


          if (!err) {

            const { value } = await props.editprofile({ user: data, userId: id });
            if (value?.success) {
              dispatch(setAlert('success', value?.message))
              callApi();
            } else {
              dispatch(setAlert('error', value?.message))
            }
          }
        }

      } catch (error) {
        dispatch(setAlert('error', error?.message))
      }
    }
  };



  // const handleChange = (e) => {
  //     setdata({ ...data, [e?.target?.name]: e.target.value })
  //     console.log(data, "------>");
  // };

  const [errmessage, seterrmessage] = useState()
  const [errmessage1, seterrmessage1] = useState()


  const handleChange = (e) => {
    const { name, value } = e.target;

    let err1 = false

    if (name === 'fullname' && /\d/.test(value)) {
      err1 = true
      // alert('Name field should not contain numbers');
      seterrmessage("Number not allowed!")
    } else {
      seterrmessage("")
    }

    if (name === 'phone' && /\D/.test(value)) {
      err1 = true;
      // alert('Phone field should only contain numbers');
      seterrmessage1("Text not allowed")
    } else {
      seterrmessage1("")
    }

    if (!err1) {
      setdata({ ...data, [name]: value });
    }
  };






  const [modalIsOpen, setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }


  function closeModal() {
    setIsOpen(false);
  }

  const hideMenu = () => {
    var element = document.getElementById("profile-wrapper");
    var menu = document.getElementById("test_menu");
    element.classList.add("left-wrapper");
    menu.classList.remove("showmenu");
  }

  return (
    <>
      <section className="adminDashboard">
        <Header />
        <div className="adminreport-wrapper" id="profile-wrapper">
          <div className="d-flex  align-items-center">
            <img
              src={leftArrow}
              className="left-arrow"
              onClick={() => navigate(-1)}
            />
            <h4>1099</h4>
          </div>
          <div className="report-list-wrapper secondndtab">
            <div className="pd-block">
              <div className="d-flex align-items-center justify-content-between pb-3 ">
                <div className="d-flex align-items-center">
                  <div className="taggr-logo-img">
                    {/* <img src={bart} /> */}
                    <img src={pageData?.avatar} />
                  </div>
                  <div className="logo-text">
                    {/* <h5>Bart Simpson</h5> */}
                    <h5>{pageData?.fullname}</h5>
                    <p className="d-flex align-items-center">
                      Email : {pageData?.email}
                    </p>
                  </div>
                </div>
              </div>
              <div className="date-picker-block">
                <div className="d-flex align-items-center border-block">
                  <div className="date-picker-list">
                    <label>Start Date</label>
                    <DatePicker
                      selected={startDate}
                      // onChange={(date) => setStartDate(date)}
                      onChange={(date) =>
                        setStartDate(moment(date).tz(tz).toDate())
                      }
                      placeholderText="--/--/----"
                      className="date-picker"
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="date-picker-list">
                    <label>End Date</label>
                    <DatePicker
                      selected={endDate}
                      // onChange={(date) => setendDate(date)}
                      // onChange={(date) =>
                      //     setendDate(moment(date).tz(tz).toDate())
                      //   }
                      onChange={(date) => {
                        const newDate = moment(date).tz(tz);
                        newDate.set({ hour: 23, minute: 59, second: 59 });
                        setendDate(newDate.toDate());
                      }}
                      placeholderText="--/--/----"
                      className="date-picker"
                      minDate={startDate}
                      maxDate={new Date()}
                      disabled={!startDate}
                    />
                  </div>
                </div>
                <div className="operator-btns">
                  <div className="border-block-btn">
                    <button className="btn DarkBtn" onClick={handleSubmit}>
                      Submit
                    </button>
                  </div>
                  <ClearDates onClick={clearDates} className="me-2"/>
                  <button
                    className="btn DarkBtn report-btn"
                    onClick={async () => {
                      await get_all_report_taggr();
                    }}
                  >
                    <Download />
                    All Report
                  </button>
                </div>
              </div>
            </div>

            <ul class="nav nav-pills">
              <li class="nav-item">
                <a class="nav-link active" data-bs-toggle="pill" href="#home">Report</a>
              </li>
              <li class="nav-item border-left-right">
                <a class="nav-link" data-bs-toggle="pill" href="#menu1">{data?.user?.fullname} Details</a>
              </li>
            </ul>
            <div class="tab-content">
              <div class="tab-pane  active" id="home">

                <div className="table-block table-responsive">
                  <table className="table StripTable">
                    <thead>
                      <tr>
                        <th>Lot</th>
                        <th>Address</th>
                        <th>No. of Tags</th>
                        <th>No. of Visits</th>
                        <th>Taggr's Revenue</th>
                        <th>Report</th>
                      </tr>
                    </thead>
                    <tbody>

                      {
                        (taggrdata?.lots && taggrdata?.lots.length > 0) ? (

                          taggrdata?.lots?.map((val, index) => (


                            <tr key={index}>

                              <td>{val?._id?.lotname ?? ""}{val?._id?.status === "deleted" && <strong> [Deleted]</strong>}</td>
                              <td>{val?._id?.address ?? ""}</td>
                              <td>{val?.number_of_tags ?? 0}</td>
                              <td>{val.visits ?? ""}</td>
                              <td>${val?.total_operator_revenue_amount ?? 0}</td>
                              {/* <td>
                                                    <button className="btn DarkBtn">Download</button>
                                                </td> */}

                              <td>
                                <CSVLink
                                  data={[
                                    ["Lot Name", "Address", "No. of Tags", "No. of Visits", "Taggr's Revenue($)"],
                                    [val?._id?.lotname ?? "", val?._id?.address ?? "", val?.number_of_tags ?? 0, val.visits ?? "", val?.total_operator_revenue_amount ?? 0]
                                  ]}
                                  filename={`${taggrname}_${val?._id?.lotname}_admin_dashboard-report_${getCurrentDateTime()}.csv`}
                                  className="btn DarkBtn m-0"
                                  target="_blank"
                                >
                                  <Download />
                                  Download
                                </CSVLink>
                              </td>

                            </tr>

                          ))
                        )
                          :
                          (
                            taggrdata?.lots === null || taggrdata?.lots?.length === 0 ?
                              <div className="dataText">
                                <h5>No Result Found</h5>
                              </div>
                              :
                              <div className="dataText">
                                <h5></h5>
                              </div>
                          )
                        //   (
                        //     <tr>
                        //         <td>
                        //             <div className="dataText">
                        //                 No Result Found
                        //             </div>
                        //         </td>
                        //     </tr>
                        // )

                      }
                    </tbody>
                  </table>
                  <div className='pagination-block'>
                    <Pagination
                      hideOnSinglePage
                      defaultCurrent={pageState?.defaultCurrentPage}
                      onChange={page => setPageState({ currentPage: page })}
                      current={pageState?.currentPage}
                      pageSize={pageState?.pageSize}
                      total={pageState?.total}
                      showSizeChanger={false}
                    />
                  </div>
                </div>
              </div>
              <div class="tab-pane  fade bart-tab" id="menu1">
                <div className="operator-details">
                  <div className="row">
                    <div className="col-lg-3">
                      <label>Contrators Name </label>
                      <div className="operator-detail-list" onClick={() => {
                        setEditMode(true)
                        seterror(false);
                        seterrmessage(false);
                        seterrmessage1(false)
                      }
                      }>
                        <input
                          type="text"
                          id="text"
                          name="fullname"
                          //   disabled
                          value={data?.fullname ?? ""}
                          onChange={handleChange}
                          onKeyDown={handleEditClick}
                          disabled={!editMode}
                        />
                        <img src={singleedit}
                          onClick={() => handleEditClick("submit")}
                        />
                      </div>
                      {errmessage && <p className="Error">{errmessage}</p>}
                      {error && data?.fullname <= 0 ? (
                        <div className="Error">Please enter fullname</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-3">
                      <label>Address</label>
                      <div className="operator-detail-list" onClick={() => {
                        setEditMode(true)
                        seterror(false);
                        seterrmessage(false);
                        seterrmessage1(false)
                      }
                      }>
                        <input type="address"
                          id="address"
                          name="address"
                          // disabled
                          value={data?.address ?? ""}
                          onChange={handleChange}
                          onKeyDown={handleEditClick}
                          disabled={!editMode}
                        />
                        <img src={singleedit}
                          onClick={() => handleEditClick("submit")}
                        />
                      </div>
                      {error && data?.address <= 0 ? (
                        <div className="Error">Please enter address</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-3">
                      <label>Email Address</label>
                      <div className="operator-detail-list" onClick={() => {
                        setEditMode(true)
                        seterror(false);
                        seterrmessage(false);
                        seterrmessage1(false)
                      }
                      }>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          //  disabled
                          value={data?.email ?? ""}
                          onChange={handleChange}
                          onKeyDown={handleEditClick}
                          disabled={!editMode}
                        />
                        <img src={singleedit}
                          onClick={() => handleEditClick("submit")}
                        />
                      </div>
                      {error && data?.email <= 0 ? (
                        <div className="Error">Please enter email</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-lg-3">
                      <label>Contact No</label>
                      <div className="operator-detail-list" onClick={() => {
                        setEditMode(true)
                        seterror(false);
                        seterrmessage(false);
                        seterrmessage1(false)
                      }
                      }
                      >
                        <input
                          type="text"
                          id="text"
                          name="phone"
                          maxLength={10}
                          //   disabled
                          value={data?.phone ?? ""}
                          onChange={handleChange}
                          onKeyDown={handleEditClick}
                          disabled={!editMode}
                        />
                        <img src={singleedit}
                          onClick={() => handleEditClick("submit")}
                        />
                      </div>
                      {error && data?.phone <= 0 ? (
                        <div className="Error">Please enter contact number</div>
                      ) : (
                        ""
                      )}
                      {errmessage1 && <p className="Error">{errmessage1}</p>}
                    </div>
                  </div>

                  <div className="pt-4 d-flex justify-content-center">
                    <button
                      className="DarkBtn m-auto"
                      onClick={() => handleEditClick("submit")}
                    >
                      {" "}
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade operator-modal"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
        tabindex="-1"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel2">
                Mountain View Parking
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <label>Lot Name</label>
              <p>
                Mountain View Parking <img src={Edit} />
              </p>
              <label>Address</label>
              <p>
                3330 S Ocean Blvd, 618C, Highland Beach, FL <img src={Edit} />
              </p>
              <label>Report</label>
              <div className="date-picker">
                <div className="date-picker-list">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="date-picker"
                  />
                </div>
                <div className="date-picker-list">
                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    className="date-picker"
                  />
                </div>
              </div>
              <div className="d-flex align-items-center justify-content-between">
                <button className="btn DarkBtn m-0">
                  <Download />
                  Download
                </button>
                <div>
                  <div className="switch-toggle">
                    <label class="switch">
                      <input type="checkbox" />
                      <span class="slider round"></span>
                    </label>
                  </div>
                  <div className="switch-toggle">
                    <p>Deactivate</p>
                    <label class="switch">
                      <input type="checkbox" checked />
                      <span class="slider round"></span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}



























