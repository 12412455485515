import React from 'react';
import PropTypes from 'prop-types';
import "./style.css"

const StarRating = ({ rating = 0, type = "cleanlinessRatings" }) => {
  // Round rating to nearest 0.5
  let roundedRating = 0
  if(rating === 0){
    roundedRating = 0
  } 
  else if(rating <= 20) {
    roundedRating = 1
  }
   else if (rating <= 40) {
    roundedRating = 2;
  } 
  else if (rating <= 60) {
    roundedRating = 3;
  }
  else if (rating <= 80) {
    roundedRating = 4;
  }
   else {
    roundedRating = 5;
  }
  const totalStars = 5;
  const yellowStars = Math.min(roundedRating, totalStars);
  const greyStars = totalStars - yellowStars;
  const ratingText = {
    cleanlinessRatings: {
      0: (rating) + "%",
      1: "Needs Attention",
      2: "Improvement Needed",
      3: "Average Cleanliness",
      4: "Above Average Cleanliness",
      5: "Great Cleanliness"
    },
    landscapingRatings : {
      0: (rating) + "%",
      1: "Needs Improvement",
      2: "Fair",
      3: "Good",
      4: "Very Good",
      5: "Excellent"
    },
    signageRatings: {
      0: (rating) + "%",
      1: "Inadequate",
      2: "Fair",
      3: "Good",
      4: "Very Good",
      5: "Excellent"
    }
  }

  console.log(yellowStars, greyStars, "<<<stars")
  return (
    <>
    <div className="star-rating">
      {[...Array(Math.floor(yellowStars))].map((_, index) => (
        <span key={index} className="star yellow-star">&#9733;</span>
        ))}
      {[...Array(Math.floor(greyStars))].map((_, index) => (
        <span key={index + yellowStars} className="star grey-star">&#9733;</span>
        ))}
    </div>
     <p style={{marginBottom: '20px'}}>{ratingText[type][roundedRating]}</p>
    </>
  );
};

StarRating.propTypes = {
  rating: PropTypes.number.isRequired,
};

export default StarRating;
