import { connect } from "react-redux";
import {  user ,editprofile } from "../../store/home/duck";
import OffenceComponent from "./component";

const OffenceContainer = connect( 
  // Map state to props
  (state) => ({
  }),
  // Map actions to dispatch and props
  {
    user,
    editprofile
  }
)(OffenceComponent);

export default OffenceContainer;
