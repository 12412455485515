import React from "react";
import Modal from "react-modal";
import { useParams } from "react-router-dom";
import { Pagination } from 'antd';
import search from "../../../images/search.svg";
import location from "../../../../src/images/location.svg";
import citationImage from "../../../images/citation-image.png";
import image from "../../../images/image.svg";
import violation from "../../../images/violations.svg";
import Details from "../../../images/details.svg";
import leftArrow from "../../../images/left-arrow.svg";
import Header from "../Citations-Header/container";
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { setAlert } from "../../../store/actioncreator";
import { useDispatch } from "react-redux";
import { Two_decimals, capitalize } from "../../../global";
import Refund_modal from "../../../utils/refund_modal";
import moment from "moment-timezone";
import Refund_Breakdown from "./Refund_Breakdown";
export default function CitationsInformationComponent(props) {
    const tz = moment.tz?.guess()
    // const { id } = useParams()
    const params = useParams();
    const dispatch = useDispatch();
    const [reason, setReason] = useState('')
    const [taggrFee, setTaggrFee] = useState(false);
    const proof = new URLSearchParams(window.location.search)?.get("is_proof");
    const [data, setdata] = useState({})

    const initialPageState = {
        showModal: false,
        operator_name : '',
        amount: 0,
        citation_type: null
      }
    
      const [pageState, setPageState] = useState(initialPageState)

    useEffect(() => {
        callApi();
        hideMenu();
    }, [])

    const on_void_citation_modal_submit = async (payment_source_id) => {
        let err
        if (!reason || reason?.trim()?.length <= 0) {
          err = true
          dispatch(setAlert('error', "Reason can't be empty!"))
        }
        if (!err) {
          setPageState({ show_void_modal: false })
    
          try {
            var text = `Your notice has been voided. Reasoning: ${reason} Release code ${data?.tag_release_code}. Please place the tag in the yellow drop box located ${data?.lot?.dropbox?.dropboxDescription}`
            const { value } = await props.void_citation(pageState?.void_citation_id, reason, taggrFee, payment_source_id);
            if (value?.success) {
              dispatch(setAlert("success", value?.message))
              on_modal_submit_text(data.point_of_contact, text)
            } else {
              dispatch(setAlert("error", value?.message))
            }
          } catch (error) {
            dispatch(setAlert("error", error?.message))
          }
          setReason('')
          callApi();
          setTaggrFee(false)
        }
      };

    const on_deny_citation_modal_submit = async () =>{
        let err
        if (!reason || reason?.trim()?.length <= 0) {
          err = true
          dispatch(setAlert('error', "Reason can't be empty!"))
        }
        if (!err) {
          setPageState({ show_deny_modal: false })
          try {
            const { value } = await props.deny_citation(pageState?.deny_citation_id, reason);
            if (value?.success) {
              dispatch(setAlert("success", value?.message))
              on_modal_submit_text(data.point_of_contact, `Your dispute has been denied. Reasoning: ${reason}. There is nothing more we can do at this time. Click https://www.paytaggr.com to make your payment.`)
            } else {
              dispatch(setAlert("error", value?.message))
            }
          } catch (error) {
            dispatch(setAlert("error", error?.message))
          }
          setReason('')
        }
        callApi();
    }

    const on_reduce_citation_modal_submit = async () =>{
        let err
        if (!reason || reason?.trim()?.length <= 0) {
          err = true
          dispatch(setAlert('error', "Reason can't be empty!"))
        }
        if (!err) {
          setPageState({ show_reduce_modal: false })
          try {
            const { value } = await props.reduce_citation(pageState?.reduce_citation_id, reason);
            if (value?.success) {
              dispatch(setAlert("success", value?.message))
                on_modal_submit_text(data.point_of_contact, `Your notice has been reduced to the minimum fee: $55. Reasoning: ${reason}. There is nothing more we can do at this time. Click https://www.paytaggr.com to make your payment.`)
            } else {
              dispatch(setAlert("error", value?.message))
            }
          } catch (error) {
            dispatch(setAlert("error", error?.message))
          }
          setReason('')
          callApi();
        }
    }

    const on_modal_submit_text = async (to,text) =>{
        let err
        if (!text) {
          err = true
          dispatch(setAlert('error', "Text can't be empty"))
        }
        if (!err) {
          try {
            const { value } = await props.send_sms(to, text);
            if (value?.success) {
              dispatch(setAlert("success", "Message sent to the violator"))
            } else {
              dispatch(setAlert("error", value?.message))
            }
          } catch (error) {
            dispatch(setAlert("error", error?.message))
          }
        }
    }

    const callApi = async () => {
        try {
            const id = params.id
            const { value } = await props.get_single_citation_details(id)
            if (value.success) {
                setdata(value?.citation)
            } else {
                dispatch(setAlert("error", value?.message))
            }
        } catch (error) {
            dispatch(setAlert("error", error?.message))
        }
    }

    const hideMenu = () => {
        var element = document.getElementById("profile-wrapper");
        var menu = document.getElementById("test_menu");
        element.classList.add("left-wrapper");
        menu.classList.remove("showmenu");
      }



    const navigate = useNavigate();
    return (
        <>
            <section className="adminCitations">
                <Header />
                <div className="citationsSection paddzero" id="profile-wrapper">
                    <div className="title-wrapper">
                        <div className="d-flex align-items-center">
                            <img src={leftArrow} className="left-arrow" onClick={() => navigate(-1)} />
                            <h4>{proof ? "Case Details" : "Notices"}</h4>
                        </div>
                    </div>
                    <div className="cirtation-wrapper">
                        <div className={proof ? 'proof-container' : ''}>
                            <div className={proof ? 'left-block-proof' : ''}>
                                <div className="details-block-wrapper">
                                    <h6><img src={Details} />Notice Details</h6>
                                    <div className="details-wrapper-block">
                                        <div className="details-list">
                                            <label>Notice Number</label>
                                            <input
                                                value={data?.qr_code ?? data?.tiretag_citation_data?.citation_number ?? data?.parkpliant_reference_id ?? ""}
                                                type="text" id="text"
                                                className="form-control"
                                                disabled />
                                        </div>
                                        <div className="details-list">
                                            <label>Vehicle</label>
                                            <input
                                                value={data?.vehicle?.licensePlate ?? ""}
                                                type="text"
                                                id="text"
                                                className="form-control"
                                                disabled />
                                        </div>
                                        <div className="details-list">
                                            <label>Location</label>
                                            <input value={data?.lot?.address ?? ""} type="text" id="text" className="form-control" disabled />
                                        </div>
                                        <div className="details-list">
                                            <label>Issued by </label>
                                            <input value={data?.user?.fullname ?? ''} type="text" id="text" className="form-control" disabled />
                                        </div>
                                        <div className="details-list">
                                            <label>Tire Tag S. No.</label>
                                            <input value={data?.tireTagSerialNumber ?? ""} type="text" id="text" className="form-control" disabled />
                                        </div>
                                        <div className="details-list">
                                            <label>Citation issued time </label>
                                            <input value={moment(data?.createdAt)?.tz(tz).format('YYYY-MM-DD HH:mm') ?? ''} type="text" id="text" className="form-control" style={{width:"100%"}} disabled />
                                        </div>
                                        {!proof && <div className="details-list">
                                            <label>Release code</label>
                                            <input value={data?.tag_release_code} type="text" id="text" className="form-control" disabled />
                                        </div>}
                                        {!proof && <div className="details-list">
                                            <label>Cheque Info</label>
                                            <input value={data?.chequeInfo?.Id} type="text" id="text" className="form-control" disabled />
                                        </div>}
                                        {!proof && <div className="dropbox-textarea">
                                            <label>Drop box description</label>
                                            <textarea value={data?.lot?.dropbox?.dropboxDescription} disabled></textarea>
                                        </div> } 
                                        {!proof && <div className="dropbox-textarea">
                                            <label>Void description</label>
                                            <textarea value={data?.void?.reason} disabled></textarea>
                                        </div> } 
                                    </div>
                                </div>
                                <div className="details-block-wrapper">
                                    <h6><img src={violation} />Violation Fees and Details</h6>
                                    <div className="details-wrapper-block">

                                        <div className="details-list">
                                            <label>Violation code(s)</label>
                                            <input value={data?.offence?.offenceName ?? data?.enforcement_lite_reason} type="text" id="text" className="form-control" disabled />
                                        </div>
                                        <div className="details-list">
                                            <label>Violation fee</label>
                                            <input value={data?.break_down_amount?.offence_fees > 60 ? data?.break_down_amount?.offence_fees + data?.break_down_amount?.tire_tag?.amount : data?.break_down_amount?.offence_fees } type="text" id="text" className="form-control" disabled />
                                        </div>
                                        <div className="details-list">
                                            <label>Payment due</label>
                                            <input value={data?.paid ? 0 : data?.amount} type="text" id="text" className="form-control" disabled />
                                        </div>
                                        {!proof && <div className="details-list">
                                            <label>Location</label>
                                            <input value={data?.lot?.address ?? ""} type="text" id="text" className="form-control" disabled />
                                        </div>}
                                        <div className="details-list">
                                            <label>Holding fee</label>
                                            <input value={Two_decimals(data?.break_down_amount?.holding_fees - data?.break_down_amount?.stripe_fees_holding) ?? ""} type="text" id="text" className="form-control" disabled />
                                        </div>
                                        <div className="details-list">
                                            <label>Payment status</label>
                                            <input value={capitalize(data?.refund?.length > 0 ? data?.refund?.[0]?.refund_type + " Refund" : data?.payment_status) ?? ''} type="text" id="text" className="form-control" disabled />
                                        </div>
                                        { proof && data?.is_reduced && <div className="details-list">
                                            <label>Reduce Reason</label>
                                            <input value={capitalize(data?.reduce_reason) ?? ''} type="text" id="text" className="form-control" disabled />
                                        </div>}
                                        { proof && data?.is_denied && <div className="details-list">
                                            <label>Deny Reason</label>
                                            <input value={capitalize(data?.denied_reason) ?? ''} type="text" id="text" className="form-control" disabled />
                                        </div>}
                                        { data?.payment_status == 'voided' && <div className="details-list">
                                            <label>Void Reason</label>
                                            <input value={capitalize(data?.void?.reason) ?? ''} type="text" id="text" className="form-control" disabled />
                                        </div>}
                                        {!proof && <div className="details-list">
                                            <label>Transaction Id</label>
                                            <input value={capitalize(data?.txnId) ?? ''} type="text" id="text" className="form-control" disabled />
                                        </div>}
                                        {!proof && <div className="details-list">
                                            <label>Voided Transaction Id</label>
                                            <input value={capitalize(data?.voidedTxnId) ?? ''} type="text" id="text" className="form-control" disabled />
                                        </div>}
                                    </div>
                                </div>
                            </div>

                            { !proof && <div className="details-block-wrapper">
                                <h6><img src={violation} />Breakdown Amount</h6>
                                <div className="details-wrapper-block">
                                
                                    <div className="details-list">
                                        <label>Taggr amount</label>
                                        <input value={data?.break_down_amount?.taggr?.amount ?? 0} type="text" id="text" className="form-control" disabled />
                                    </div>
                                    <div className="details-list">
                                        <label>Operator amount</label>
                                        <input value={data?.break_down_amount?.operator?.amount ?? 0} type="text" id="text" className="form-control" disabled />
                                    </div>
                                    <div className="details-list">
                                        <label>Tire tag amount</label>
                                        <input value={data?.break_down_amount?.tire_tag?.amount ?? 0} type="text" id="text" className="form-control" disabled />
                                    </div>
                                    <div className="details-list">
                                        <label>Taggr solutions amount</label>
                                        <input value={data?.break_down_amount?.taggr_solutions?.amount  ?? 0} type="text" id="text" className="form-control" disabled />
                                    </div>                               
                                    <div className="details-list">
                                        <label>Dock fees</label>
                                        <input value={data?.break_down_amount?.dock_fees ?? 0} type="text" id="text" className="form-control" disabled />
                                    </div>
                                    <div className="details-list">
                                        <label>Total stripe fees</label>
                                        <input value={data?.break_down_amount?.total_stripe_fees ?? 0} type="text" id="text" className="form-control" disabled />
                                    </div>
                                    <div className="details-list">
                                        <label>Call center fees</label>
                                        <input value={data?.break_down_amount?.service_fee ?? 0} type="text" id="text" className="form-control" disabled />
                                    </div>
                                </div>
                            </div>}
                            { !proof && data?.payment_status === "refunded"
                             && <Refund_Breakdown data={data} />}

                            { proof && 
                                <div className={proof ? "right-block-proof" : 'details-block-wrapper'}>
                                    <h6><img src={image} alt='Proof'/>Image</h6>
                                    <div className="proof-image">
                                        <img src={data?.proof?.image} />
                                    </div>
                                </div>
                            }
                        </div>
                        {proof && <div className="custombutton-container">
                            <button
                                className="custombutton"
                                onClick={() => {
                                if (data?.payment_status === "voided") {
                                    dispatch(
                                    setAlert(
                                        "info",
                                        "Notice already voided."
                                    )
                                    );
                                } else if (
                                    data?.payment_status !== "paid" &&
                                    data?.payment_status !== "refunded"
                                ) {
                                setPageState({
                                    void_citation_id: data?._id,
                                    citation_type: data?.citation_type,
                                    show_void_modal: true,
                                    void_popup_title: data?.citation_type === "tag_vehicle"
                                        ? data?.tiretag_citation_data?.citation_number
                                        : data?.citation_mail_in_number?.replace("MAIL_IN_", " "),
                                    amount: (
                                        data?.break_down_amount
                                        ?.calculative_amount +
                                        data?.break_down_amount
                                        ?.stripe_fees_offence
                                    )?.toFixed(2),
                                    });
                                } else {
                                    dispatch(
                                    setAlert(
                                        "info",
                                        "You can't void a Notice after it's paid!"
                                    )
                                    );
                                }
                                }}
                                disabled={data?.payment_status === "voided"}
                            >
                                {data?.payment_status === "voided"
                                ? "Voided"
                                : "Void"}
                            </button>
                            
                            <button className="custombutton"
                             onClick={() => {
                                    if ((data?.break_down_amount
                                        ?.calculative_amount +
                                        data?.break_down_amount
                                        ?.stripe_fees_offence) <= 55) {
                                        dispatch(
                                        setAlert(
                                            "info",
                                            "Notice has already been reduced to minimum fee"
                                        )
                                        );
                                    }else if (data?.is_denied){
                                        dispatch(
                                            setAlert(
                                                "info",
                                                "Notice already denied."
                                            )
                                        );
                                    }else if ((
                                        data?.break_down_amount
                                            ?.calculative_amount +
                                            data?.break_down_amount
                                            ?.stripe_fees_offence) > 55
                                    ) {
                                        setPageState({
                                            reduce_citation_id: data?._id,
                                            show_reduce_modal: true,
                                            reduce_popup_title:
                                                data?.tiretag_citation_data
                                                ?.citation_number,
                                            amount: (
                                                data?.break_down_amount
                                                ?.calculative_amount +
                                                data?.break_down_amount
                                                ?.stripe_fees_offence
                                            )?.toFixed(2),
                                        });
                                    }
                                    else {
                                        dispatch(
                                        setAlert(
                                            "info",
                                            "You can't reduce a Notice after it's paid!"
                                        )
                                        );
                                    }
                                }
                             }
                                disabled={data?.is_reduced}
                            >Reduce</button>  
                            <button className="custombutton"
                            onClick={() => {
                                if (data?.payment_status === "voided") {
                                    dispatch(
                                    setAlert(
                                        "info",
                                        "Notice already voided."
                                    )
                                    );
                                }  else if (data?.is_reduced){
                                    dispatch(
                                        setAlert(
                                            "info",
                                            "Notice already reduced."
                                        )
                                    );
                                } 
                                else if (
                                    data?.payment_status !== "paid" &&
                                    data?.payment_status !== "refunded"
                                ) {
                                setPageState({
                                    deny_citation_id: data?._id,
                                    show_deny_modal: true,
                                    deny_popup_title:
                                        data?.tiretag_citation_data
                                        ?.citation_number,
                                    amount: (
                                        data?.break_down_amount
                                        ?.calculative_amount +
                                        data?.break_down_amount
                                        ?.stripe_fees_offence
                                    )?.toFixed(2),
                                    });
                                } else {
                                    dispatch(
                                    setAlert(
                                        "info",
                                        "You can't deny a Notice after it's paid!"
                                    )
                                    );
                                }
                                }}
                                disabled={data?.is_denied}
                            >Deny</button>
                        </div>}
                        <Refund_modal
                            show={pageState?.show_void_modal}
                            onClose={() => {
                                setPageState({ show_void_modal: false })
                                setReason('')
                                setTaggrFee(false)
                            }}
                            setReason={(e) => setReason(e?.target?.value)}
                            onSubmit={on_void_citation_modal_submit}
                            amount={pageState?.amount}
                            reason={reason}
                            title={pageState?.void_popup_title}
                            citation_type={pageState?.citation_type}
                            header_title="Void"
                            setTaggrFee={setTaggrFee}
                            taggrFee={taggrFee}
                        />

                        <Refund_modal
                            show={pageState?.show_reduce_modal}
                            onClose={() => setPageState({ show_reduce_modal: false }, setReason(''))}
                            setReason={(e) => setReason(e?.target?.value)}
                            onSubmit={on_reduce_citation_modal_submit}
                            amount={pageState?.amount}
                            reason={reason}
                            title={pageState?.reduce_popup_title}
                            header_title="Reduce"
                        />

                        <Refund_modal
                            show={pageState?.show_deny_modal}
                            onClose={() => setPageState({ show_deny_modal: false }, setReason(''))}
                            setReason={(e) => setReason(e?.target?.value)}
                            onSubmit={on_deny_citation_modal_submit}
                            amount={pageState?.amount}
                            reason={reason}
                            title={pageState?.deny_popup_title}
                            header_title="Deny"
                        />


                        {!proof && <div className="details-block-wrapper">
                            <h6><img src={Details} />Vehicle Details</h6>
                            <div className="details-wrapper-block">

                                <div className="details-list">
                                    <label>Vehicle plate</label>
                                    <input value={data?.vehicle?.licensePlate ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>State</label>
                                    <input value={data?.state ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                <div className="details-list">
                                    <label>Make</label>
                                    <input value={data?.vehicle?.make ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                                {(data?.citation_type !== 'enforcement_lite') &&
                                    <div className="details-list ">
                                    <label>Model</label>
                                    <input value={data?.vehicle?.model ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>}
                                <div className="details-list">

                                    <label>Color</label>
                                    <input value={data?.vehicle?.color ?? ""} type="text" id="text" className="form-control" disabled />
                                </div>
                            </div>
                        </div>}
                        { !proof && <div className="images-wrapper">
                            <h6><img src={image} />Image</h6>
                            <div className="details-wrapper-block">

                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.licensePlateImage ?? data?.images?.license_plate_image} />
                                    </div>
                                </div>

                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.signageImage ?? data?.images?.dashboard_image ?? data?.images?.dashboardImage} />
                                    </div>
                                </div>

                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.windowDecalImage ?? data?.images?.vehicle10Image} />
                                    </div>
                                </div>

                                <div className="details-list">

                                    <div className="citations_image">
                                        <img src={data?.images?.tireTagWheelImage} />
                                    </div>
                                </div>
                                {
                                    data?.images?.optionalImage?.length>0 &&
                                    data?.images?.optionalImage.map((item) => <div className="details-list">
                                        <div className="citations_image">
                                            <img src={item} />
                                        </div>
                                    </div>)
                                }
                            </div>
                        </div>}

                        {!proof && data?.remark && <div className="details-wrapper ">

                            <h6><img src={Details} />Remarks</h6>
                            <p>{data?.remark}</p>
                        </div>}
                    </div>
                </div>
            </section>
        </>
    );
}
