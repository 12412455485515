import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Header from "../Citations-Header/container";
import leftArrow from "../../../images/left-arrow.svg";
import payoutleftArrow from "../../../images/payout-left-arrow.svg";
import payoutrightArrow from "../../../images/payout-right-arrow.svg";
import DetailIcon from "../../../images/detailIcon.svg";
import { Link } from "react-router-dom";
import { setAlert, setLoading } from "../../../store/actioncreator";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Pagination } from 'antd';
import moment from "moment-timezone"
import DarkView from "../../../images/DarkViewImg.svg";
import WhiteView from "../../../images/WhiteViewImg.svg";

export default function NewPayouts(props) {

    const handleViewDetails_taggr = (taggr, index) => {
        const taggrString = JSON.stringify(taggr);
        localStorage.setItem('selectedTaggr', taggrString);
        navigate(`/payoutdetails`);
    };

    const handleViewDetails_operator = (operator, index) => {
        const taggrString = JSON.stringify(operator);
        localStorage.setItem('selectedTaggr', taggrString);
        navigate(`/payoutdetails`);
    };

    const handleViewDetails_tire_tag = (tire_tag, index) => {
        const taggrString = JSON.stringify(tire_tag);
        localStorage.setItem('selectedTaggr', taggrString);
        navigate(`/payoutdetails`);
    };

    const handleViewDetails_taggr_solutions = (taggr_solutions, index) => {
        const taggrString = JSON.stringify(taggr_solutions);
        localStorage.setItem('selectedTaggr', taggrString);
        navigate(`/payoutdetails`);
    };

    const [isForwardDisable, setForwardDisable] = useState(true)
    const [role, setRole] = useState("vendor")

    const [data, setdata] = useState([])
    const navigate = useNavigate();
    const tz = moment.tz.guess()
    const dispatch = useDispatch();
    const currentDay_of_newyork = moment().tz("America/New_York").format('dddd');
    const currentHour = moment().tz("America/New_York").hour();

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const totalPages = Math.ceil(data?.users?.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    ///////////////////////////////////////////////////////////
    


    const isWednesday = currentDay_of_newyork === 'Wednesday' && currentHour >= 6;
    const current_Wednesday = moment().tz("America/New_York").day(3).hour(6).minute(0).second(0);
    const currentDayy = moment().tz("America/New_York").day();
    const daysToSubtract = currentDayy === 3 ? 7 : currentDayy < 3 ? currentDayy + 4 : currentDayy - 3;
    const currentWednesday = moment().tz("America/New_York").subtract(daysToSubtract, 'days').hour(6).minute(0).second(0);
    const [startDate, setStartDate] = useState(isWednesday ? current_Wednesday.toDate() : currentWednesday.toDate());
    const [endDate, setendDate] = useState(moment().tz("America/New_York").toDate());

    const handleBackward = () => {
        setForwardDisable(false)
        const currentDate = startDate ? moment(startDate)?.tz("America/New_York") : moment()?.tz("America/New_York").startOf('day').hour(6); // Set start date to today at 6 PM
        const currentDay = currentDate.day();
        const daysToSubtract = currentDay === 3 ? 7 : currentDay < 3 ? currentDay + 4 : currentDay - 3;
        const previousWednesday = moment(currentDate)?.tz("America/New_York").subtract(daysToSubtract, 'days').hour(6); // Set time to 6 PM
        const lastWednesday = moment()?.tz("America/New_York").day(-4).hour(6).minute(0); // Get the last Wednesday before the current date (time set to 6 PM)

        if (previousWednesday.isAfter(lastWednesday)) {
            setStartDate(lastWednesday.toDate());
            setendDate(moment(lastWednesday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Wednesday at 6 PM
        } else if (previousWednesday.isAfter(moment()?.tz("America/New_York"))) {
            setStartDate(moment()?.tz("America/New_York").toDate());
        } else {
            setStartDate(previousWednesday.toDate());
            setendDate(moment(previousWednesday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Wednesday at 6 PM
        }

        // Make API call with updated dates
        fetchvalue(role);
    };

    const handleForward = () => {
        let currentDate = endDate ? moment(endDate)?.tz("America/New_York") : moment()?.tz("America/New_York").startOf('day').hour(6).minute(0); // Set end date to today at 6 PM
        const currentDay = currentDate.day();
        const daysToAdd = currentDay === 3 ? 7 : currentDay < 3 ? 3 - currentDay : 10 - currentDay;
        const nextWednesday = moment(currentDate)?.tz("America/New_York").add(daysToAdd, 'days').hour(6).minute(0).second(0); // Set time to 6 PM
        const lastWednesday = moment()?.tz("America/New_York").day(-4).hour(6).minute(0).second(0); // Get the last Wednesday before the current date (time set to 6 PM)
        const actual_current_data = moment()?.tz("America/New_York")
      
        if (currentDate.isBefore(nextWednesday) && !actual_current_data?.isBefore(nextWednesday)) {
            console.log('work')
            setStartDate(currentDate?.toDate());
            setendDate(nextWednesday?.second(0).toDate()); // Set end date to the next Wednesday at 6 AM
        }
        else if (actual_current_data?.isBefore(nextWednesday) && currentHour < 6) {
            console.log('work2')
            if (currentDate.minute(0).second(0).millisecond(0).toDate() >= moment(actual_current_data).minute(0).second(0).millisecond(0)?.toDate()) {
                currentDate = lastWednesday.add(7, "days")
                console.log("if")
            }
            else {
                setStartDate(currentDate?.toDate());
                console.log("else")
                setForwardDisable(true)
            }
            setendDate(actual_current_data.toDate());
        }
        else if (actual_current_data?.isBefore(nextWednesday)) {
            console.log('work3')
            if (currentDate.minute(0).second(0).millisecond(0).toDate() >= actual_current_data.minute(0).second(0).millisecond(0)?.toDate()) {
                currentDate = lastWednesday.add(7, "days")
                console.log("if")
            }
            else {
                setStartDate(currentDate?.toDate());
                console.log("else")
                setForwardDisable(true)
            }
            setendDate(actual_current_data?.toDate());
        }
        // if (currentDate.isBefore(nextWednesday) && currentHour < 6) {
        //     console.log("000000");
        //     setStartDate(lastWednesday.toDate());
        //     setendDate(moment(lastWednesday)?.tz("America/New_York").add(7, 'days').hour(6).minute(0).second(0).toDate()); // Set end date to the next Wednesday at 6 AM
        // } else if (currentDate.isBefore(nextWednesday)) {
        //     setStartDate(currentDate.toDate());
        //     if (currentDate.isAfter(lastWednesday)) {
        //         console.log('work')
        //         setendDate(moment()?.tz("America/New_York").hour(6).minute(0).second(0).toDate())
        //     } else {
        //         console.log("111111");
        //         setendDate(moment()?.tz("America/New_York").toDate()); // Set end date to the next Wednesday at 6 AM
        //     }
        // } else {
        //     console.log("22222");
        //     setStartDate(nextWednesday.toDate());
        //     setendDate(moment(nextWednesday)?.tz("America/New_York").add(7, 'days').hour(6).minute(0).second(0).toDate()); // Set end date to the next Wednesday at 6 AM
        // }

        //   Make API call with updated dates
        fetchvalue(role);
    };

    // ********************************************************************************


    // // const isFriday = currentDay_of_newyork === 'Friday';
    // const isFriday = currentDay_of_newyork === 'Friday' && currentHour >= 6;
    // const current_Friday = moment().tz("America/New_York").day(5).hour(6).minute(0).second(0);
    // const currentDayy = moment().tz("America/New_York").day();
    // const daysToSubtract = currentDayy === 5 ? 7 : currentDayy < 5 ? currentDayy + 2 : currentDayy - 5;
    // const currentFriday = moment().tz("America/New_York").subtract(daysToSubtract, 'days').hour(6).minute(0).second(0);

    // // const [startDate, setStartDate] = useState(currentFriday.toDate());
    // const [startDate, setStartDate] = useState(isFriday ? current_Friday.toDate() : currentFriday.toDate());
    // const [endDate, setendDate] = useState(moment().tz("America/New_York").toDate());

    // const handleBackward = () => {
    //     const currentDate = startDate ? moment(startDate)?.tz("America/New_York") : moment()?.tz("America/New_York").startOf('day').hour(); // Set start date to today at 6 PM
    //     const currentDay = currentDate.day();
    //     const daysToSubtract = currentDay === 5 ? 7 : currentDay < 5 ? currentDay + 2 : currentDay - 5;
    //     const previousFriday = moment(currentDate)?.tz("America/New_York").subtract(daysToSubtract, 'days').hour(6); // Set time to 6 PM
    //     const lastFriday = moment()?.tz("America/New_York").day(-2).hour(6).minute(0); // Get the last Friday before the current date (time set to 6 PM)

    //     // Check if the previousFriday is greater than the lastFriday (cannot be later than the last Friday)
    //     if (previousFriday.isAfter(lastFriday)) {
    //         console.log("11112222", startDate, endDate);
    //         setStartDate(lastFriday.toDate());
    //         setendDate(moment(lastFriday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Friday at 6 PM
    //     } else if (previousFriday.isAfter(moment()?.tz("America/New_York"))) {
    //         console.log("111133333", startDate, endDate);
    //         // Set the start date to the current date if it's greater
    //         setStartDate(moment()?.tz("America/New_York").toDate());
    //     } else {
    //         console.log("11114444", startDate, endDate);
    //         setStartDate(previousFriday.toDate());
    //         setendDate(moment(previousFriday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Friday at 6 PM
    //     }

    //     // Make API call with updated dates
    //     fetchvalue(role);
    // };



    // const handleForward = () => {
    //     const currentDate = endDate ? moment(endDate)?.tz("America/New_York") : moment()?.tz("America/New_York").startOf('day').hour(6).minute(0); // Set end date to today at 6 PM
    //     const currentDay = currentDate.day();
    //     const daysToAdd = currentDay === 5 ? 7 : currentDay < 5 ? 5 - currentDay : 12 - currentDay;
    //     const nextFriday = moment(currentDate)?.tz("America/New_York").add(daysToAdd, 'days').hour(6).minute(0).second(0); // Set time to 6 PM
    //     const lastFriday = moment()?.tz("America/New_York").day(-2).hour(6).minute(0).second(0); // Get the last Friday before the current date (time set to 6 PM)

    //     // Check if the nextFriday is greater than the lastFriday (cannot be later than the last Friday)
    //     if (nextFriday.isAfter(lastFriday)) {
    //         setStartDate(isFriday ? current_Friday.toDate() : lastFriday.toDate());
    //         console.log("0000", startDate, endDate, ">>>>>", isFriday, ">>>>>>", current_Friday.toDate());
    //         if (moment()?.tz("America/New_York").toDate() - endDate) {
    //             setendDate(moment()?.tz("America/New_York").toDate() > moment()?.tz("America/New_York").hour(6).minute(0).second(0).toDate() ? moment()?.tz("America/New_York").hour(6).minute(0).second(0).toDate() : moment()?.tz("America/New_York").toDate());
    //         } else {

    //             setendDate(moment(lastFriday)?.tz("America/New_York").add(7, 'days').hour(6).minute(0).second(0).toDate()); // Set end date to the next Friday at 6 PM
    //         }
    //         //   setendDate(moment().toDate()); // Set end date to the next Friday at 6 PM
    //     } else if (nextFriday.isAfter(moment()?.tz("America/New_York"))) {
    //         // Set the end date and start date to the current date if it's greater
    //         setendDate(moment()?.tz("America/New_York").toDate());
    //         setStartDate(moment()?.tz("America/New_York").toDate());
    //         console.log("1");
    //     } else {
    //         console.log("2");
    //         setStartDate(nextFriday.toDate());
    //         setendDate(moment(nextFriday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Friday at 6 PM
    //     }

    //     // Make API call with updated dates
    //     fetchvalue(role);
    // };




    useEffect(() => {
        fetchvalue(role)
    }, [])

    useEffect(() => {
        fetchvalue(role);
    }, [startDate, endDate]);


    async function fetchvalue(role) {
        dispatch(setLoading(true))
        try {
            const { value } = await props?.reconcilation(role, { startDate, endDate });
            if (value?.success) {
                setdata(value)
                // console.log(data?.success, "00000000");
            } else {
                dispatch(setAlert('error', value?.message));
                console.log(value?.message);
            }
        } catch (error) {
            dispatch(setAlert('error', error?.message));
            console.log(error?.message);
        }
        dispatch(setLoading(false))
    }

    return (
        <>
            <section className="adminDashboard">
                <Header />
                <div className="adminreport-wrapper" id="profile-wrapper">
                    <div className="d-flex  align-items-center">
                        <img
                            src={leftArrow}
                            className="left-arrow"
                            onClick={() => navigate(-1)}
                        />
                        <h4>Payouts</h4>
                    </div>

                    <div className="payoutscardSection">
                        <ul className="nav nav-tabs">
                            <li className="nav-item"
                                onClick={() => {
                                    setRole("vendor")
                                    fetchvalue("vendor")
                                }}>
                                <h6>Taggr</h6>
                                <a className="nav-link active" data-bs-toggle="tab" href="#home">
                                    <div className="payoutsCards">
                                        <h1><span>$</span>{data?.statistics?.taggr}</h1>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item"
                                onClick={() => {
                                    setRole("operator")
                                    fetchvalue("operator")
                                }}>
                                <h6>Operator</h6>
                                <a className="nav-link" data-bs-toggle="tab" href="#menu1">
                                    <div className="payoutsCards">
                                        <h1><span>$</span>{data?.statistics?.operator}</h1>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item"
                                onClick={() => {
                                    setRole("tire_tag")
                                    fetchvalue("tire_tag")
                                }}>
                                <h6>Tire Tag</h6>
                                <a className="nav-link" data-bs-toggle="tab" href="#menu2">
                                    <div className="payoutsCards">
                                        <h1><span>$</span>{data?.statistics?.tire_tag}</h1>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item"
                                onClick={() => {
                                    setRole("taggr_solutions")
                                    fetchvalue("taggr_solutions")
                                }}>
                                <h6>Taggr Solution</h6>
                                <a className="nav-link" data-bs-toggle="tab" href="#menu3">
                                    <div className="payoutsCards">
                                        <h1><span>$</span>{data?.statistics?.taggr_solutions}</h1>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div className="d-flex align-items-center justify-content-evenly gap-3 border-block">
                            <button onClick={handleBackward}><img src={payoutleftArrow} /></button>
                            <div className=" d-flex align-items-center justify-content-center gap-3">
                                <div className="date-picker-list">
                                    <label>Start Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        placeholderText="--/--/----"
                                        className="date-picker"
                                        onChange={(date) => setStartDate(date)}
                                        disabled
                                    />
                                </div>
                                <div className="date-picker-list">
                                    <label>End Date </label>
                                    <DatePicker
                                        selected={endDate}
                                        placeholderText="--/--/----"
                                        className="date-picker"
                                        onChange={(date) => setendDate(date)}
                                        disabled
                                    />
                                </div>
                            </div>
                            <button disabled={isForwardDisable} onClick={handleForward}><img src={payoutrightArrow} /></button>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane container active" id="home">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" >S. No.</th>
                                            <th scope="col">Full Name</th>
                                            <th scope="col">Lotname</th>
                                            <th scope="col">Tags</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">View Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        {/* {data?.users?.map((taggr, index) => ( */}
                                        {data?.users?.slice(startIndex, endIndex)?.map((taggr, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{taggr?.fullname}</td>
                                                <td>{taggr?.lotname}</td>
                                                <td>{taggr?.tags}</td>
                                                <td >{taggr?.total_amount}</td>
                                                <td>
                                                    <div className="eyeBtn">
                                                        <img src={DarkView} className="DarkImg" onClick={() => handleViewDetails_taggr(taggr, index)} />
                                                        <img src={WhiteView} className="WhiteImg" onClick={() => handleViewDetails_taggr(taggr, index)} />
                                                    </div>
                                                    {/* <img src={DetailIcon} className="detailIcon" onClick={() => handleViewDetails_taggr(taggr, index)} /> */}
                                                </td>
                                                {/* <td ><a href="/payoutdetails"><img src={DetailIcon} className="detailIcon"/></a></td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='pagination-block'>
                                    <Pagination
                                        hideOnSinglePage
                                        defaultCurrent={1}
                                        onChange={page => {
                                            setCurrentPage(page)
                                        }}
                                        current={currentPage}
                                        pageSize={5}
                                        total={data?.users?.length}
                                        showSizeChanger={false}
                                    />
                                </div>
                            </div>
                            <div className="tab-pane container fade" id="menu1">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">Full Name</th>
                                            <th scope="col">Lot Name</th>
                                            <th scope="col">Tags</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {data?.users?.map((operator, index) => ( */}
                                        {data?.users?.slice(startIndex, endIndex)?.map((operator, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{operator?.fullname}</td>
                                                <td>{operator?.lotname}</td>
                                                <td>{operator.tags}</td>
                                                <td>{operator.total_amount}</td>
                                                <td >
                                                    <div className="eyeBtn">
                                                        <img src={DarkView} className="DarkImg" onClick={() => handleViewDetails_operator(operator, index)} />
                                                        <img src={WhiteView} className="WhiteImg" onClick={() => handleViewDetails_operator(operator, index)} />
                                                    </div>

                                                    {/* <img src={DetailIcon} className="detailIcon" onClick={() => handleViewDetails_operator(operator, index)} /> */}
                                                </td>
                                                {/* <td ><a href="/payoutdetails"><img src={DetailIcon} className="detailIcon"/></a></td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='pagination-block'>
                                    <Pagination
                                        hideOnSinglePage
                                        defaultCurrent={1}
                                        onChange={page => {
                                            setCurrentPage(page)
                                        }}
                                        current={currentPage}
                                        pageSize={5}
                                        total={data?.users?.length}
                                        showSizeChanger={false}
                                    />
                                </div>
                            </div>
                            <div className="tab-pane container fade" id="menu2">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">S. No.</th>
                                            <th scope="col">FullName</th>
                                            <th scope="col">Lot Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {data?.users?.map((tire_tag, index) => ( */}
                                        {data?.users?.slice(startIndex, endIndex)?.map((tire_tag, index) => (
                                            <tr key={index}>
                                                <th scope="row">{index + 1}</th>
                                                <td>{tire_tag?.fullname}</td>
                                                <td>{tire_tag?.lotname}</td>
                                                <td>{tire_tag.tags}</td>
                                                <td>{tire_tag.total_amount}</td>
                                                <td>
                                                    <div className="eyeBtn">
                                                        <img src={DarkView} className="DarkImg" onClick={() => handleViewDetails_tire_tag(tire_tag, index)} />
                                                        <img src={WhiteView} className="WhiteImg" onClick={() => handleViewDetails_tire_tag(tire_tag, index)} />
                                                    </div>
                                                    {/* <img src={DetailIcon} className="detailIcon" onClick={() => handleViewDetails_tire_tag(tire_tag, index)} /> */}
                                                </td>
                                                {/* <td ><a href="/payoutdetails"><img src={DetailIcon} className="detailIcon"/></a></td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='pagination-block'>
                                    <Pagination
                                        hideOnSinglePage
                                        defaultCurrent={1}
                                        onChange={page => {
                                            setCurrentPage(page)
                                        }}
                                        current={currentPage}
                                        pageSize={5}
                                        total={data?.users?.length}
                                        showSizeChanger={false}
                                    />
                                </div>
                            </div>
                            <div className="tab-pane container fade" id="menu3">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col" >S. No.</th>
                                            <th scope="col">FullName</th>
                                            <th scope="col">Lot Name</th>
                                            <th scope="col">Tags</th>
                                            <th scope="col">Total Amount</th>
                                            <th scope="col">View Details</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* {data?.users?.map((taggr_solutions, index) => ( */}
                                        {data?.users?.slice(startIndex, endIndex)?.map((taggr_solutions, index) => (
                                            <tr key={index}>
                                                <th scope="row" >{index + 1}</th>
                                                <td>{taggr_solutions?.fullname}</td>
                                                <td>{taggr_solutions?.lotname}</td>
                                                <td>{taggr_solutions.tags}</td>
                                                <td>{taggr_solutions.total_amount}</td>
                                                <td>
                                                    <div className="eyeBtn">
                                                        <img src={DarkView} className="DarkImg" onClick={() => handleViewDetails_taggr_solutions(taggr_solutions, index)} />
                                                        <img src={WhiteView} className="WhiteImg" onClick={() => handleViewDetails_taggr_solutions(taggr_solutions, index)} />
                                                    </div>
                                                    {/* <img src={DetailIcon} className="detailIcon" onClick={() => handleViewDetails_taggr_solutions(taggr_solutions, index)} /> */}
                                                </td>
                                                {/* <td ><a href="/payoutdetails"><img src={DetailIcon} className="detailIcon"/></a></td> */}
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <div className='pagination-block'>
                                    <Pagination
                                        hideOnSinglePage
                                        defaultCurrent={1}
                                        onChange={page => {
                                            setCurrentPage(page)
                                        }}
                                        current={currentPage}
                                        pageSize={5}
                                        total={data?.users?.length}
                                        showSizeChanger={false}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
