import React, { useEffect } from "react";
import { Grid, Card, Switch, Tooltip, Box, Typography } from "@mui/material";

const NoticePermissionCard = (props) => {
  const { modules, setModules, isViewOnly = false } = props;
  useEffect(() => {
    if (modules.Notice_view === undefined) {
      setModules((prevModules) => ({
        ...prevModules,
        Notice_view: true,
        Notice_add: true,
        Notice_update: true,
        Notice_delete: true,
        Notice_active_deactive: true,
      }));
    }
  }, [modules, setModules]);

  const handleNoticeViewChange = (event) => {
    const isNoticeViewEnabled = event.target.checked;
    setModules({
      ...modules,
      Notice_view: isNoticeViewEnabled,
      ...(isNoticeViewEnabled
        ? {}
        : {
            Notice_add: false,
            Notice_update: false,
            Notice_delete: false,
            Notice_active_deactive: false,
          }),
    });
  };

  return (
    <>
      <Card sx={{ minWidth: 275, boxShadow: 3 }}>
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} container alignItems="center">
              <Typography
                color="dark"
                display="block"
                variant="subtitle2"
                fontWeight="bold"
                sx={{ flex: 1 }}
              >
                Notice Module
              </Typography>
              <Tooltip
                title={`${
                  modules.Notice_view ? "Disable" : "Enable"
                } Notice module`}
                placement="top"
              >
                <Switch
                  checked={modules.Notice_view}
                  onChange={handleNoticeViewChange}
                  disabled={isViewOnly}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <Typography variant="caption" fontWeight="regular">
                    Add Notice
                  </Typography>

                  <Switch
                    checked={modules.Notice_add}
                    onChange={(event) =>
                      setModules({
                        ...modules,
                        Notice_add: event.target.checked,
                      })
                    }
                    disabled={!modules.Notice_view || isViewOnly}
                  />
                </Grid>

                <Grid item xs={6} md={4}>
                  <Typography variant="caption" fontWeight="regular">
                    Update Notice
                  </Typography>
                  <Switch
                    checked={modules.Notice_update}
                    onChange={(event) =>
                      setModules({
                        ...modules,
                        Notice_update: event.target.checked,
                      })
                    }
                    disabled={!modules.Notice_view || isViewOnly}
                  />
                </Grid>

                <Grid item xs={6} md={4}>
                  <Typography variant="caption" fontWeight="regular">
                    Delete Notice
                  </Typography>
                  <Switch
                    checked={modules.Notice_delete}
                    onChange={(event) =>
                      setModules({
                        ...modules,
                        Notice_delete: event.target.checked,
                      })
                    }
                    disabled={!modules.Notice_view || isViewOnly}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography variant="caption" fontWeight="regular">
                    Active/Deactive Notice
                  </Typography>
                  <Switch
                    checked={modules.Notice_active_deactive}
                    onChange={(event) =>
                      setModules({
                        ...modules,
                        Notice_active_deactive: event.target.checked,
                      })
                    }
                    disabled={!modules.Notice_view || isViewOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default NoticePermissionCard;
