import React, { useReducer } from "react";
import DatePicker from "react-datepicker";
import Download from "../../../components/svgIcons/Download";
import { Pagination } from 'antd';
import Header from "../Citations-Header/container";
import leftArrow from "../../../images/left-arrow.svg";
import { useEffect, useState } from 'react';
import { Navigate, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setAlert,setLoading } from "../../../store/actioncreator";
import { CSVLink } from "react-csv";
import moment from "moment-timezone"
import { Two_decimals } from "../../../global";
import { DataGrid } from "@mui/x-data-grid"
import ClearDates from "../../../utils/clearDates/ClearDates";

const OPERATOR_CSV_HEADERS = [
    "Operator's",
    "# of Visits",
    "Tire Tags",
    "Paid Tire Tags",
    "Unpaid Tire Tags",
    "Voided Tire Tags",
    "Mail Ins",
    "Paid Mail Ins",
    "Unpaid Mail Ins",
    "Voided Mail Ins",
    "Reduced Tire Tags",
    "Reduced Mail Ins",
    "Revenue from Tire Tags",
    "Revenue from Mail ins",
    "Total Revenue",
    "Taggr Solutions Revenue",
    "Operator Revenue",
]

const TAGGR_HEADERS = [
    "Taggr's (1099)",
    "# of Visits",
    "Tire Tags",
    "Paid Tire Tags",
    "Unpaid Tire Tags",
    "Voided Tire Tags",
    "Mail ins",
    "Paid Mail ins",
    "Unpaid Mail ins",
    "Voided Mail Ins",
    "Reduced Tire Tags",
    "Reduced Mail Ins",
    "Revenue from Tire Tags",
    "Revenue from Mail Ins",
    "Total Revenue",
    "Taggrsolutions Revenue",
    "Taggr Revenue"
  ];

function generateOperatorCSVData (user) {
    return [
        user?.fullname ?? "-",
        user?.visits ?? 0,
        user?.number_of_tire_tags ?? 0,
        user?.number_of_paid_tire_tags ?? 0,
        user?.number_of_unpaid_tire_tags ?? 0,
        user?.voided_tire_tags ?? 0,
        user?.number_of_mail_ins ?? 0,
        user?.number_of_paid_mail_ins ?? 0,
        user?.number_of_unpaid_mail_ins ?? 0,
        user?.voided_mail_ins ?? 0,
        user.reduced_tire_tags ?? 0,
        user?.reduce_mail_ins ?? 0,
        user?.revenue_from_tire_tag ?? 0,
        user?.revenue_from_mail_ins ?? 0,
        (user?.revenue_from_tire_tag ?? 0) + (user?.revenue_from_mail_ins ?? 0),
        user?.revenue_taggr_solutions ?? 0,
        user?.revenue_operator ?? 0,
    //    Two_decimals( user?.operator_payout + user?.taggr_payout + 
    //     user?.taggr_solutions_payout + user?.tire_tag_payout)
    //     ?? 0 
    ]
}

function generateTaggrCSVData(taggr) {
    return [
        taggr?.fullname ?? '-',
        taggr?.visits ?? 0,
        taggr?.number_of_tire_tags ?? 0,
        taggr?.number_of_paid_tire_tags ?? 0,
        taggr?.number_of_unpaid_tire_tags ?? 0,
        taggr?.voided_tire_tags ?? 0,
        taggr?.number_of_mail_ins ?? 0,
        taggr?.number_of_paid_mail_ins ?? 0,
        taggr?.number_of_unpaid_mail_ins ?? 0,
        taggr?.voided_mail_ins ?? 0,
        taggr?.reduced_tire_tags ?? 0,
        taggr?.reduce_mail_ins ?? 0,
        taggr?.revenue_from_tire_tag ?? 0,
        taggr?.revenue_from_mail_ins ?? 0,
        (taggr?.revenue_from_tire_tag ?? 0) + (taggr?.revenue_from_mail_ins ?? 0),
        taggr?.revenue_operator ?? 0,
        taggr?.total_taggr_revenue_amount ?? 0,
    ];
}

// const REPORT_FILTERS = [{key: "Tire Tag", value: "tag_vehicle"}, {key: "Mail In", value: "mail_in"}]

export default function AdminReportComponent(props) {

    const [startDate, setStartDate] = useState(null);
    const [endDate, setendDate] = useState(null);
    const tz = moment.tz.guess()
    // const [reportFilter, setReportFilter] = useState("All")




    const [role, setRole] = useState("operator")
    const navigate = useNavigate();
    const dispatch = useDispatch()

    let render = true
    const ROLES = {
        operator: "operator",
        vendor: "vendor"
    }

    useEffect(() => {
        get_operator_taggr_visit(1, role)
    }, [])


    const initialPageState = {
        total: 0,
        defaultCurrentPage: 1,
        currentPage: 1,
        pageSize: 10,
        users: []
    }


    const [pageState, setPageState] = useReducer((prev, next) => {
        if (next?.currentPage && (!render || next?.isPagechange))
            get_operator_taggr_visit(next?.currentPage, role)
        return { ...prev, ...next }
    }, initialPageState)

    render = false

    async function get_operator_taggr_visit(page, role, dateFilter=true) {
        dispatch(setLoading(true))
        try {
            const { value } = await props?.get_operator_taggr_visit(role, { page, size: 10, startDate: dateFilter ? startDate : null, endDate: dateFilter ? endDate : null });
            if (value?.success) {
                setPageState({ total: value?.total, users: value?.users });
            } else {
                dispatch(setAlert('error', value?.message));
            }
        } catch (error) {
            dispatch(setAlert('error', error?.message));
        }
        dispatch(setLoading(false))
    }


    // async function get_all_operator_taggr_visit(role) {
    //     console.log(role, ">>>>role");
    //     try {
    //         const { value } = await props?.get_operator_taggr_visit(role, { startDate, endDate });
    //         if (value?.success) {
    //             console.log(value, "************");
    //             const data = [
    //                 ["Operator", "Address", "No. of Tags", "No. of Visits", "Total Revenue"],
    //                 ...value?.users.map(user => [user.fullname ?? "--name",  `"${user.address ?? "--address"}"`, 26 , user.visits ?? 0, "$6000"])
    //               ];
    //               const csv = data.map(row => row.join(",")).join("\n");
    //               const blob = new Blob([csv], { type: "text/csv" });
    //               const url = URL.createObjectURL(blob);
    //               const link = document.createElement("a");
    //               link.href = url;
    //               link.download = "data.csv";
    //               link.click();
    //               URL.revokeObjectURL(url);
    //         }
    //     } catch (error) {
    //         dispatch(setAlert('error', error?.message));
    //     }
    // }


    async function get_all_operator_taggr_visit(role) {
        try {
            const { value } = await props?.get_operator_taggr_visit(role, { startDate, endDate });
            if (value?.success) {
                console.log(value,"--------------->>>>>>>>>>");
                // let revenueKey = "operator_payout";
                const data = [
                    role === "vendor" ? TAGGR_HEADERS : OPERATOR_CSV_HEADERS,
                    ...value?.users.map(user => role === "vendor"
                    ? generateTaggrCSVData(user)
                    : generateOperatorCSVData(user))
                ];
                const csv = data.map(row => row.join(",")).join("\n");
                const blob = new Blob([csv], { type: "text/csv" });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;              
                const reportType = role === "vendor" ? "Taggr's" : "Operator's";
                link.download = `All-${reportType}-_admin_dashboard_report_${getCurrentDateTime()}.csv`;
                link.click();
                URL.revokeObjectURL(url);
            }
        } catch (error) {
            dispatch(setAlert('error', error?.message));
        }
    }


    const getCurrentDateTime = () => {
        const now = moment().tz(tz);
        return now.format("YYYY-MM-DD_HH-mm-ss");
    };



    function handleSubmit() {
        if (!startDate || !endDate) {
            dispatch(setAlert('error', "Please select start date and end date"));
            console.log("Please select start and end date");
        } else {
            dispatch(setLoading(true))
            get_operator_taggr_visit(1, role);
        }
        setTimeout(() => {
            dispatch(setLoading(false))
          }, 300);
    }

    function clearDates() {
       setStartDate(null)
       setendDate(null)
        dispatch(setLoading(true))
        get_operator_taggr_visit(1, role, false);
        setTimeout(() => {
            dispatch(setLoading(false))
          }, 300);
    }

    // function handleSubmit(e) {
    //     get_operator_taggr_visit(1, role);
    // }

    const handleEndDateSelection = (date) => {
        const currentDate = new Date();
        const selectedEndDate = moment(date).tz(tz).toDate(); // get the selected end date  
        const newEndDate = new Date(selectedEndDate.getFullYear(), selectedEndDate.getMonth(), selectedEndDate.getDate(), currentDate.getHours(), currentDate.getMinutes(), currentDate.getSeconds(), currentDate.getMilliseconds());
        setendDate(newEndDate);
    }

    const columns =
      role === "vendor"
        ? [
            {
              field: "fullname",
              headerName: "Taggr's (1099)",
              width: 200,
              hideable: false,
              editable: false,
              pinned: 'left'
            },
            {
              field: "visits",
              headerName: "# of Visits",
              width: 150,
              valueFormatter: (params) => (params ? params : 0),
            },
            {
              field: "number_of_tire_tags",
              headerName: "Tire Tags",
              width: 150,
            },
            {
              field: "number_of_paid_tire_tags",
              headerName: "Paid Tire Tags",
              width: 150,
            },
            {
              field: "number_of_unpaid_tire_tags",
              headerName: "Unpaid Tire Tags",
              width: 150,
            },
            {
              field: "voided_tire_tags",
              headerName: "Voided Tire Tags",
              width: 150,
            },
            { field: "number_of_mail_ins", headerName: "Mail ins", width: 150 },
            {
              field: "number_of_paid_mail_ins",
              headerName: "Paid Mail ins",
              width: 150,
            },
            {
              field: "number_of_unpaid_mail_ins",
              headerName: "Unpaid Mail ins",
              width: 150,
            },
            {
              field: "voided_mail_ins",
              headerName: "Voided Mail Ins",
              width: 150,
            },
            {
              field: "reduced_tire_tags",
              headerName: "Reduced Tire Tags",
              width: 150,
            },
            {
              field: "reduce_mail_ins",
              headerName: "Reduced Mail Ins",
              width: 150,
            },
            {
              field: "revenue_from_tire_tag",
              headerName: "Revenue from Tire Tags",
              width: 200,
              valueFormatter: (params) => `$${params}`,
            },
            {
              field: "revenue_from_mail_ins",
              headerName: "Revenue from Mail Ins",
              width: 200,
              valueFormatter: (params) => `$${params}`,
            },
            {
              field: "total_revenue",
              headerName: "Total Revenue",
              width: 200,
              valueFormatter: (params) => `$${params}`,
            },
            {
              field: "revenue_taggr_solutions",
              headerName: "Taggr Solutions Revenue",
              width: 200,
              valueFormatter: (params) => `$${params}`,
            },
            {
              field: "total_taggr_revenue_amount",
              headerName: "Taggr Revenue",
              width: 200,
              valueFormatter: (params) => `$${params}`,
            },
          ]
        : [
            { 
              field: "fullname",
              headerName: "Operator's",
              width: 200,
              hideable: false,
              editable: false,
            },
            {
              field: "visits",
              headerName: "# of Visits",
              width: 150,
              valueFormatter: (params) => (params ? params : 0),
            },
            {
              field: "number_of_tire_tags",
              headerName: "Tire Tags",
              width: 150,
            },
            {
              field: "number_of_paid_tire_tags",
              headerName: "Paid Tire Tags",
              width: 150,
            },
            {
              field: "number_of_unpaid_tire_tags",
              headerName: "Unpaid Tire Tags",
              width: 150,
            },
            {
                field: "voided_tire_tags",
                headerName: "Voided Tire Tags",
                width: 150,
            },
            { field: "number_of_mail_ins", headerName: "Mail Ins", width: 150 },
            {
              field: "number_of_paid_mail_ins",
              headerName: "Paid Mail Ins",
              width: 150,
            },
            {
              field: "number_of_unpaid_mail_ins",
              headerName: "Unpaid Mail Ins",
              width: 150,
            },
            {
                field: "voided_mail_ins",
                headerName: "Voided Mail Ins",
                width: 150,
            },
            {
              field: "reduced_tire_tags",
              headerName: "Reduced Tire Tags",
              width: 150,
            },
            {
              field: "reduce_mail_ins",
              headerName: "Reduced Mail Ins",
              width: 150,
            },
            {
              field: "revenue_from_tire_tag",
              headerName: "Revenue from Tire Tags",
              width: 200,
              valueFormatter: (params) => `$${params}`,
            },
            {
              field: "revenue_from_mail_ins",
              headerName: "Revenue from Mail ins",
              width: 200,
              valueFormatter: (params) => `$${params}`,
            },
            {
                field: "total_revenue",
                headerName: "Total Revenue",
                width: 200,
                valueFormatter: (params) => `$${params}`,
            },
            {
              field: "revenue_taggr_solutions",
              headerName: "Taggr Solutions Revenue",
              width: 200,
              valueFormatter: (params) => `$${params}`,
            },
            {
              field: "revenue_operator",
              headerName: "Operator Revenue",
              width: 200,
              valueFormatter: (params) => `$${params}`,
            },
          ];


    return (
        <>
            <section className="adminDashboard">
                <Header />
                <div className="adminreport-wrapper taggr-list-wrapper" id="profile-wrapper">
                    <div className="d-flex  align-items-center">
                        <img src={leftArrow} className="left-arrow" onClick={() => navigate(-1)} />
                        <h4>Report</h4>
                    </div>
                    <div className="report-block-wrapper">
                        <ul className="nav nav-pills">
                            <li className="nav-item">
                                <a className="nav-link active" data-bs-toggle="pill" href="#home"
                                    onClick={() => {
                                        setRole("operator")
                                        setPageState(initialPageState)
                                        get_operator_taggr_visit(1, "operator")
                                    }}
                                >Operators</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" data-bs-toggle="pill" href="#menu1"
                                    onClick={() => {
                                        setRole("vendor")
                                        setPageState(initialPageState)
                                        get_operator_taggr_visit(1, "vendor")
                                    }}
                                >1099</a>
                            </li>
                        </ul>
                        <div className="tab-content">
                            <div className="tab-pane  active" id="home">
                                <div className="date-picker-block mb-4 ">
                                    <div className="d-flex align-items-center border-block">
                                        <div className="date-picker-list">
                                            <label>Start Date</label>
                                            <DatePicker
                                                selected={startDate}
                                                // onChange={(date) => setStartDate(date)}
                                                onChange={(date) =>
                                                    setStartDate(moment(date).tz(tz).toDate())
                                                }
                                                placeholderText="--/--/----"
                                                className="date-picker"
                                                maxDate={new Date()}
                                            />
                                        </div>
                                        <div className="date-picker-list">
                                            <label>End Date</label>
                                            <DatePicker
                                                selected={endDate}                                             
                                                // onChange={(date) =>
                                                //     setendDate(moment(date).tz(tz).toDate())
                                                // }
                                                onChange={(date) => {
                                                    const newDate = moment(date).tz(tz);
                                                    newDate.set({ hour: 23, minute: 59, second: 59 });
                                                    setendDate(newDate.toDate());
                                                }}                                                
                                                placeholderText="--/--/----"
                                                className="date-picker"
                                                minDate={startDate}
                                                maxDate={new Date()}
                                                disabled={!startDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="operator-btns">
                                        <div className="border-block-btn">
                                            <button className="btn DarkBtn" onClick={handleSubmit}>Submit</button>
                                        </div>
                                        <ClearDates
                                        onClick={clearDates}
                                        className="me-2"/>

                      <button
                        className="btn DarkBtn report-btn"
                        onClick={async () => {
                          await get_all_operator_taggr_visit(role);
                        }}
                      >
                        <Download />
                        All Report
                      </button>
                      {/* <select
                        className="CustomSelect report-filter"
                        value={reportFilter}
                        onChange={(e) => {
                            setReportFilter(e?.target?.value)
                           console.log("Selected filter", e.target.value);
                        }}
                      >
                        <option value="All">All</option>
                       {REPORT_FILTERS?.map((filter, idx) => (
                           <option key={idx} value={filter?.value}>
                               {filter?.key}
                           </option>
                       ))}
                      </select> */}

                      {/* <CSVLink
                                            filename={`${"alldata"}.csv`}
                                            className="btn DarkBtn report-btn"                                            
                                        >
                                            <Download />
                                            All Report
                                        </CSVLink> */}
                                    </div>
                                </div>
                                <div className="table-block ">
                                    <div className="table-responsive">
                                    {/* <div style={{ height: 400, width: '100%' }}> */}
                                    <DataGrid
                                        rows={
                                            pageState?.users?.map((user, index) => ({
                                                ...user,
                                                total_revenue: (user?.revenue_from_tire_tag ?? 0) + (user?.revenue_from_mail_ins ?? 0)
                                            })
                                        )}
                                        columns={columns}
                                        pageSize={pageState.pageSize}
                                        rowsPerPageOptions={[10]}
                                        // hideFooterPagination
                                        // hideFooterSelectedRowCount
                                        hideFooter
                                        pagination
                                        onPageChange={(params) => setPageState({ currentPage: params.page })}
                                        rowCount={pageState.total}
                                        paginationMode="server"
                                        loading={props.loading}
                                        getRowId={(row) => row._id}
                                    />
                                    </div>
                                    <div className='pagination-block'>
                                        <Pagination
                                            hideOnSinglePage
                                            defaultCurrent={pageState?.defaultCurrentPage}
                                            onChange={page => setPageState({ currentPage: page, isPagechange: true })}
                                            current={pageState?.currentPage}
                                            pageSize={pageState?.pageSize}
                                            total={pageState?.total}
                                        />
                                    </div>
                                </div>

                            </div>
                            <div className="tab-pane  fade" id="menu1">
                                <div className="date-picker-block mb-4 ">
                                    <div className="d-flex align-items-center border-block ">
                                        <div className="date-picker-list">
                                            <label>Start Date</label>
                                            <DatePicker
                                                selected={startDate}
                                                // onChange={(date) => setStartDate(date)}
                                                onChange={(date) =>
                                                    setStartDate(moment(date).tz(tz).toDate())
                                                }
                                                placeholderText="--/--/----"
                                                className="date-picker"
                                                maxDate={new Date()}
                                            />
                                        </div>
                                        <div className="date-picker-list">
                                            <label>End Date</label>
                                            <DatePicker
                                                selected={endDate}
                                                // onChange={(date) => setendDate(date)}
                                                // onChange={(date) =>
                                                //     setendDate(moment(date).tz(tz).toDate())
                                                // }
                                                onChange={(date) => {
                                                    const newDate = moment(date).tz(tz);
                                                    newDate.set({ hour: 23, minute: 59, second: 59 });
                                                    setendDate(newDate.toDate());
                                                }}  
                                                placeholderText="--/--/----"
                                                className="date-picker"
                                                minDate={startDate}
                                                maxDate={new Date()}
                                                disabled={!startDate}
                                            />
                                        </div>
                                    </div>
                                    <div className="operator-btns">
                                        <div className="border-block-btn">
                                            <button className="btn DarkBtn" onClick={handleSubmit} >Submit</button>
                                        </div>
                                        <ClearDates onClick={clearDates} className="me-2"/>
                                        <button className="btn DarkBtn report-btn" onClick={async () => {
                                            await get_all_operator_taggr_visit(role);
                                        }}><Download />All Report</button>
                                        {/* <CSVLink
                                            filename={`${"alltaggrdata"}.csv`}
                                            className="btn DarkBtn report-btn"                                           
                                        >
                                            <Download />
                                            All Report
                                        </CSVLink> */}
                                    </div>
                                </div>
                                <div className="table-block ">
                                    <div className="table-responsive">
                                    {/* <div style={{ height: 400, width: '100%' }}> */}
                                    <DataGrid
                                        rows={pageState?.users?.map((user, index) => ({
                                                ...user,
                                                total_revenue: (user?.revenue_from_tire_tag ?? 0) + (user?.revenue_from_mail_ins ?? 0)
                                            })
                                        )}
                                        columns={columns}
                                        pageSize={pageState.pageSize}
                                        rowsPerPageOptions={[10]}
                                        // hideFooterPagination
                                        // hideFooterSelectedRowCount
                                        hideFooter
                                        pagination
                                        onPageChange={(params) => setPageState({ currentPage: params.page })}
                                        rowCount={pageState.total}
                                        paginationMode="server"
                                        loading={props.loading}
                                        getRowId={(row) => row._id}
                                    />
                                    </div>
                                    <div className='pagination-block'>
                                        <Pagination
                                            hideOnSinglePage
                                            defaultCurrent={pageState?.defaultCurrentPage}
                                            onChange={page => setPageState({ currentPage: page, isPagechange: true })}
                                            current={pageState?.currentPage}
                                            pageSize={pageState?.pageSize}
                                            total={pageState?.total}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
