import { connect } from "react-redux";
import { logins ,user,statistics_operator, lotprovider} from "../../store/home/duck";
import DashboardComponent from "./component";

const DashboardContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,user,statistics_operator,
    lotprovider
  }
)(DashboardComponent);

export default DashboardContainer;