import React, { useState, useCallback, useEffect, useRef, memo } from 'react';
import { GoogleMap, DrawingManagerF, useJsApiLoader } from '@react-google-maps/api';

const libraries = ['places','drawing'];

const containerStyle = {
  width: '100%',
  height: '80vh'
};

const defaultCenter = {
  lat: 40.7128, // Default latitude (New York)
  lng: -74.0060 // Default longitude (New York)
};

const satelliteMapStyle = [
  {
    "featureType": "all",
    "elementType": "all",
    "stylers": [
      {
        "saturation": -100
      },
      {
        "lightness": 30
      }
    ]
  },
  {
    "featureType": "landscape",
    "elementType": "labels",
    "stylers": [
      {
        "lightness": 50
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "on" // Show parks
      }
    ]
  },
  {
    "featureType": "poi.business",
    "elementType": "labels",
    "stylers": [
      {
        "visibility": "on" // Hide business-related POIs (shops, restaurants, etc.)
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "all",
    "stylers": [
      {
        "visibility": "on"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "all",
    "stylers": [
      {
        "color": "#00b0ff"
      }
    ]
  }
];

const PolygonMap = ({ latitude, longitude, setPolygonCoords }) => {
  const [center, setCenter] = useState(defaultCenter);
  const [zoom, setZoom] = useState(10)
  const currentPolygon = useRef(null); 

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY_FOR_ADDRESS,
    libraries 
  });

  const onPolygonComplete = useCallback((polygon) => {
    if (currentPolygon.current) {
      currentPolygon.current.setMap(null);
    }

    polygon.setEditable(true);
    polygon.setDraggable(true);

    const path = polygon.getPath();
    const coordinates = [];

    for (let i = 0; i < path.getLength(); i++) {
      const latLng = path.getAt(i);
      coordinates.push([latLng.lat(), latLng.lng()]);
    }

    setPolygonCoords(coordinates);
 
    currentPolygon.current = polygon;

    window.google.maps.event.addListener(polygon.getPath(), 'set_at', function () {
      const updatedCoords = [];
      for (let i = 0; i < path.getLength(); i++) {
        const latLng = path.getAt(i);
        updatedCoords.push([latLng.lat(), latLng.lng()]);
      }
      setPolygonCoords(updatedCoords);
    });

    window.google.maps.event.addListener(polygon.getPath(), 'insert_at', function () {
      const updatedCoords = [];
      for (let i = 0; i < path.getLength(); i++) {
        const latLng = path.getAt(i);
        updatedCoords.push([latLng.lat(), latLng.lng()]);
      }
      setPolygonCoords(updatedCoords);
    });
  }, []);

  const zoomToLatLng = useCallback((lat, lng) => {
    setCenter({ lat, lng });
    setZoom(1000)
  }, []);

  useEffect(() => {
    if (latitude && longitude) {
      zoomToLatLng(latitude, longitude);
    }
  }, [latitude, longitude, zoomToLatLng]);
  

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <GoogleMap
      mapContainerStyle={containerStyle }
      center={center}
      zoom={zoom}
      options={{
        styles: satelliteMapStyle, 
        mapTypeId: window.google.maps.MapTypeId.HYBRID, 
        tilt: 0,
        disableDefaultUI: true, 
        zoomControl: true, 
        streetViewControl: false,
        rotateControl: false,
      }}
    >
      <DrawingManagerF
        onPolygonComplete={onPolygonComplete}
        options={{
          drawingControl: true, 
          drawingControlOptions: {
            position: window.google.maps.ControlPosition.TOP_CENTER,
            drawingModes: ['polygon'] 
          },
          polygonOptions: {
            fillColor: '#2196F3',
            fillOpacity: 0.4,
            strokeWeight: 2,
            clickable: true,
            editable: true, 
            draggable: true 
          }
        }}
      />
    </GoogleMap>
  )
};

export default memo(PolygonMap);
