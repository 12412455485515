import React, { useEffect, useReducer, useRef, useState } from "react";
import { Link } from "react-router-dom";
import searchicon from "../../images/search.svg";
import HeaderContainer from "../../components/common/Header/container";
import Header from "../../components/common/Header/container";
import View from "../../images/IC-view.svg";
import DarkView from "../../images/DarkViewImg.svg";
import WhiteView from "../../images/WhiteViewImg.svg";
import { setAlert, setLoading } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { capitalize, debounce } from "../../global";
import { Pagination } from "antd";
import Refund_modal from "../../utils/refund_modal";
import { async } from "rxjs";
import { DataGrid } from "@mui/x-data-grid";
import { REQUEST_TYPES } from "../../constants"
import "./styles.scss";

export default function OperatorCitationsComponent(props) {

  const dispatch = useDispatch()
  const [data, setdata] = useState();
  const [permissions, setPermissions] = useState({});
  const [roleTitle, setRoleTitle] = useState("");
  const tz = moment.tz?.guess();
  const [lots, setLots] = useState([]);
  const [selectedLot, setSelectedLot] = useState("All")
  const [selectedStatus, setSelectedStatus] = useState("All")
  const [selectedCitation_type, setSelectedCitation_type] = useState("All")
  const [selectedDate_type, setSelectedDate_type] = useState("createdAt")

  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10,
    citation_type: null
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      Operator_citations(next?.currentPage, {lotId: selectedLot, status: selectedStatus, citation_type: selectedCitation_type, date_type: selectedDate_type})
    }
    return { ...prev, ...next }
  }, initialPageState)


  const [searchKey, setSearchKey] = useState('');
  const searchInputRef = useRef()

  useEffect(() => {
    Operator_citations(1, {lotId: selectedLot, status: selectedStatus, citation_type: selectedCitation_type, date_type: selectedDate_type});
  }, [selectedLot, selectedStatus, selectedCitation_type, selectedDate_type]);

  useEffect(() => {
    getUserPermissions();
  }, []);

   async function Operator_citations(page, filters) {
    dispatch(setLoading(true))
    try {
      const { value } = await props.operatoCitation({ page, size: 10,
        lotId: filters?.lotId ?? selectedLot,
        status: filters?.status ?? selectedStatus,
        citation_type: filters?.citation_type ?? selectedCitation_type,
        date_type: filters?.date_type ?? selectedDate_type,
       })
      if (value?.success) {
        setdata(value)
        setPageState({ total: value?.total })
      } else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setLoading(false))
      dispatch(setAlert("error", error?.message))
    }
    dispatch(setLoading(false))
  }

  const getLotList = async (id, role) => {
    const {value} = await props.getAllLotsList({id, role});
    console.log(value, "<<--lots")
    if (value?.lots) {
      setLots(value?.lots);
    }
  };

  const getUserPermissions = async () => {
    const { value } = await props.user();
    if (value?.success) {
      setRoleTitle(value?.user?.role);
      setPermissions(value?.user?.roleId?.permissions);
      getLotList(value?.user?._id, value?.user?.role);
    }
    setLoading(false);
  };

  async function search_operator_citation(query) {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchForOperator('citation_operator', query)
        if (value?.success) {
          setdata({ citation: value?.results })
          setPageState({ total: 0 })
        }
        else {
          dispatch(setAlert('error', value?.message))
        }
      }
    } catch (error) {
      dispatch(setAlert('error', error?.message))
    }
  }

  const [refund_role, setrefund_role] = useState("operator");
  const [taggrFee, setTaggrFee] = useState(false);
  const [reason, setReason] = useState('')

  const on_void_citation_modal_submit = async (payment_source_id) => {
    dispatch(setLoading(true))
    let err
    if (!reason || reason?.trim()?.length <= 0) {
      err = true
      dispatch(setAlert('error', "Reason can't be empty!"))
    }
    if (!err) {
      setPageState({ show_void_modal: false })
console.log(pageState, "<<==citation type")
      try {
        const { value } = pageState?.citation_type === "mail_in"
         ? await props.refund_request({
            citationId: pageState?.void_citation_id,
            reason,
            requestType: REQUEST_TYPES.VOID_REQUEST,
            requestedAmount: pageState?.amount,
            withholdTaggrFee: taggrFee
          })
          : await props.void_citation(pageState?.void_citation_id, reason, taggrFee, payment_source_id);
        if (value?.success) {
          Operator_citations(pageState?.currentPage, {lotId: selectedLot, status: selectedStatus, citation_type: selectedCitation_type, date_type: selectedDate_type});
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message))
      }
      setReason('')
      setTaggrFee(false)
    }
    dispatch(setLoading(false))
  };


  const onRefundRequestModalSubmit = async () => {

    let err
    if (!reason || reason?.trim()?.length <= 0) {
      err = true
      dispatch(setAlert('error', "Reason can't be empty!"))
    }
    if(pageState?.amount < 1 ){
      err = true;
      dispatch(setAlert("error", "Invalid amount entered!"));
    }
     // Amount must be greater than the taggr's amount
     if (parseFloat(pageState?.amount) > parseFloat(pageState?.maximumRefundAmount) && pageState?.amount !== pageState?.fullRefundAmount) {
      err = true;
      dispatch(setAlert("error", "Invalid amount entered!"));
    }

    if (!err) {
      setPageState({ showModal: false })

      try {
        const { value } = await props.refund_request({
          citationId: pageState?.refund_citation_id,
          reason,
          requestedAmount: pageState?.amount,
          requestType: REQUEST_TYPES.REFUND_REQUEST,
          withholdTaggrFee: taggrFee
        });
        if (value?.success) {
          Operator_citations(pageState?.currentPage, {lotId: selectedLot, status: selectedStatus, citation_type: selectedCitation_type, date_type: selectedDate_type});
          dispatch(setAlert("success", value?.message))
        } else {
          dispatch(setAlert("error", value?.message))
        }
      } catch (error) {
        dispatch(setAlert("error", error?.message))
      }
      setReason('')
    }
  };

  const status_classes ={
    paid: "paid-green",
    pending: "red",
    voided: "voided-red"
  }

  const columns =[
    { 
      field: "tiretag_citation_data",
      headerName: "Notice Number",
      width: 200,
      hideable: false,
      editable: false,
      valueFormatter: (val, row) => `${row?.citation_type === "mail_in"
      ? row?.citation_mail_in_number?.replace("MAIL_IN_", " ") :  (row?.qr_code ?? val?.citation_number ?? '')}`,
    },
    {
      field: "lotdata",
      headerName: "Lot",
      width: 150,
      valueFormatter: (val) => (val?.address),
    },
    {
      field: "tireTagSerialNumber",
      headerName: "Tag Serial Number",
      width: 150,
    },
    {
      field: "vehicle",
      headerName: "Vehicle Plate",
      width: 150,
      valueFormatter: (val) => (val?.licensePlate)
    },
    { field: "citation_type", headerName: "Tag Type", width: 150},
    { 
      field: "internalAccountingId",
      headerName: "Internal Accounting Id",
      width: 150,
      valueGetter: (val, row) => row?.lotdata?.internalAccountingId
    },
    {
      field: "payment_status",
      headerName: "Status",
      width: 180,
      renderCell: (row) => 
      { var val = row?.row;
      return  <td align="center">
                          <span className={status_classes[val?.payment_status] || 'red'}>
                          {capitalize(val?.refund?.length > 0 && val?.refund[0]?.refund_type === "partial" ? "Partial Refund" : val?.payment_status)}
                          </span>
                        </td>
      },
      // valueFormatter: (val) => capitalize(val)
    },
    {
      field: "payment_date",
      headerName: "Payment Date",
      width: 150,
      valueFormatter: (val) => capitalize(val)
    },
    {
      field: "taggr break_down_amount",
      headerName: "Taggr amount",
      width: 150,
      valueFormatter: (val, row) =>  `${((row?.break_down_amount?.taggr?.amount ?? 0))}`

    },
    {
      field: "operator break_down_amount",
      headerName: "operator amount",
      width: 150,
      valueFormatter: (val, row) =>  `${((row?.break_down_amount?.operator?.amount ?? 0))}`

    },
    {
      field: "TireTag amount break_down_amount",
      headerName: "TireTag amount",
      width: 150,
      valueFormatter: (val, row) =>  `${((row?.break_down_amount?.tire_tag?.amount ?? 0))}`

    },
    {
      field: "Taggr Solutions Amount break_down_amount",
      headerName: "Taggr Solutions Amount",
      width: 150,
      valueFormatter: (val, row) =>  `${((row?.break_down_amount?.taggr_solutions?.amount ?? 0))}`

    },
    {
        field: "break_down_amount",
        headerName: "Amount",
        width: 150,
        valueFormatter: (val, row) =>  {
          return row?.citation_type === "enforcement_lite" ? row?.amount : `${((val?.calculative_amount ?? 0) + (val?.stripe_fees_offence ?? 0) + (val?.dock_fees ?? 0)).toFixed(2)}`
        }

    },
    { field: "", headerName: "Issued", width: 150, valueFormatter: (val, row) => {
        var date = row?.tiretag_citation_data?.created_at
        var mail_in_citation_date = row?.createdAt
        return moment(date ? date :mail_in_citation_date)?.tz(tz).format('YYYY-MM-DD HH:mm:ss')}
     },
     { field: "b", headerName: "Actions", width: 400, height: 400, renderCell: (row) => 
      {
        var val = row?.row;
        return (
          <div className="d-flex align-items-center">
            <Link
              to={`/citations/detail/${val?._id}`}
              className="eyeBtn DarkBlackBtn"
            >
              <img src={DarkView} className="DarkImg" />
              <img src={WhiteView} className="WhiteImg" />
            </Link>

            {(permissions?.TagsDeployed_edit || roleTitle === "operator") &&
              (val?.payment_status === "paid" ||
              val?.payment_status === "voided" ? (
                <button
                  disabled={
                    val?.payment_status === "paid" ||
                    val?.payment_status === "voided"
                  }
                  className="DarkEditBtnDisbled"
                >
                  Edit
                </button>
              ) : (
                <Link
                  to={`/Edit_citationsInformation/${val?._id}`}
                  className="DarkBlackBtn"
                >
                  Edit
                </Link>
              ))}

            {val?.citation_type !== "mail_in" ? (
              <button
                className="DarkEditBtnDisbled DarkBlackBtn"
                onClick={() => {
                  if (val?.payment_status === "voided") {
                    dispatch(setAlert("info", "Notice already voided."));
                  } else if (
                    val?.payment_status !== "paid" &&
                    val?.payment_status !== "refunded"
                  ) {
                    setPageState({
                      void_citation_id: val?._id,
                      show_void_modal: true,
                      citation_type: val?.citation_type,
                      void_popup_title:
                        val?.citation_type === "tag_vehicle"
                          ? val?.tiretag_citation_data?.citation_number
                          : val?.citation_mail_in_number?.replace(
                              "MAIL_IN_",
                              " "
                            ),
                      amount: (
                        val?.break_down_amount?.calculative_amount +
                        val?.break_down_amount?.stripe_fees_offence + 
                        (val?.break_down_amount?.dock_fees ?? 0)
                      )?.toFixed(2),
                    });
                  } else {
                    dispatch(
                      setAlert(
                        "info",
                        "You can't void a Notice after it's paid!"
                      )
                    );
                  }
                }}
                disabled={val?.payment_status !== "pending"}
              >
                {val?.payment_status === "voided" ? "Voided" : "Void"}
              </button>
            ) : (
              <button
                className="RefundBtnDisbled"
                onClick={() => {
                  setPageState({
                    void_citation_id: val?._id,
                    show_void_modal: true,
                    citation_type: val?.citation_type,
                    void_popup_title:
                      val?.citation_type === "tag_vehicle"
                        ? val?.tiretag_citation_data?.citation_number
                        : val?.citation_mail_in_number?.replace(
                            "MAIL_IN_",
                            " "
                          ),
                    amount: (
                      val?.break_down_amount?.calculative_amount +
                      val?.break_down_amount?.stripe_fees_offence + 
                      (val?.break_down_amount?.dock_fees ?? 0)
                    )?.toFixed(2),
                  });
                }}
                disabled={
                  val?.payment_status !== "pending" ||
                  val?.refund_request?.requestType ===
                    REQUEST_TYPES.VOID_REQUEST
                }
              >
                {val?.refund_request?.requestType === REQUEST_TYPES.VOID_REQUEST
                  ? "Request Submitted"
                  : "Request Void"}
              </button>
            )}

            {(permissions?.TagsDeployed_refundRequest ||
              roleTitle === "operator") &&
            (val?.payment_status === "refunded" ||
            val?.refund_request?.requestType === REQUEST_TYPES.REFUND_REQUEST ||
            val?.payment_status !== "paid" ? (
              <button
                className="RefundBtnDisbled"
                disabled={
                  val?.payment_status === "refunded" ||
                  val?.refund_request?.requestType ===
                    REQUEST_TYPES.REFUND_REQUEST ||
                  val?.payment_status !== "paid"
                }
              >
                {val?.refund_request?.requestType ===
                REQUEST_TYPES.REFUND_REQUEST
                  ? "Request Submitted"
                  : "Request Refund"}
              </button>
            ) : (
              <button
                className="DarkBlackBtn"
                onClick={() =>
                  setPageState({
                    refund_citation_id: val?._id,
                    showModal: true,
                    refund_popup_title:
                      val?.tiretag_citation_data?.citation_number,
                    amount: (
                      val?.break_down_amount?.calculative_amount +
                      val?.break_down_amount?.stripe_fees_offence + 
                      (val?.break_down_amount?.dock_fees ?? 0)
                    )?.toFixed(2),
                    maximumRefundAmount: val?.break_down_amount?.calculative_amount > 55 ? (val?.break_down_amount?.calculative_amount - 55)?.toFixed(2) : 0,
                    fullRefundAmount: (
                      val?.break_down_amount?.calculative_amount +
                      val?.break_down_amount?.stripe_fees_offence + 
                      (val?.break_down_amount?.dock_fees ?? 0)
                    )?.toFixed(2)
                  })
                }
              >
                Request Refund
              </button>
            ))}
          </div>
        );
        }
      },
    
  ];



  return (
    <>
      <section className="adminCitations">
        <Header />
        <div className="citationsSection paddzero" id="profile-wrapper">
          <div className="title-wrapper">
            <div className="d-flex align-items-center">
              {/* <img src={leftArrow} className="left-arrow " onClick={() => navigate(-1)} /> */}
              <h4> Tags Deployed</h4>
            </div>
            <div className="search-input">
              <button>
                <img src={searchicon} />
              </button>
              {/* <input type="search" placeholder="Search..." /> */}
              <input
                type="search"
                ref={searchInputRef}
                placeholder="Search..."
                onChange={async (e) => {
                  setSearchKey(e?.target?.value)
                  e?.target?.value?.length > 2 && debounce(await search_operator_citation(e?.target?.value), 2000)
                  e?.target?.value?.length === 0 && Operator_citations(1);
                }}
              />

            </div>
          </div>
          <div className="cirtation-wrapper">
            <div className="table-responsive">
  <div className="selectors-container">
    <div className="grid">
    <h7 style={{fontWeight: "bold", marginLeft: '8px', marginBottom: '5px'}}>Select Lot</h7>
    <select
      className="minimal selectors"
      value={selectedLot}
      onChange={(e) => {
        setSelectedLot(e.target?.value);
        Operator_citations(1, {lotId: e.target?.value});
      }}
    >
      <option value="All">All Lots</option>
      {lots?.map((lot, idx) => (
        <option key={idx} value={lot?._id}>
          {lot?.address}
        </option>
      ))}
    </select>
  </div>
  <div className="grid">
  {/* ####################################### */}
              <h7 style={{fontWeight: "bold", marginLeft: '2px', marginBottom: '5px'}}>Select Notice Status</h7>
              <select
                className="minimal selectors"
                value={selectedStatus}
                onChange={(e) => {
                  setSelectedStatus(e.target?.value);
                  Operator_citations(1, { status: e.target?.value});
                }}
              >
                <option value="All">All Statuses</option>
                {['paid', 'pending', 'refunded', 'voided']?.map((status, idx) => (
                  <option key={idx} value={status}>
                    {status}
                  </option>
                ))}
                </select>
  {/* ######################################### */}
  </div>
  <div className="grid">
              <h7 style={{fontWeight: "bold", marginLeft: '8px', marginBottom: '5px'}} >Select Tag type</h7>
              <select
                className="minimal selectors"
                value={selectedCitation_type}
                onChange={(e) => {
                  setSelectedCitation_type(e.target?.value);
                  Operator_citations(1, { citation_type: e.target?.value});
                }}
              >
                <option value="All">All Tags</option>
                {['tag_vehicle', 'mail_in']?.map((status, idx) => (
                  <option key={idx} value={status}>
                    {status}
                  </option>
                ))}
                </select>
  {/* ######################################### */}
  </div>
  <div className="grid">
              <h7 style={{fontWeight: "bold", marginLeft: '8px', marginBottom: '5px'}}>List by</h7>
              <select
                className="minimal selectors"
                value={selectedDate_type}
                onChange={(e) => {
                  setSelectedDate_type(e.target?.value);
                  Operator_citations(1, { date_type: e.target?.value});
                }}
              >
                <option value="All">All Dates</option>
                {[{name: 'Created At', value: 'createdAt'}, {name: 'Payment Date', value: 'payment_date'}]?.map((status, idx) => (
                  <option key={idx} value={status?.value}>
                    {status?.name}
                  </option>
                ))}
                </select>
  {/* ######################################### */}
  </div>
  </div>
            {data?.citation?.length > 0 && <DataGrid
              rows={data?.citation?.map((citation, index) => (citation)
              )}
              columns={columns}
              pageSize={pageState?.pageSize ?? 10}
              rowsPerPageOptions={[10]}
              // hideFooterPagination
              // hideFooterSelectedRowCount
              hideFooter
              pagination
              onPageChange={(params) => setPageState({ currentPage: params.page })}
              rowCount={pageState.total}
              
              paginationMode="server"
              loading={props.loading}
              getRowId={(row) => row._id}
            />}
              <table className="StripTable">
                <thead>
                  <tr>
                    {/* <th>Notice Number</th>
                    <th>Lot</th>
                    <th>Tag Serial Number</th>
                    <th>Vehicle Plate</th>
                    <th>Status</th>
                    <th>Issued</th>
                    <th>Actions</th> */}
                  </tr>
                </thead>
                <tbody>
                  {data?.citation?.length > 0 ? ( ""
                    // data?.citation?.map((val) => {
                    //   const date = val?.tiretag_citation_data?.created_at
                    //   const mail_in_citation_date = val?.createdAt
                    //   const formattedDateTime = moment(date ? date : mail_in_citation_date)?.tz(tz).format('YYYY-MM-DD HH:mm:ss');
                    //   return (
                    //     <tr>
                    //       {/* <td>{val?.tiretag_citation_data?.citation_number}</td> */}
                    //       <td align="center">{val?.citation_type === "mail_in" ? val?.citation_mail_in_number : val?.tiretag_citation_data?.citation_number}</td>
                    //       <td align="center ">{val?.lotdata?.address}</td>
                    //       <td align="center ">
                    //         {val?.tireTagSerialNumber}
                    //       </td>
                    //       <td align="center">{val?.vehicle?.licensePlate}</td>
                    //       <td align="center">
                    //         <span className={val?.payment_status === "paid" ? "green" : "red"}>
                    //           {capitalize(val?.payment_status)}
                    //         </span>
                    //       </td>
                    //       <td align="center">{formattedDateTime}</td>
                    //       {/* <td>
                    //         <Link to={`/citations/detail/${val?._id}`} className="eyeBtn">
                    //           <img src={DarkView} className="DarkImg" />
                    //           <img src={WhiteView} className="WhiteImg" />
                    //         </Link>
                    //       </td> */}
                    //         <td>
                            //  <div className="d-flex align-items-center">
                            // <Link to={`/citations/detail/${val?._id}`} className="eyeBtn">
                            //   <img src={DarkView} className="DarkImg" />
                            //   <img src={WhiteView} className="WhiteImg" />
                            // </Link>
                            // {val?.payment_status === "paid" ||
                            //     val?.payment_status === "voided" ? (
                            //       <button
                            //         disabled={
                            //           val?.payment_status === "paid" ||
                            //           val?.payment_status === "voided"
                            //         }
                            //         className="DarkEditBtnDisbled"
                            //       >
                            //         Edit
                            //       </button>
                            //     ) : (
                            //       <Link
                            //         to={`/Edit_citationsInformation/${val?._id}`}
                            //         className="DarkBlackBtn"
                            //       >
                            //         Edit
                            //       </Link>
                            //     )}
                            // <button
                            //   className="DarkEditBtnDisbled DarkBlackBtn"
                            //   onClick={() => {
                            //     if (val?.payment_status === "voided") {
                            //       dispatch(
                            //         setAlert(
                            //           "info",
                            //           "Notice already voided."
                            //         )
                            //       );
                            //     } else if (
                            //       val?.payment_status !== "paid" &&
                            //       val?.payment_status !== "refunded"
                            //     ) {
                            //       setPageState({
                            //         void_citation_id: val?._id,
                            //         show_void_modal: true,
                            //         citation_type: val?.citation_type,
                            //         void_popup_title:
                            //           val?.tiretag_citation_data
                            //             ?.citation_number,
                            //         amount: (
                            //           val?.break_down_amount
                            //             ?.calculative_amount +
                            //           val?.break_down_amount
                            //             ?.stripe_fees_offence
                            //         )?.toFixed(2),
                            //       });
                            //     } else {
                            //       dispatch(
                            //         setAlert(
                            //           "info",
                            //           "You can't void a Notice after it's paid!"
                            //         )
                            //       );
                            //     }
                            //   }}
                            //   disabled={(val?.payment_status !== "pending")}
                            // >
                            //   {val?.payment_status === "voided"
                            //     ? "Voided"
                            //     : "Void"}
                            // </button>
                              
                            //  {  val?.payment_status === "refunded" ?

                            //   <button className="RefundBtnDisbled"
                            //   disabled={                                
                            //     val?.payment_status === "refunded"
                            //   }
                            //   >                          
                            //         Refund
                            //       </button>
                            //       : 
                            //       <button className="DarkBlackBtn" 
                            //  onClick={() => setPageState({
                            //             refund_citation_id: val?._id,
                            //             showModal: true,
                            //             refund_popup_title:val?.tiretag_citation_data?.citation_number,
                            //             amount : val?.amount
                            //           })}
                            //           >
                            //         Refund
                            //       </button>
                                  
                            //     }
                            //       </div>
                    //       </td>
                    //     </tr>
                    //   );
                    // })
                  ) : data?.citation === null ||
                    data?.citation?.length === 0 ? (
                    searchKey && (
                      <div className="dataText">
                        <h5>No Result Found</h5>
                      </div>
                    )
                  ) : (
                    <div className="dataText">
                      <h5></h5>
                    </div>
                  )
                  }
                </tbody>
              </table>
              <div className="pagination-block">
              <Pagination
                hideOnSinglePage
                defaultCurrent={pageState?.defaultCurrentPage}
                // onChange={(page) => setPageState({ currentPage: page })}
                onChange={(page) => {
                  console.log("Current Page:", page);
                  setPageState({ currentPage: page });
                }}
                current={pageState?.currentPage}
                pageSize={pageState?.pageSize}
                total={pageState?.total}
                showSizeChanger={false}
              />
            </div>
          </div>
          </div>
        </div>
      </section>
      <Refund_modal
        show={pageState?.showModal}
        onClose={() => setPageState({ showModal: false })}
        role={"operator"}
        setReason={(e) => setReason(e?.target?.value)}
        amount={pageState?.amount}
        maximumRefundAmount={pageState?.maximumRefundAmount}
        fullRefundAmount={pageState?.fullRefundAmount}
        setAmount={(amount) => setPageState({amount})}
        reason={reason}
        onSubmit={onRefundRequestModalSubmit}
        title={pageState?.refund_popup_title}
        header_title="Request Refund"
      />
      <Refund_modal
        show={pageState?.show_void_modal}
        onClose={() => {
          setPageState({ show_void_modal: false })
          setReason('')
          setTaggrFee(false)
        }}
        setReason={(e) => setReason(e?.target?.value)}
        onSubmit={on_void_citation_modal_submit}
        amount={pageState?.amount}
        reason={reason}
        title={pageState?.void_popup_title}
        header_title="Void"
        citation_type={pageState?.citation_type}
        setTaggrFee={setTaggrFee}
        taggrFee={taggrFee}
      />
    </>
  );
}
