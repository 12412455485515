import React, { useRef, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Pagination } from "antd";
import searchicon from "../../../images/search.svg";
import leftArrow from "../../../images/left-arrow.svg";
import Header from "../Citations-Header/container";
import { setAlert, setLoading } from "../../../store/actioncreator";

export default function TireTags(props) {
  const [tireTags, setTireTags] = useState([]);
  const [filteredTireTags, setFilteredTireTags] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [role, setRole] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    fetchTireTags();
  }, []);

  const getUser = async () => {
    // dispatch(setLoading(true))
    try {
      const { value } = await props.user();
      setRole(value?.user?.role);
    } catch (error) {
      dispatch(setAlert('error', error?.message));
    }
    // dispatch(setLoading(false))
  };

  const searchInputRef = useRef();

  async function fetchTireTags() {
    dispatch(setLoading(true));
    try {
      const response = await props.getAllTireTags();
      const { status } = response?.value;
      getUser();
      if (status && status.length >= 1) {
        setTireTags(status);
        setFilteredTireTags(status); // Initialize filteredTireTags with all data
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  }

  const renderTireTags = (tags) => {
    return tags?.map(tireTag => (
      <tr key={tireTag.id}>
        <td>{tireTag.id}</td>
        <td align="center">{tireTag.serial_number}</td>
        <td align="center">{tireTag.activation_code}</td>
      </tr>
    ));
  }

  useEffect(() => {
    if (searchKey?.length < 1) {
      setFilteredTireTags(tireTags);
    } else {
      const filteredResult = tireTags?.filter(tireTag =>
        tireTag?.id?.toLocaleString()?.includes(searchKey) ||
        tireTag?.serial_number?.includes(searchKey) ||
        tireTag?.activation_code?.includes(searchKey)
      );
      setFilteredTireTags(filteredResult);
    }
    setCurrentPage(1); // Reset to first page on new search
  }, [searchKey, tireTags]);

  const handlePageChange = (page, pageSize) => {
    setCurrentPage(page);
    setPageSize(pageSize);
  };

  const paginatedTags = filteredTireTags.slice((currentPage - 1) * pageSize, currentPage * pageSize);

  return (
    <>
      <section className="adminCitations">
        <Header />
        <div className="citationsSection paddzero" id="profile-wrapper">
          <div className="title-wrapper">
            <div className="d-flex align-items-center">
              {role !== "callcenter" && (
                <img
                  src={leftArrow}
                  className="left-arrow"
                  onClick={() => navigate(-1)}
                  alt="left-arrow"
                />
              )}
              <h4>Tire Tags</h4>
            </div>
            <div className="search-input">
              <button>
                <img src={searchicon} alt="search-icon" />
              </button>
              <input
                type="search"
                placeholder="Search..."
                ref={searchInputRef}
                onChange={e => setSearchKey(e.target.value)}
              />
            </div>
          </div>
          <div className="cirtation-wrapper">
            <div className="table-responsive">
              <table className="table offence-table StripTable">
                <thead>
                  <tr>
                    <th style={{ textAlign: "center" }}>Tire Tag ID</th>
                    <th>Tag Serial Number</th>
                    <th>Tag Activation Code</th>
                  </tr>
                </thead>
                <tbody>
                  {renderTireTags(paginatedTags)}
                  {filteredTireTags?.length === 0 && (
                    <div className="dataText">
                      <h5>No Result Found.</h5>
                    </div>
                  )}
                </tbody>
              </table>
            </div>
            {
              filteredTireTags?.length > 0 &&
              <div className='pagination-block'>
                <Pagination
                  current={currentPage}
                  pageSize={pageSize}
                  total={filteredTireTags?.length}
                  onChange={handlePageChange}
                />
              </div>
            }
          </div>
        </div>
      </section>
    </>
  );
}
