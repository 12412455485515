import { connect } from "react-redux";
import { lotprovider,user, deleteyourlot, addlots, getProviders, searchForOperator } from "../../store/home/duck";
import ReportComponent from "./report_component";

const ReportContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    lotprovider,
    user,
    deleteyourlot,
    addlots,
    getProviders,
    searchForOperator
  }
)(ReportComponent);

export default ReportContainer;
