import { connect } from "react-redux";
import { getEmailByBuffer, createPasswordForOperator } from "../../store/home/duck";

import CreatePasswordComponent from "./component";

const CreatePasswordContainer = connect(
  // Map state to props
  (state) => ({
  }),
  // Map actions to dispatch and props
  {
   
    getEmailByBuffer,
    createPasswordForOperator
  }
)(CreatePasswordComponent);

export default CreatePasswordContainer;
