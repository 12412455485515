import React, { useState } from "react";
import brandLogo from "../../images/brand-logo-black.svg"
import ImgLogin from "../../images/login-img.png";
import icEye from "../../images/ic-eye.svg"
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"

export default function SignupComponent(props) {
  const [signupapi, setsignupapi] = useState('')
  const [email, setEmail] = useState('')
  const [agree, setAgree] = useState(false);
  const [password, setPassword] = useState('')
  const [username, setUsername] = useState('')
  const [showpassw, setShowPassw] = useState(false)
  const [errorus, setErrorUs] = useState()
  const [error, setError] = useState({})

  // const onSignup = async (e) => {
  //   e.preventDefault()
  //   const { value } = await props.createoprtrsignup({ fullname: username, email: email, password: password, role: "operator" })
  //   if (value?.success) {
  //     setsignupapi(value)
  //   }
  // }

  const onSignup = async (e) => {
    e.preventDefault();
    if (!agree) {
      alert("Please agree to the terms and conditions to sign up.");
      return;
    }
    const { value } = await props.createoprtrsignup({ fullname: username, email: email, password: password, role: "operator" });
    if (value?.success) {
      setsignupapi(value);
    }
  };
  

  const checkboxHandler = () => {
    setAgree(!agree);
  };

  function onLogin(e) {
    e.preventDefault()
    const err = {}
    if (!username || username.length < 3) {
      err.username = 'Name should have atleast 3 letters'
    }
    if (!email || !/^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/.test(email)) {
      err.email = 'Please enter valid email'
    }
    // if (!password || !/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(password)) {
    //   err.password = 'Please enter valid password'
    // }
    setError(err)
    if (!Object.keys(err).length) {
      const data = {
        email,
        password,
      }
    }
  }
  const visiblePassw = (e) => {
    e.preventDefault()
    setShowPassw(!showpassw)
  };

  return (
    <>
      <div>
        <section className="MainLoginWrapper">
          <div className="FlexDiv">
            <div className="LeftSection">
              <div className="FullwidthClass">
                <div>
                  <img src={brandLogo} className="BrandLogo" alt="taggr logo" />
                  <h3>Enforcement Simplified </h3>
                </div>

                <div className="FlexCenter">
                  <div className="SectionDetails">
                    <h2>Create Account</h2>
                    <p>Sign up to continue to Taggr</p>
                    <div className="FormBg">
                      <form >
                        <div className="mb-3 mt-3">
                          <label htmlFor="email" className="form-label">
                            User name
                          </label>
                          <input
                            type="text"
                            className="form-control InputStyle"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                          {"username" in error &&
                            <div className='invalid-feedback'>{error.username}</div>
                          }
                        </div>

                        <div className="mb-3 mt-3">
                          <label htmlFor="email" className="form-label">
                            Email address
                          </label>
                          <input
                            type="email"
                            className="form-control InputStyle"
                            name="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                          {"email" in error &&
                            <div className='invalid-feedback'>{error.email}</div>
                          }
                        </div>

                        <div className="mb-3">
                          <label for="pwd" className="form-label">
                            Password
                          </label>
                          <div className="flex-password">
                            <input
                                 type={!showpassw ? "password" : "text"}
                              className="form-control PasswordStyle"
                              value={password}
                              autocomplete="off"
                              onChange={(e) => setPassword(e.target.value)}
                            />
                            <button className="passwordbtn">
                              <img src={icEye}
                                onClick={(e) => visiblePassw(e)} alt="" />
                            </button>

                          </div>
                          {"password" in error &&
                            <div className='invalid-feedback'>{error.password}</div>
                          }
                        </div>
                        <div className="form-check text-center mb-4">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="remember"
                              onChange={checkboxHandler}
                            />
                            I agree to
                            <a href="#" target="_blank" className="Terms">
                              terms & conditions
                            </a>
                          </label>
                        </div>

                        <button type="submit" className="btn PrimaryDark mt-4"
                          onClick={(e) => { onLogin(e); onSignup(e) }}>
                          Sign Up
                        </button>
                        <div className="AccountLink">
                          Already account? <Link to="/login">Log In</Link>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="RightSection">
              <div className="ImageBox">
                <img src={ImgLogin} alt="" />
                <h3 className="Heading">The future of enforcement!!</h3>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}



























