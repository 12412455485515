import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import brandLogo from "../../images/brand-logo-black.svg";
import ImgLogin from "../../images/login-img.png";
import icEye from "../../images/ic-eye.svg";
import icEyec from "../../images/ic-eyec.svg"
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { setLoading } from "../../store/actioncreator"
export default function LoginComponent(props) {
  const dispatch = useDispatch()
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showpassw, setShowPassw] = useState(false);
  const [img, setImg] = useState(icEyec)
  const [error, setError] = useState();
  const [Error1, setError1] = useState()
  const [Error2, setError2] = useState()
  const [Error3, setError3] = useState()
  const [errorus, setErrorUs] = useState();
  const navigate = useNavigate();
  const [userexist, setUserexist] = useState();
  const [checkboxAlert, setcheckboxAlert] = useState(false);


  const handleLogin = async (e) => {
    e.preventDefault()
    dispatch(setLoading(true))
    const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let err = false

    if (email.length == 0) {
      err = true
      setError("Please enter email")
    } else {
      if (emailRegex.test(email)) {
      } else {
        err = true
        setError1("Enter valid email")
      }
    }

    if (password.length == 0) {
      err = true
      setError2("Please enter password")
    }
    if (!err) {
      const { value } = await props.logins({ email: email, password: password });
      if (value.success) {
        if (value.user?.role === "operator" || value?.user?.role === "manager") {
          setUserexist(value);
          navigate("/dashboard");
          localStorage.setItem('token', value?.token);
          // 6437a71d4fbf489ab438b517
        } else {
          setError3("Invalid user")
        }
      }
      else {
        setErrorUs(value?.message);
      }
      if (value?.success) {
        if (value?.user?.role === "admin" || value?.user?.role === "superAdmin") {
          navigate("/admin");
          localStorage.setItem('token', value?.token);
        } 
        if(value?.user?.role ==="callcenter"){
          navigate("/admincitations");
          localStorage.setItem('token', value?.token);
        }
        else {
          // console.log("InValid user");
        }
      }
      else {
        setErrorUs(value?.message);
      }
    };
    dispatch(setLoading(false))
  }


  const visiblePassw = (e) => {
    e.preventDefault()
    setShowPassw(!showpassw)
    if (img === icEye) {
      setImg(icEyec)
    } else {
      setImg(icEye)
    }
  };
  // const checkboxHandler = () => {
  //   setAgree(!agree);
  // };

  const handleNav = async (e) => {
    const { value } = await props.user();
    if (value?.success) {
      setUserexist(value);
    }
    navigate("/passwordrecovery");
  };
  return (
    <>
      <div>
        <section className="MainLoginWrapper">
          <div className="FlexDiv">
            <div className="LeftSection">
              <div className="FullwidthClass">
                <div>
                  <img src={brandLogo} className="BrandLogo" alt="taggr logo" />
                  <h3>Enforcement Simplified </h3>
                </div>

                <div className="FlexCenter">
                  <div className="SectionDetails">
                    <h2>Login</h2>
                    <p>Sign in to continue to Taggr operator account</p>
                    <div className="FormBg">
                      <form onSubmit={(e) => handleLogin(e)}>
                        <div className="mb-4 mt-3">
                          <label htmlFor="email" className="form-label">
                            Email Address
                          </label>
                          <input
                            type="text"
                            className="form-control PasswordStyle"
                            name="email"
                            value={email}
                            autocomplete="off"
                            onChange={(e) => {
                              setEmail(e.target.value.toLowerCase())
                              setError(false)
                              setError1(false)
                              setError2(false)
                              setErrorUs(false);

                            }}
                          />
                          {error && <div className="text-danger">{error}</div>}
                          {Error3 && <div className="text-danger">{Error3}</div>}
                          {Error1 && <div className="text-danger">{Error1}</div>}

                        </div>
                        <div className="mb-3">
                          <label for="pwd" className="form-label">
                            Password
                          </label>
                          <div className="flex-password">
                            <input
                              type={!showpassw ? "password" : "text"}
                              className="form-control PasswordStyle"
                              value={password}
                              autocomplete="off"
                              onChange={(e) => {
                                setPassword(e.target.value)
                                setError(false)
                                setError1(false)
                                setError2(false)
                                setErrorUs(false);
                              }}
                            />
                            <img
                              src={img}
                              onClick={(e) => visiblePassw(e)}
                              alt=""
                              className="passwordbtn"
                            />
                          </div>
                          {Error2 && <div className="text-danger">{Error2}</div>}
                        </div>

                        {errorus && (
                          <div className="invalid-feedback">{errorus}</div>
                        )}
                        <div className="text-end">
                          <Link
                            to="/forgot-password"
                            href="#"
                            className="ForgotPassword"
                            onClick={(e) => handleNav(e)}
                          >
                            Forgot Password?
                          </Link>
                        </div>


                        <button type="submit" className="btn PrimaryDark">
                          Login
                        </button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="RightSection">
              <div className="ImageBox">
                <img src={ImgLogin} alt="" />
                <h3 className="Heading">The future of enforcement!!</h3>
              </div>
            </div>
          </div>

          <Modal show={checkboxAlert} onHide={() => setcheckboxAlert(false)}>
            <Modal.Header closeButton>Verification Incomplete!!</Modal.Header>
            <Modal.Body>Please fill all the required feilds</Modal.Body>
            <Modal.Footer>
              <Button
                variant="secondary"
                onClick={() => setcheckboxAlert(false)}
              >
                OK
              </Button>
              <Button variant="primary" onClick={() => setcheckboxAlert()}>
                cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </section>
      </div>
    </>
  );
}

