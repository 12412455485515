import { connect } from "react-redux";
// import { logins ,user} from "../../../store/home/api";
import { logins ,user,get_single_citation_details, void_citation,reduce_citation,send_sms,deny_citation} from "../../../store/home/duck";
import CitationsInformationComponent from "./citations_information_component";

const CitationsInformationContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,user,get_single_citation_details,void_citation,reduce_citation,send_sms,deny_citation
  }
)(CitationsInformationComponent);

export default CitationsInformationContainer;
