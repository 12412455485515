import { connect } from "react-redux";
import { logins ,user, getuserdetails, editprofile , gettaggrdetails, current_payout} from "../../../store/home/duck.js";
import CurrentPayout from "./current-payouts.js";

const CurrentPayoutPage = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    getuserdetails,
    editprofile,
    gettaggrdetails,
    current_payout
  }
)(CurrentPayout);

export default CurrentPayoutPage;
