import React from 'react';
import "./loading.css"
import { useLottie } from "lottie-react";
import loader from "./loader.json"

function Loading() {
    const options = {
        animationData: loader,
        loop: true
    };
    const { View } = useLottie(options);
    return <>
        <div class="loadingContainer">
        <div className='loader'>
            {View}
        </div>
        </div>
    </>
}

export default Loading
