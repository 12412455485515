import { connect } from "react-redux";
import { user, get_all_refund_requests, refund_citation, void_citation, approved_refund_request, search_refund_request } from "../../../store/home/duck";
import RefundRequestComponent from "./RefundRequest";

const RefundRequestContainer = connect(
  (state) => ({}),
  {
    user,
    get_all_refund_requests,
    refund_citation,
    approved_refund_request,
    search_refund_request,
    void_citation
  }
)(RefundRequestComponent);

export default RefundRequestContainer;
