import { connect } from "react-redux";
import {
  lotprovider,
  user,
  addManager,
  getManagerById,
  updateManager,
  getRolesByOperator,
  token,
} from "../../store/home/duck";
import AddManagerComponent from "./AddManagerComponent";

const AddManagerContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    user,
    lotprovider,
    addManager,
    getManagerById,
    updateManager,
    getRolesByOperator,
    token
  }
)(AddManagerComponent);

export default AddManagerContainer;
