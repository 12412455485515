import React from 'react'

function ClearDates({onClick, className}) {
  return (
    <button 
    onClick={onClick} 
    className={className?.length > 0 ? 'btn DarkBtn ' + className : 'btn DarkBtn'}
    aria-label="Clear"
  >
   Clear
  </button>
  )
}

export default ClearDates
