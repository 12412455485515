import React from 'react'
import IcClose from "../../images/ic-close.svg";
import Delete from "../../images/delete-popup.svg";

const Modal = ({onClose, show, onSubmit, title}) =>{
  return (
    <div>
      <div className={`modalContainer ${show ? "show" : ""}`} onClick={onClose}>
        <div className="modalContent delete-content" onClick={(e) => e.stopPropagation()}>
          <div className="modalBody">
            <img src={Delete} className="delete-popup"/>
            <h3>Delete {title}</h3>
            <h6>Are you sure?</h6>
          </div>
          <div className="modalFooter">
            <button className="btn PrimaryBtn" onClick={onSubmit} >
              Yes
            </button>
            <button className="btn SecondaryBtn" onClick={onClose} >
              No
            </button>
          </div>
        </div>
      </div>
  
    </div>
  );
}

export default Modal