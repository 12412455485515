import React from 'react';
import PropTypes from 'prop-types';
import './detailActivity.css'; // Assuming you have a CSS file for styling
import moment from 'moment-timezone';

const DetailActivity = (props) => {
  const tz = moment.tz.guess();
  const formattedCheckIn = moment(props?.checkIn)?.tz(tz)?.format('MMMM D, YYYY [at] h:mm A');
  const formattedCheckOut = props?.isEndSession ? moment(props?.checkOut)?.tz(tz)?.format('MMMM D, YYYY [at] h:mm A') : "N/A"

  return (
    <div className="detail-activity">
      <div className="header">
        <h2>{props?.fullname} <span role="img" aria-label="detective">🕵️‍♂️</span></h2>
        <h3>{props?.lotData?.lotname} | {props?.lotData?.address}</h3>
      </div>
      <div className="time-info">
        <div className="time-card">
          <p className="time-label">Check-in Time</p>
          <p className="time-value">{formattedCheckIn}</p>
        </div>
        <div className="time-card">
          <p className="time-label">Check-out Time</p>
          <p className="time-value">{formattedCheckOut}</p>
        </div>
      </div>
      <div className="plates-section">
        <div className="plates">
          <h4>Searched License Plates <span role="img" aria-label="search">🔍</span></h4>
          <ul>
            {props?.licensePlates?.map((plate, index) => (
              <li key={index}>{plate?.licensePlate}</li>
            ))}
          </ul>
        </div>
        <div className="plates">
          <h4>Tagged License Plates <span role="img" aria-label="tag">🏷️</span></h4>
          <ul>
            {props?.taggedLicensePlates?.map((plate, index) => (
              <li key={index}>{plate}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

DetailActivity.propTypes = {
  fullname: PropTypes.string.isRequired,
  lotData: PropTypes.shape({
    lotName: PropTypes.string.isRequired,
  }).isRequired,
  checkIn: PropTypes.string.isRequired,
  checkOut: PropTypes.string.isRequired,
  isEndSession: PropTypes.bool.isRequired,
  licensePlates: PropTypes.arrayOf(
    PropTypes.shape({
      licensePlate: PropTypes.string.isRequired,
    })
  ).isRequired,
  taggedLicensePlates: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default DetailActivity;
