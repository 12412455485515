import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Header from "../Citations-Header/container";
import leftArrow from "../../../images/left-arrow.svg";
import payoutleftArrow from "../../../images/payout-left-arrow.svg";
import payoutrightArrow from "../../../images/payout-right-arrow.svg";
import DetailIcon from "../../../images/detailIcon.svg";
import { Link } from "react-router-dom";
import { setAlert, setLoading } from "../../../store/actioncreator";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Pagination } from 'antd';
import moment from "moment-timezone"
import DarkView from "../../../images/DarkViewImg.svg";
import WhiteView from "../../../images/WhiteViewImg.svg";
import { DataGrid } from "@mui/x-data-grid";
import { capitalize } from "@mui/material";
import { Two_decimals } from "../../../global";


export default function CurrentPayouts(props) {

    const handleViewDetails_taggr = (taggr, index) => {
        const taggrString = JSON.stringify(taggr);
        localStorage.setItem('selectedTaggr', taggrString);
        navigate(`/payoutdetails`);
    };

    const handleViewDetails_operator = (operator, index) => {
        const taggrString = JSON.stringify(operator);
        localStorage.setItem('selectedTaggr', taggrString);
        navigate(`/payoutdetails`);
    };

    const handleViewDetails_tire_tag = (tire_tag, index) => {
        const taggrString = JSON.stringify(tire_tag);
        localStorage.setItem('selectedTaggr', taggrString);
        navigate(`/payoutdetails`);
    };

    const handleViewDetails_taggr_solutions = (taggr_solutions, index) => {
        const taggrString = JSON.stringify(taggr_solutions);
        localStorage.setItem('selectedTaggr', taggrString);
        navigate(`/payoutdetails`);
    };

    const [isForwardDisable, setForwardDisable] = useState(true)
    const [isBackwardDisable, setBackwardDisable] = useState(false)
    const [role, setRole] = useState("vendor")
    const [data, setdata] = useState([])
    const navigate = useNavigate();
    const tz = moment.tz.guess()
    const dispatch = useDispatch();
    const currentDay_of_newyork = moment().tz("America/New_York").format('dddd');
    const currentHour = moment().tz("America/New_York").hour();

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const totalPages = Math.ceil(data?.users?.length / itemsPerPage);

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    const isWednesday = currentDay_of_newyork === 'Wednesday' && currentHour >= 6;
    const current_Wednesday = moment().tz("America/New_York").day(3).hour(6).minute(0).second(0);
    const currentDayy = moment().tz("America/New_York").day();
    const daysToSubtract = currentDayy === 3 ? 7 : currentDayy < 3 ? currentDayy + 4 : currentDayy - 3;
    const currentWednesday = moment().tz("America/New_York").subtract(daysToSubtract, 'days').hour(6).minute(0).second(0);
    const [startDate, setStartDate] = useState(isWednesday ? current_Wednesday.toDate() : currentWednesday.toDate());
    const [payoutDates, setPayoutDates] = useState([])
    const [endDate, setendDate] = useState(moment().tz("America/New_York").toDate());
    const status_classes ={
    paid: "paid-green",
    pending: "red",
    failed: "red",
    voided: "voided-red"
    }
    const handleBackward = () => {
      setForwardDisable(false)
      const currentDate = startDate ? moment(startDate)?.tz("America/New_York") : moment()?.tz("America/New_York").startOf('day').hour(6); // Set start date to today at 6 PM
      const currentDay = currentDate.day();
      const daysToSubtract = currentDay === 3 ? 7 : currentDay < 3 ? currentDay + 4 : currentDay - 3;
      const previousWednesday = moment(currentDate)?.tz("America/New_York").subtract(daysToSubtract, 'days').hour(6); // Set time to 6 PM
      const lastWednesday = moment()?.tz("America/New_York").day(-4).hour(6).minute(0); // Get the last Wednesday before the current date (time set to 6 PM)

      if (previousWednesday.isAfter(lastWednesday)) {
          setStartDate(lastWednesday.toDate());
          setendDate(moment(lastWednesday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Wednesday at 6 PM
      } else if (previousWednesday.isAfter(moment()?.tz("America/New_York"))) {
          setStartDate(moment()?.tz("America/New_York").toDate());
      } else {
          setStartDate(previousWednesday.toDate());
          setendDate(moment(previousWednesday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Wednesday at 6 PM
      }

      // Make API call with updated dates
      fetchvalue(role);
  };

  const handleForward = () => {
      let currentDate = endDate ? moment(endDate)?.tz("America/New_York") : moment()?.tz("America/New_York").startOf('day').hour(6).minute(0); // Set end date to today at 6 PM
      const currentDay = currentDate.day();
      const daysToAdd = currentDay === 3 ? 7 : currentDay < 3 ? 3 - currentDay : 10 - currentDay;
      const nextWednesday = moment(currentDate)?.tz("America/New_York").add(daysToAdd, 'days').hour(6).minute(0).second(0); // Set time to 6 PM
      const lastWednesday = moment()?.tz("America/New_York").day(-4).hour(6).minute(0).second(0); // Get the last Wednesday before the current date (time set to 6 PM)
      const actual_current_data = moment()?.tz("America/New_York")
    
      if (currentDate.isBefore(nextWednesday) && !actual_current_data?.isBefore(nextWednesday)) {
          console.log('work')
          setStartDate(currentDate?.toDate());
          setendDate(nextWednesday?.second(0).toDate()); // Set end date to the next Wednesday at 6 AM
      }
      else if (actual_current_data?.isBefore(nextWednesday) && currentHour < 6) {
          console.log('work2')
          if (currentDate.minute(0).second(0).millisecond(0).toDate() >= moment(actual_current_data).minute(0).second(0).millisecond(0)?.toDate()) {
              currentDate = lastWednesday.add(7, "days")
              console.log("if")
          }
          else {
              setStartDate(currentDate?.toDate());
              console.log("else")
              setForwardDisable(true)
          }
          setendDate(actual_current_data.toDate());
      }
      else if (actual_current_data?.isBefore(nextWednesday)) {
          console.log('work3')
          if (currentDate.minute(0).second(0).millisecond(0).toDate() >= actual_current_data.minute(0).second(0).millisecond(0)?.toDate()) {
              currentDate = lastWednesday.add(7, "days")
              console.log("if")
          }
          else {
              setStartDate(currentDate?.toDate());
              console.log("else")
              setForwardDisable(true)
          }
          setendDate(actual_current_data?.toDate());
      }
      // if (currentDate.isBefore(nextWednesday) && currentHour < 6) {
      //     console.log("000000");
      //     setStartDate(lastWednesday.toDate());
      //     setendDate(moment(lastWednesday)?.tz("America/New_York").add(7, 'days').hour(6).minute(0).second(0).toDate()); // Set end date to the next Wednesday at 6 AM
      // } else if (currentDate.isBefore(nextWednesday)) {
      //     setStartDate(currentDate.toDate());
      //     if (currentDate.isAfter(lastWednesday)) {
      //         console.log('work')
      //         setendDate(moment()?.tz("America/New_York").hour(6).minute(0).second(0).toDate())
      //     } else {
      //         console.log("111111");
      //         setendDate(moment()?.tz("America/New_York").toDate()); // Set end date to the next Wednesday at 6 AM
      //     }
      // } else {
      //     console.log("22222");
      //     setStartDate(nextWednesday.toDate());
      //     setendDate(moment(nextWednesday)?.tz("America/New_York").add(7, 'days').hour(6).minute(0).second(0).toDate()); // Set end date to the next Wednesday at 6 AM
      // }

      //   Make API call with updated dates
      fetchvalue(role);
  };

    // ********************************************************************************


    // // const isFriday = currentDay_of_newyork === 'Friday';
    // const isFriday = currentDay_of_newyork === 'Friday' && currentHour >= 6;
    // const current_Friday = moment().tz("America/New_York").day(5).hour(6).minute(0).second(0);
    // const currentDayy = moment().tz("America/New_York").day();
    // const daysToSubtract = currentDayy === 5 ? 7 : currentDayy < 5 ? currentDayy + 2 : currentDayy - 5;
    // const currentFriday = moment().tz("America/New_York").subtract(daysToSubtract, 'days').hour(6).minute(0).second(0);

    // // const [startDate, setStartDate] = useState(currentFriday.toDate());
    // const [startDate, setStartDate] = useState(isFriday ? current_Friday.toDate() : currentFriday.toDate());
    // const [endDate, setendDate] = useState(moment().tz("America/New_York").toDate());

    // const handleBackward = () => {
    //     const currentDate = startDate ? moment(startDate)?.tz("America/New_York") : moment()?.tz("America/New_York").startOf('day').hour(); // Set start date to today at 6 PM
    //     const currentDay = currentDate.day();
    //     const daysToSubtract = currentDay === 5 ? 7 : currentDay < 5 ? currentDay + 2 : currentDay - 5;
    //     const previousFriday = moment(currentDate)?.tz("America/New_York").subtract(daysToSubtract, 'days').hour(6); // Set time to 6 PM
    //     const lastFriday = moment()?.tz("America/New_York").day(-2).hour(6).minute(0); // Get the last Friday before the current date (time set to 6 PM)

    //     // Check if the previousFriday is greater than the lastFriday (cannot be later than the last Friday)
    //     if (previousFriday.isAfter(lastFriday)) {
    //         console.log("11112222", startDate, endDate);
    //         setStartDate(lastFriday.toDate());
    //         setendDate(moment(lastFriday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Friday at 6 PM
    //     } else if (previousFriday.isAfter(moment()?.tz("America/New_York"))) {
    //         console.log("111133333", startDate, endDate);
    //         // Set the start date to the current date if it's greater
    //         setStartDate(moment()?.tz("America/New_York").toDate());
    //     } else {
    //         console.log("11114444", startDate, endDate);
    //         setStartDate(previousFriday.toDate());
    //         setendDate(moment(previousFriday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Friday at 6 PM
    //     }

    //     // Make API call with updated dates
    //     fetchvalue(role);
    // };



    // const handleForward = () => {
    //     const currentDate = endDate ? moment(endDate)?.tz("America/New_York") : moment()?.tz("America/New_York").startOf('day').hour(6).minute(0); // Set end date to today at 6 PM
    //     const currentDay = currentDate.day();
    //     const daysToAdd = currentDay === 5 ? 7 : currentDay < 5 ? 5 - currentDay : 12 - currentDay;
    //     const nextFriday = moment(currentDate)?.tz("America/New_York").add(daysToAdd, 'days').hour(6).minute(0).second(0); // Set time to 6 PM
    //     const lastFriday = moment()?.tz("America/New_York").day(-2).hour(6).minute(0).second(0); // Get the last Friday before the current date (time set to 6 PM)

    //     // Check if the nextFriday is greater than the lastFriday (cannot be later than the last Friday)
    //     if (nextFriday.isAfter(lastFriday)) {
    //         setStartDate(isFriday ? current_Friday.toDate() : lastFriday.toDate());
    //         console.log("0000", startDate, endDate, ">>>>>", isFriday, ">>>>>>", current_Friday.toDate());
    //         if (moment()?.tz("America/New_York").toDate() - endDate) {
    //             setendDate(moment()?.tz("America/New_York").toDate() > moment()?.tz("America/New_York").hour(6).minute(0).second(0).toDate() ? moment()?.tz("America/New_York").hour(6).minute(0).second(0).toDate() : moment()?.tz("America/New_York").toDate());
    //         } else {

    //             setendDate(moment(lastFriday)?.tz("America/New_York").add(7, 'days').hour(6).minute(0).second(0).toDate()); // Set end date to the next Friday at 6 PM
    //         }
    //         //   setendDate(moment().toDate()); // Set end date to the next Friday at 6 PM
    //     } else if (nextFriday.isAfter(moment()?.tz("America/New_York"))) {
    //         // Set the end date and start date to the current date if it's greater
    //         setendDate(moment()?.tz("America/New_York").toDate());
    //         setStartDate(moment()?.tz("America/New_York").toDate());
    //         console.log("1");
    //     } else {
    //         console.log("2");
    //         setStartDate(nextFriday.toDate());
    //         setendDate(moment(nextFriday)?.tz("America/New_York").add(7, 'days').hour(6).toDate()); // Set end date to the next Friday at 6 PM
    //     }

    //     // Make API call with updated dates
    //     fetchvalue(role);
    // };




    useEffect(() => {
        fetchvalue(role)
    }, [])

    useEffect(() => {
        fetchvalue(role);
    }, [startDate, endDate, currentPage]);


    async function fetchvalue(role) {
      dispatch(setLoading(true))
      try {
          const { value } = await props?.current_payout(role, currentPage, 10, { startDate, endDate });
          if (value?.success) {
              setdata(value)
              // console.log(data?.success, "00000000");
          } else {
              dispatch(setAlert('error', value?.message));
              console.log(value?.message);
          }
      } catch (error) {
          dispatch(setAlert('error', error?.message));
          console.log(error?.message);
      }
      dispatch(setLoading(false))
  }

    const columns = role == 'vendor' ? [
        { 
            field: "taggr_name",
            headerName: "Full Name",
            width: 200,
            hideable: false,
            editable: false,
            valueFormatter: (val, row) => val,
        },
        { 
          field: "total_taggr_amount",
          headerName: "Amount to be transferred",
          width: 200,
          hideable: false,
          editable: false,
          valueFormatter: (val, row) => '$'+(row?.total_taggr_amount + row?.total_taggr_mail_in_amount),
        },
        { 
          field: "total_withhold_mail_in_citation_amount",
          headerName: "Withhold amount (Mail-In)",
          width: 200,
          hideable: false,
          editable: false,
          valueGetter: (val) => '$'+ Two_decimals(val ? val : 0)
        },
        {
          field: "total_tire_tag_amount",
          headerName: "Tire Tag Amount",
          width: 150,
          valueFormatter: (val) => '$'+(val)
        },
        { field: "total_taggrsolutions_amount", headerName: "Taggr Solutions Amount", width: 150, valueFormatter: (val) => '$'+(val)},
        { field: "total_taggrsolutions_mail_in_amount", headerName: "Taggr Solutions Mail-In Amount", width: 150, valueFormatter: (val) => '$'+(val)},
        {
          field: "citation_ids_count",
          headerName: "Tire tag Paid citations",
          width: 150,
          valueFormatter: (val, row) =>  val
    
        },
        {
          field: "un_paid_mail_in_citation_ids_count",
          headerName: "UnPaid Mail in citations",
          width: 150,
          valueFormatter: (val, row) =>  val
        },
        {
          field: "withhold_mail_in_citation_ids_count",
          headerName: "Withhold Mail in citations",
          width: 150,
          valueFormatter: (val, row) =>  val?.length
        },
        {
          field: "paid_mail_in_citation_ids_count",
          headerName: "Taggr Paid mail in citations",
          width: 150,
          valueFormatter: (val, row) =>  val
    
        },
      ] : role == "operator" ? [
        { 
            field: "fullname",
            headerName: "Full Name",
            width: 200,
            hideable: false,
            editable: false,
            valueFormatter: (val, row) => val,
        },
        { 
          field: "user_amount",
          headerName: "Actual amount transferred",
          width: 200,
          hideable: false,
          editable: false,
          valueFormatter: (val, row) => '$'+val/100,
        },
        { 
            field: "Amount before transferred",
            headerName: "Amount before transferred",
            width: 200,
            hideable: false,
            editable: false,
            valueFormatter: (val, row) => '$'+row?.break_down_amount?.operator_amount_before_MID/100,
        },
        { 
          field: "captured_refunded_amount",
          headerName: "Collected Refund Amount",
          width: 200,
          hideable: false,
          editable: false,
          valueFormatter: (val) => '-$'+  Two_decimals(val/100),
         },
        { 
            field: "break_down_amount",
            headerName: "Mail in deduction amount",
            width: 200,
            hideable: false,
            editable: false,
            valueFormatter: (val, row) => '-$'+val?.amount_for_MID/100,
        },
        {
          field: "user_destination",
          headerName: "User account",
          width: 150,
          valueFormatter: (val) => (val),
        },
        {
          field: "transfer_group",
          headerName: "Payout transfer Group",
          width: 150,
        },
        {
          field: "tire_tag_amount",
          headerName: "Tire Tag Amount",
          width: 150,
          valueFormatter: (val) => (val/100)
        },
        { field: "taggr_solutions_amount", headerName: "Taggr Solutions Amount", width: 150, valueFormatter: (val) => '$'+(val/100)},
        {
          field: "user_type",
          headerName: "Type",
          width: 150,
          valueFormatter: (val) => capitalize(val ?? "")
        },
        {
          field: "operator_paid_citations",
          headerName: "Paid citations",
          width: 150,
          valueFormatter: (val, row) =>  val
        },
        {
          field: "refunded_citation_data",
          headerName: "Refunded citations",
          width: 150,
          valueFormatter: (val, row) =>  val?.length ?? 0
    
        },
        {
          field: "operator_MID_ids",
          headerName: "Taggr Mail in deductions",
          width: 150,
          valueFormatter: (val, row) =>  val
        },
        {
          field: "createdAt",
          headerName: "Created at",
          width: 150,
          valueFormatter: (val, row) => moment(val)?.tz(tz).format('YYYY-MM-DD HH:mm:ss')
    
        }
      ] : [
        { 
            field: "fullname",
            headerName: "Full Name",
            width: 200,
            hideable: false,
            editable: false,
            valueFormatter: (val, row) => val,
        },
        { 
          field: "user_amount",
          headerName: "Amount transferred",
          width: 200,
          hideable: false,
          editable: false,
          valueFormatter: (val, row) => '$'+val,
        },
        {
          field: "user_destination",
          headerName: "User account",
          width: 150,
          valueFormatter: (val) => (val),
        },
        {
          field: "transfer_group",
          headerName: "Payout transfer Group",
          width: 150,
        },
        {
          field: "status",
          headerName: "Status",
          width: 150,
          renderCell: (row) => 
          { var val = row?.row;
          return  <td align="center">
                              <span className={status_classes[val?.status] || 'red'}>
                                {capitalize(val?.status ?? "")}
                              </span>
                            </td>
          },
        },
        {
          field: "user_type",
          headerName: "Type",
          width: 150,
          valueFormatter: (val) => capitalize(val ?? "")
        },
        {
          field: "createdAt",
          headerName: "Created at",
          width: 150,
          valueFormatter: (val, row) =>  moment(val)?.tz(tz).format('YYYY-MM-DD HH:mm:ss')
        },
      ];

    return (
        <>
            <section className="adminDashboard">
                <Header />
                <div className="adminreport-wrapper" id="profile-wrapper">
                    <div className="d-flex  align-items-center">
                        <img
                            src={leftArrow}
                            className="left-arrow"
                            onClick={() => navigate(-1)}
                        />
                        <h4>Current Payouts</h4>
                    </div>
                    <div className="payoutscardSection">
                        <ul className="nav nav-tabs">
                            <li className="nav-item"
                                onClick={() => {
                                    setRole("vendor")
                                    fetchvalue("vendor")
                                }}>
                                <h6>Taggr</h6>
                                <a className="nav-link active" data-bs-toggle="tab" href="#home">
                                    <div className="payoutsCards">
                                        <h1><span>$</span>{data?.payoutData?.taggr_amount_in_dollars}</h1>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item"
                                onClick={() => {
                                    setRole("operator")
                                    fetchvalue("operator")
                                }}>
                                <h6>Operator</h6>
                                <a className="nav-link" data-bs-toggle="tab" href="#menu1">
                                    <div className="payoutsCards">
                                        <h1><span>$</span>{data?.payoutData?.operator_amount_in_dollars}</h1>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item">
                                <h6>Tire Tag</h6>
                                <a className="nav-link">
                                    <div className="payoutsCards">
                                        <h1><span>$</span>{data?.payoutData?.tire_tag_amount_in_dollars}</h1>
                                    </div>
                                </a>
                            </li>
                            <li className="nav-item">
                                <h6>Taggr Solution</h6>
                                <a className="nav-link">
                                    <div className="payoutsCards">
                                        <h1><span>$</span>{data?.payoutData?.taggr_solutions_amount_in_dollars}</h1>
                                    </div>
                                </a>
                            </li>
                        </ul>
                        <div className="d-flex align-items-center justify-content-evenly gap-3 border-block">
                            {/* <button disabled={isBackwardDisable} onClick={handleBackward}><img src={payoutleftArrow} /></button> */}
                            <div className=" d-flex align-items-center justify-content-center gap-3">
                                <div className="date-picker-list flex">
                                <div className=" d-flex align-items-center justify-content-center gap-3">
                                <div className="date-picker-list">
                                    <label>Start Date</label>
                                    <DatePicker
                                        selected={startDate}
                                        placeholderText="--/--/----"
                                        className="date-picker"
                                        onChange={(date) => setStartDate(date)}
                                        disabled
                                    />
                                </div>
                                <div className="date-picker-list">
                                    <label>End Date </label>
                                    <DatePicker
                                        selected={endDate}
                                        placeholderText="--/--/----"
                                        className="date-picker"
                                        onChange={(date) => setendDate(date)}
                                        disabled
                                    />
                                </div>
                            </div>
                                </div>
                            </div>
                            {/* {<button disabled={isForwardDisable} onClick={handleForward}><img src={payoutrightArrow} /></button>} */}
                        </div>
                        <div className="tab-content">
                        <div className="cirtation-wrapper tab-pane active" id="home">
                            <div className="table-responsive">
                            {data?.citations_taggr?.length > 0 ? <DataGrid
                            rows={data?.citations_taggr?.map((citation, index) => (citation)
                            )}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            hideFooterPagination
                            hideFooter
                            // hideFooterSelectedRowCount
                            pagination
                            // onPageChange={(params) => setPageState({ currentPage: params.page })}
                            rowCount={data?.total}
                            paginationMode="server"
                            loading={props.loading}
                            getRowId={(row) => {
                              return(row._id.Taggr)
                            }}
                            />: <h2 style={{
                                maxWidth: "fit-content",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: "10%"
                              }}
                              > No record founds</h2>}
                            {data?.citations_taggr?.length > 0 && (
                                <div className='pagination-block'>
                                <Pagination
                                    hideOnSinglePage
                                    defaultCurrent={1}
                                    onChange={page => setCurrentPage(page)}
                                    current={currentPage}
                                    pageSize={10}
                                    total={data?.total}
                                    showSizeChanger={false}
                                />
                                </div>
                            )}
                            </div>
                        </div>
                        </div>
                        <div className="tab-content">
                            <div className="tab-pane fade" id="menu1">
                            <div className="table-responsive">
                            {data?.citations_operator?.length > 0 ? <DataGrid
                            rows={data?.citations_operator?.map((citation, index) => (citation)
                            )}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            hideFooterPagination
                            hideFooter
                            // hideFooterSelectedRowCount
                            pagination
                            // onPageChange={(params) => setPageState({ currentPage: params.page })}
                            rowCount={data?.total}
                            
                            paginationMode="server"
                            loading={props.loading}
                            getRowId={(row) => row.userId}
                            />: <h2 style={{
                                maxWidth: "fit-content",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: "10%"
                              }}
                              > No record founds</h2>}
                            {data?.citations_operator?.length > 0 && (
                                <div className='pagination-block'>
                                <Pagination
                                    hideOnSinglePage
                                    defaultCurrent={1}
                                    onChange={page => setCurrentPage(page)}
                                    current={currentPage}
                                    pageSize={10}
                                    total={data?.total_operator}
                                    showSizeChanger={false}
                                />
                                </div>
                            )}
                            </div>
                            </div>
                            <div className="tab-pane fade" id="menu2">
                            <div className="table-responsive">
                            {data?.payouts?.length > 0 ? <DataGrid
                            rows={data?.payouts?.map((citation, index) => (citation)
                            )}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            hideFooterPagination
                            hideFooter
                            // hideFooterSelectedRowCount
                            pagination
                            // onPageChange={(params) => setPageState({ currentPage: params.page })}
                            rowCount={data?.total}
                            
                            paginationMode="server"
                            loading={props.loading}
                            getRowId={(row) => row._id}
                            />: <h2 style={{
                                maxWidth: "fit-content",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: "10%"
                              }}
                              > No record founds</h2>}
                            {data?.payouts?.length > 0 && (
                                <div className='pagination-block'>
                                <Pagination
                                    hideOnSinglePage
                                    defaultCurrent={1}
                                    onChange={page => setCurrentPage(page)}
                                    current={currentPage}
                                    pageSize={10}
                                    total={data?.total}
                                    showSizeChanger={false}
                                />
                                </div>
                            )}
                            </div>
                            </div>
                            <div className="tab-pane fade" id="menu3">
                            <div className="table-responsive">
                            {data?.payouts?.length > 0 ? <DataGrid
                            rows={data?.payouts?.map((citation, index) => (citation)
                            )}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            hideFooterPagination
                            hideFooter
                            // hideFooterSelectedRowCount
                            pagination
                            // onPageChange={(params) => setPageState({ currentPage: params.page })}
                            rowCount={data?.total}
                            
                            paginationMode="server"
                            loading={props.loading}
                            getRowId={(row) => row._id}
                            />: <h2 style={{
                                maxWidth: "fit-content",
                                marginLeft: "auto",
                                marginRight: "auto",
                                padding: "10%"
                              }}
                              > No record founds</h2>}
                            {data?.payouts?.length > 0 && (
                                <div className='pagination-block'>
                                <Pagination
                                    hideOnSinglePage
                                    defaultCurrent={1}
                                    onChange={page => setCurrentPage(page)}
                                    current={currentPage}
                                    pageSize={10}
                                    total={data?.total}
                                    showSizeChanger={false}
                                />
                                </div>
                            )}
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
