import React, { useCallback } from "react";
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash'
import Header from "../../components/common/Header/container";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import IcWarning from "../../images/ic-warning.svg";

export default function ProfileComponent(props) {
  const dispatch = useDispatch()
  const navigate = useNavigate()
 

  const [isRerenderHeader, setRerenderHeader] = useState(false)
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [user, setUser] = useState({})
  const [error, seterror] = useState(null)
  const [img, setImg] = useState();
  const [userId,setuserId] = useState()
  const [error1 , seterror1] = useState()
  const [error2 , seterror2] = useState()
  const [error3 , seterror3] = useState()



  useEffect(() => {
    fetchName();
  }, [])

  

  const fetchName = async () => {
    const data = await props.user()
   try {
      if (data?.value?.success) {    
        setUser(data?.value?.user)
        setuserId(data?.value?.user?._id)
        setImg(data?.value?.user?.avatar);
    }else{
      // alert("error")
      dispatch(setAlert("error", data?.value?.message))
    }
  }catch(error){
    dispatch(setAlert("error", error?.message))
  }
  }



  const InputEventa = (e) => {
    setUser({ ...user, [e?.target?.name]: e.target.value })
  }

  const onSave = async (e) => {

    try {
      e.preventDefault();
      const email = user?.email
      const emailRegex = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
      let err = false
      seterror3(false)

      if (user?.phone?.length == 0) {
        err = true
        seterror(true);
      } else {
        if (user?.phone?.length < 10) {
          err = true
          seterror1("please enter 10 digit")
        }
      }
      if (user?.email?.length === 0) {
        err = true
        seterror(true);
      } else {
        if (emailRegex.test(email)) {
        } else {
          err = true
          seterror2("enter valid email")
        }
      }
     
      if (!err) {
        const { value } = await props.editprofile({ userId: user?._id, user });
        if (value?.success) {
          dispatch(setAlert("success", value?.message))
          setRerenderHeader(!isRerenderHeader);
          fetchName();
        } else {
          // alert("false")
          dispatch(setAlert("error", value?.message))
        }
      }
    } catch (error) {
      // alert("error")
      dispatch(setAlert("error", error?.message))
    }
  }



  const clickoninfo = () => {
    seterror(false)
    seterror3(false)
    seterror2(false)
    seterror1(false)
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('image/')) {
      setImg(URL.createObjectURL(file));
      setUser({ ...user, file });
    } else {
      dispatch(setAlert("error", "Invalid file type!"))
    }
  };



  return (
    <>
      <section className="WhiteBackground mainWrapper ">
        {/* <Header props={props} /> */}
        <Header isRerenderHeader={isRerenderHeader} />
        <section
          className="ProfileSection profile-bg main-wrapper"
          id="profile-wrapper"
        >
          <div className="ProfileBg">
            <span className="ProfileImgBox">
              <div class="wrapperprofile">
                <div class="box">
                  {img?.length > 0 ? (
                    <img src={img} alt="icons" class="js--image-preview" />
                  ) : (
                    <div class="js--image-preview"></div>
                  )}
                  <div className="upload-options">
                    <label>
                      <input
                        type="file"
                        class="image-upload"
                        accept="image/*"
                        onChange={handleFileChange}
                      />
                    </label>
                  </div>
                </div>
                <span class="tooltiptext">
                  <img src={IcWarning} />
                  Please upload a clear photo of your face for identity
                  verification
                </span>
              </div>
            </span>
          </div>
          <div className="ProfileForm">
            <div className="container">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <div className="mb-5 form-field">
                    <label for="pwd" className="form-label">
                      Operator Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pwd1"
                      placeholder="Enter your user name"
                      value={user?.fullname ?? ""}
                      name="fullname"
                      disabled
                    />
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <div className="mb-5 form-field">
                    <label for="pwd" className="form-label">
                      Contact Info
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pwd"
                      placeholder="Enter your contact"
                      // value={user?.phone}
                      value={user?.phone ?? ''}
                      name="phone"
                      maxLength={10}
                      onClick={clickoninfo}
                      onChange={(e) => {
                        const input = e.target.value;
                        const regex = /^[0-9\b]+$/;
                        if (input == "" || regex.test(input)) {
                          InputEventa(e);
                          seterror3("");
                          seterror(false);
                        } else {
                          seterror3("Text not allowed!");
                        }
                      }}
                    />
                    {error1 && <p className="Error">{error1}</p>}
                    {error3 && <p className="Error">{error3}</p>}
                    {error && user?.phone <= 0 ? (
                      <div className="Error">Please enter contact</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <div className="mb-5 form-field">
                    <label for="pwd" className="form-label">
                      Email ID
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="pwd"
                      placeholder="Enter your mail id"
                      value={user?.email ?? ""}
                      name="email"
                      onClick={clickoninfo}
                      onChange={(e) => {
                        InputEventa(e);
                        seterror(false);
                      }}
                    />
                    {error2 && <p className="Error">{error2}</p>}
                    {error && user?.email <= 0 ? (
                      <div className="Error">Please enter email</div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <div className="mb-5 form-field">
                    <label for="pwd" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pwd"
                      placeholder="Enter your address"
                      // value={user?.address}
                      value={user?.address ?? ""}
                      name="address"
                      disabled
                    />
                    {/* {error && user?.address <= 0 ? <div className="Error">Please enter address</div> : ""} */}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <div className="mb-5 form-field">
                    <label for="pwd" className="form-label">
                      Dock Fee
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="pwd"
                      placeholder="Enter dock fees"
                      // value={user?.dock_fees}
                      value={user?.dock_fees ?? ""}
                      name="dock_fees"
                      disabled
                      onChange={(e) => {
                        InputEventa(e);
                        seterror(false);
                      }}
                    />
                    {/* {error && user?.dock_fees <= 0 ? <div className="Error">Please enter dock_fees</div> : ""} */}
                  </div>
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <div className="mb-5 form-field">
                    <label for="pwd" className="form-label">
                      Company Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pwd"
                      placeholder="Company name"
                      onChange={(e) => {
                        InputEventa(e);
                      }}
                      value={user?.operatorInfo?.company ?? user?.company}
                      disabled={user?.operatorInfo?.company}
                      name="company"
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 d-flex justify-content-center">
                  <button className="DarkBtn me-0" onClick={(e) => onSave(e)}>
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
}
