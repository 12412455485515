import React, { useEffect } from "react";
import { Grid, Card, Switch, Tooltip, Box, Typography } from "@mui/material";

const TagsDeployedPermissionCard = (props) => {
  const { modules, setModules, isViewOnly = false } = props;
  useEffect(() => {
    if (modules.TagsDeployed_view === undefined) {
      setModules((prevModules) => ({
        ...prevModules,
        TagsDeployed_view: true,
        TagsDeployed_edit: true,
        TagsDeployed_refundRequest: true,
      }));
    }
  }, [modules, setModules]);

  const handleTagsDeployedViewChange = (event) => {
    const isTagsDeployedViewEnabled = event.target.checked;
    setModules({
      ...modules,
      TagsDeployed_view: isTagsDeployedViewEnabled,
      ...(isTagsDeployedViewEnabled
        ? {}
        : {
            TagsDeployed_edit: false,
            TagsDeployed_refundRequest: false,
          }),
    });
  };

  return (
    <>
      <Card sx={{ minWidth: 275, boxShadow: 3 }}>
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} container alignItems="center">
              <Typography
                color="dark"
                display="block"
                variant="subtitle2"
                fontWeight="bold"
                sx={{ flex: 1 }}
              >
                TagsDeployed Module
              </Typography>
              <Tooltip
                title={`${
                  modules.TagsDeployed_view ? "Disable" : "Enable"
                } TagsDeployed module`}
                placement="top"
              >
                <Switch
                  checked={modules.TagsDeployed_view}
                  onChange={handleTagsDeployedViewChange}
                  disabled={isViewOnly}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Typography variant="caption" fontWeight="regular">
                    Edit TagsDeployed
                  </Typography>

                  <Switch
                    checked={modules.TagsDeployed_edit}
                    onChange={(event) =>
                      setModules({
                        ...modules,
                        TagsDeployed_edit: event.target.checked,
                      })
                    }
                    disabled={!modules.TagsDeployed_view || isViewOnly}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Typography variant="caption" fontWeight="regular">
                    Refund Request TagsDeployed
                  </Typography>

                  <Switch
                    checked={modules.TagsDeployed_refundRequest}
                    onChange={(event) =>
                      setModules({
                        ...modules,
                        TagsDeployed_refundRequest: event.target.checked,
                      })
                    }
                    disabled={!modules.TagsDeployed_view || isViewOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default TagsDeployedPermissionCard;
