import { connect } from "react-redux";
import { get_disputed_citation_by_id_operator } from "../../store/home/duck";
import OperatorDisputedCitationsInformation from "./OperatorDisputedCitationsInformation";

const OperatorDisputedCitationsInformationContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    get_disputed_citation_by_id_operator
  }
)(OperatorDisputedCitationsInformation);

export default OperatorDisputedCitationsInformationContainer;
