import React, { useEffect, useState } from "react";
import Header from "../Citations-Header/container";
import leftArrow from "../../../images/left-arrow.svg";
import { useNavigate } from "react-router-dom";


export default function Payouts(props) {
    const navigate = useNavigate();
    const storedData = localStorage.getItem("selectedTaggr");
    const data = storedData ? JSON.parse(storedData) : null;

    const hideMenu = () => {
        var element = document.getElementById("profile-wrapper");
        var menu = document.getElementById("test_menu");
        element.classList.add("left-wrapper");
        menu.classList.remove("showmenu");
      }

      useEffect(() => {
        hideMenu();
      }, [])

    return (
        <>
            <section className="adminDashboard">
                <Header />
                <div className="adminreport-wrapper" id="profile-wrapper">
                    <div className="container">
                        <div className="d-flex  align-items-center pb-3">
                            <img
                                src={leftArrow}
                                className="left-arrow"
                                onClick={() => navigate(-1)}
                            />
                            <h4>Payouts</h4>
                        </div>
                        <div className="payoutscardSection">
                            <div className="row">
                                <div className="col-lg-3">
                                    <label className="form-label">Full Name</label>
                                    <div className=" d-flex align-items-center"><p className="m-0">{data?.fullname}</p></div>
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Lot Name</label>
                                    <div className="d-flex align-items-center"><p className="m-0">{data?.lotname}</p></div>
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Tags</label>
                                    <div className=" d-flex align-items-center"><p className="m-0">{data?.tags}</p></div>
                                </div>
                                <div className="col-lg-3">
                                    <label className="form-label">Total Amount</label>
                                    <div className="d-flex align-items-center"><p className="m-0">{data?.total_amount}</p></div>
                                </div>
                            </div>
                            <div className="showid table-responsive">
                                <table className="table header-fixed">
                                    <thead>
                                        <tr>
                                            <th>Notice Number</th>
                                            <th>Transition Id</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {data?.citation_numbers?.map((citation, index) => (
                                            <tr key={index}>
                                                <td>{citation?.citation_number}</td>
                                                <td>{citation?.txnId}</td>
                                            </tr>
                                        ))}                                       
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}