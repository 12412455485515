import {
    Dialog,
    DialogContent,
    Grid,
    Stack,
    Switch,
    Box,
    Typography,
    Badge,
    DialogTitle,
    IconButton,
  } from "@mui/material";
  import { isEmpty } from "lodash";
  import CloseIcon from "@mui/icons-material/Close";
  
  const PermissionsDialogBox = (props) => {
    const { dialogOpen, onClose, PermissionsData = {} } = props;
  
    return (
      <>
        <Dialog open={dialogOpen} onClose={onClose}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <DialogTitle sx={{ m: 0, p: 2 }}>Role Permissions</DialogTitle>
            <IconButton
              aria-label="close"
              sx={(theme) => ({
                position: "absolute",
                right: 8,
                top: 8,
                color: theme.palette.grey[500],
              })}
              onClick={onClose}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <DialogContent>
            <Grid container spacing={2} className="mt-1">
              <Grid item xs={12}>
                {!isEmpty(PermissionsData) && (
                  <Box>
                    <Typography variant="h6" sx={{ mb: 1.3 }}>
                      Modules and Permissions
                    </Typography>
                    {Object.keys(PermissionsData).map((key, index) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        spacing={2}
                        key={index}
                        sx={{ mb: 1.6 }}
                      >
                        <Typography variant="subtitle2">
                          {key.replace(/_/g, " ")}
                        </Typography>
                        <Stack
                          direction="row"
                          alignItems="center"
                          spacing={1}
                        >
                          <Badge
                            badgeContent={PermissionsData[key] ? "YES" : "NO"}
                            color={PermissionsData[key] ? "primary" : "secondary"}
                          />
                          <Switch checked={PermissionsData[key]} />
                        </Stack>
                      </Stack>
                    ))}
                  </Box>
                )}
              </Grid>
            </Grid>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  export default PermissionsDialogBox;
  