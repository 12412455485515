import { connect } from "react-redux";
import { lotprovider, user, deleteyourlot, addlots, getProviders, getLotsById } from "../../store/home/duck";
import YourLotsComponent from "./component";

const YourLotsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    lotprovider,
    user,
    deleteyourlot,
    addlots,
    getProviders,
    getLotsById
  }
)(YourLotsComponent);

export default YourLotsContainer;
