import React, { useEffect } from "react";
import { Grid, Card, Switch, Tooltip, Box, Typography } from "@mui/material";

const LotsPermissionCard = (props) => {
  const { modules, setModules, isViewOnly = false } = props;
  useEffect(() => {
    if (modules.Lots_view === undefined) {
      setModules((prevModules) => ({
        ...prevModules,
        Lots_view: true,
        Lots_add: true,
        Lots_update: true,
        Lots_delete: true,
        Lots_active_deactive: true,
      }));
    }
  }, [modules, setModules]);

  const handleLotsViewChange = (event) => {
    const isLotsViewEnabled = event.target.checked;
    setModules({
      ...modules,
      Lots_view: isLotsViewEnabled,
      ...(isLotsViewEnabled
        ? {}
        : {
            Lots_add: false,
            Lots_update: false,
            Lots_delete: false,
            Lots_active_deactive: false,
          }),
    });
  };

  return (
    <>
      <Card sx={{ minWidth: 275, boxShadow: 3 }}>
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} container alignItems="center">
              <Typography
                color="dark"
                display="block"
                variant="subtitle2"
                fontWeight="bold"
                sx={{ flex: 1 }}
              >
                Lots Module
              </Typography>
              <Tooltip
                title={`${
                  modules.Lots_view ? "Disable" : "Enable"
                } Lots module`}
                placement="top"
              >
                <Switch
                  checked={modules.Lots_view}
                  onChange={handleLotsViewChange}
                  disabled={isViewOnly}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={6} md={4}>
                  <Typography variant="caption" fontWeight="regular">
                    Add Lots
                  </Typography>

                  <Switch
                    checked={modules.Lots_add}
                    onChange={(event) =>
                      setModules({
                        ...modules,
                        Lots_add: event.target.checked,
                      })
                    }
                    disabled={!modules.Lots_view || isViewOnly}
                  />
                </Grid>

                <Grid item xs={6} md={4}>
                  <Typography variant="caption" fontWeight="regular">
                    Update Lots
                  </Typography>
                  <Switch
                    checked={modules.Lots_update}
                    onChange={(event) =>
                      setModules({
                        ...modules,
                        Lots_update: event.target.checked,
                      })
                    }
                    disabled={!modules.Lots_view || isViewOnly}
                  />
                </Grid>

                <Grid item xs={6} md={4}>
                  <Typography variant="caption" fontWeight="regular">
                    Delete Lots
                  </Typography>
                  <Switch
                    checked={modules.Lots_delete}
                    onChange={(event) =>
                      setModules({
                        ...modules,
                        Lots_delete: event.target.checked,
                      })
                    }
                    disabled={!modules.Lots_view || isViewOnly}
                  />
                </Grid>
                <Grid item xs={6} md={4}>
                  <Typography variant="caption" fontWeight="regular">
                    Active/Deactive Lots
                  </Typography>
                  <Switch
                    checked={modules.Lots_active_deactive}
                    onChange={(event) =>
                      setModules({
                        ...modules,
                        Lots_active_deactive: event.target.checked,
                      })
                    }
                    disabled={!modules.Lots_view || isViewOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default LotsPermissionCard;
