import React, { useReducer, useState } from 'react';
import IcUpload from "../../images/upload-file.svg";
import './proof.scss';
import { useDispatch } from 'react-redux';
import { setAlert,setLoading } from "../../store/actioncreator";
import { useNavigate } from 'react-router-dom';
import ConfirmationComponent from './confirmation';

const SubmitProofComponent = (props) => {
  const phoneNumber = new URLSearchParams(window.parent.location.search)?.get("phone_number");
  const initialPageState = {
    proofImage: "",
    isSubmit: false
  }

  const initialState = {
    proofImage: []
  }
  const [pageState, setPageState] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, initialPageState)

  const [state, setState] = useReducer((prev, next) => {
    return { ...prev, ...next }
  }, initialState)

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [blank_proofImage , set_blank_proofImage] = useState();
  const [licensePlate, setLicensePlate] = useState('');
  const [tireTagSerialNumber, setTireTagSerialNumber] = useState('');
  const [blank_tireTagSerialNumber , set_blank_tireTagSerialNumber] = useState()
  const [blank_phoneNumber , set_blank_phoneNumber] = useState()
  const [blank_licensePlate , set_blank_licensePlate] = useState()
  const [show_confirmation_modal , set_show_confirmation_modal] = useState()
  

  const clearerror = () => {
    set_blank_proofImage(false)
    set_blank_tireTagSerialNumber(false)
    set_blank_licensePlate(false)
    set_blank_phoneNumber(false)
  }

  const onClose = () => {
    set_show_confirmation_modal(false)
  }

  const handleSubmit = async ()=>{
    dispatch(setLoading(true))
    if (licensePlate?.trim() === ''){
      set_blank_licensePlate('Please fill license plate')
      dispatch(setLoading(false))
      return
    }
    if (!phoneNumber){
      set_blank_phoneNumber('Phone number missing')
      dispatch(setLoading(false))
      return
    }
    if (tireTagSerialNumber?.trim() === ''){
      set_blank_tireTagSerialNumber('Please fill tire tag serial number')
      dispatch(setLoading(false))
      return
    }
    if (state?.proofImage?.length == 0){
      set_blank_proofImage('Please attach image')
      dispatch(setLoading(false))
      return
    }

    console.log(licensePlate);
    console.log(tireTagSerialNumber);
    try {
      const { value } = await props?.submitProof({...state, licensePlate: licensePlate?.toUpperCase(), tireTagSerialNumber: tireTagSerialNumber, point_of_contact: phoneNumber})
      if (value?.success) {
        set_show_confirmation_modal(true)
      }
      else {
        dispatch(setAlert("error", value?.message))
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message))
    }
    dispatch(setLoading(false))
  }

  return (
    <div className='container'>
      <div className="col-lg-12">
        <div className="form-group">
          <label className="InputLabels">
            Vehicle License Plate
            <span className="mandatory Error">*</span>
          </label>
          <input
            type="text"
            placeholder=""
            name="licensePlate"
            className="InputStyles"
            onClick={clearerror}
            value={licensePlate?.toUpperCase()}
            onChange={(e) => setLicensePlate(e.target.value)}
          />
          {blank_licensePlate && (
            <p className="Error">{blank_licensePlate}</p>
          )}
        </div>
      </div>
      <div className="col-lg-12">
        <div className="form-group">
          <label className="InputLabels">
            Phone Number
            <span className="mandatory Error">*</span>
          </label>
          <input
            type="text"
            placeholder=""
            name="phoneNumber"
            className="InputStyles"
            value={phoneNumber}
            disabled={true}
          />
          {blank_phoneNumber && (
            <p className="Error">{blank_phoneNumber}</p>
          )}
        </div>
      </div>
      <div className="col-lg-12">
        <div className="form-group">
          <label className="InputLabels">
            Tire Tag Serial Number
            <span className="mandatory Error">*</span>
          </label>
          <input
            type="text"
            placeholder=""
            name="tireTagSerialNumber"
            className="InputStyles"
            onClick={clearerror}
            onChange={(e)=>setTireTagSerialNumber(e.target.value)}
          />
          {blank_tireTagSerialNumber && (
            <p className="Error">{blank_tireTagSerialNumber}</p>
          )}
        </div>
      </div>
      <div className="file-upload">
        {pageState?.previewproofImage ? (
          <img
            src={pageState?.previewproofImage}
            alt="optional"
            className="large-image"
          />
        ) : (
          <>
            <div className="d-flex justify-content-center align-items-center flex-wrap h-100">
              <div>
                <img
                  src={IcUpload}
                  alt="loading"
                  className="UploadIcon"
                />
                <p>Proof Image</p>
              </div>
            </div>
          </>
        )}
        <input
          type="file"
          className=""
          accept="image/png,image/jpg,image/jpeg"
          name="proofImage"
          onClick={clearerror}
          onChange={(e) => {
            setState({
              proofImage:
                e?.target?.files?.length > 0
                  ? e?.target?.files[0]
                  : "",
            });
            setPageState({
              ["preview" + e?.target?.name]:
                e?.target?.files?.length > 0
                  ? URL.createObjectURL(e?.target?.files[0])
                  : "",
            });
          }}
        />
      </div>
      {blank_proofImage && (
        <p className="Error">{blank_proofImage}</p>
      )}
    <button className="custombutton" onClick={() => handleSubmit()} >Submit</button>
    <ConfirmationComponent show={show_confirmation_modal} title={"Confirmation"} onClose={onClose}/>
    </div>
  );
};

export default SubmitProofComponent;
