import { connect } from "react-redux";
import { get_operator_taggr_visit } from "../../../store/home/duck";
import AdminReportComponent from "./admin_report_component";

const AdminReportContainer = connect(
  // Map state to props
  (state) => ({}),
  // Map actions to dispatch and props
  {
    get_operator_taggr_visit
  }
)(AdminReportComponent);

export default AdminReportContainer;
