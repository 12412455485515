import React, { useEffect } from "react";
import {
  Grid,
  Card,
  Switch,
  Tooltip,
  Box,
  Typography,
  Checkbox,
} from "@mui/material";

const RefundPermissionCard = (props) => {
  const { modules, setModules, isViewOnly = false } = props;
  useEffect(() => {
    if (modules.Refund_view === undefined) {
      setModules((prevModules) => ({
        ...prevModules,
        Refund_view: true,
      }));
    }
  }, [modules, setModules]);

  const handleRefundViewChange = (event) => {
    const isReportViewEnabled = event.target.checked;
    setModules({
      ...modules,
      Refund_view: isReportViewEnabled,
    });
  };

  return (
    <>
      <Card sx={{ minWidth: 275, boxShadow: 3, width: "inherit" }}>
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} xs={12} container alignItems="center">
              <Typography
                color="dark"
                display="block"
                variant="subtitle2"
                fontWeight="bold"
                sx={{ flex: 1 }}
              >
                Refund Module
              </Typography>

              <Tooltip
                title={`${
                  modules.Refund_view ? "Disable" : "Enable"
                } Refund module`}
                placement="top"
              >
                <Switch
                  checked={modules.Refund_view}
                  onChange={handleRefundViewChange}
                  disabled={isViewOnly}
                />
              </Tooltip>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default RefundPermissionCard;
