import { connect } from "react-redux";
import { logins ,user, getuserdetails, editprofile , gettaggrdetails,reconcilation} from "../../../store/home/duck.js";
import NewPayouts from "./old-payouts.js";

const PayoutPage = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    getuserdetails,
    editprofile,
    gettaggrdetails,
    reconcilation
  }
)(NewPayouts);

export default PayoutPage;
