import {
    Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  styled,
} from "@mui/material";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import { Formik } from "formik";
import { useLayoutEffect, useState } from "react";
import RefundPermissionCard from "./PermissionCards/refundRequestPermissonCard";
import DashboardPermissionCard from "./PermissionCards/dashboardRequestPermissonCard";
import LotsPermissionCard from "./PermissionCards/lotsPermissionCard";
import ReportPermissionCard from "./PermissionCards/reportPermissionCard";
import TagsDeployedPermissionCard from "./PermissionCards/tagsDeployedPermissionCard";
import PermitsPermissionCard from "./PermissionCards/permitsPermissionCard";
import NoticePermissionCard from "./PermissionCards/noticePerimissionCard";
import DisputedCitationsPermissionCard from "./PermissionCards/disputedCitationsPermissionCard";
import VisitsPermissionCard from "./PermissionCards/visitsPermissionCard";
import { useDispatch } from "react-redux";
import { setAlert, setLoading } from "../../../store/actioncreator";

export const createRolesValidationSchema = Yup.object().shape({
  title: Yup.string().required().label("Role Title"),
});

export default function CreateRole(props) {
  const dispatch = useDispatch();
  const { dialogOpen, onClose, updateParent, data, isViewOnly = false } = props;
  const [modules, setModules] = useState({});
  const [roleId, setRoleId] = useState();
  const [title, setTitle] = useState();


  useLayoutEffect(() => {
    if (data && data.permissions) {
      setModules(data.permissions);
      setRoleId(data._id);
      setTitle(data.title);
    }
  }, [data]);

  const handleUpdateRole = async (payload) => {
    dispatch(setLoading(true));
    try {
      const { value } = await props.updateRole(roleId, payload);
      if (value?.success) {
        updateParent();
        onClose();
        dispatch(setAlert("success", value?.message));
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  };

  return (
    <>
      <Dialog open={dialogOpen} onClose={onClose}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {!isViewOnly && (
            <DialogTitle sx={{ m: 0, p: 2 }}>Create New Role</DialogTitle>
          )}
          <IconButton
            aria-label="close"
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500],
            })}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent sx={{ mt: isViewOnly ? 4 : 0 }}>
          <Formik
            initialValues={{
              title,
            }}
            validationSchema={createRolesValidationSchema}
            onSubmit={(value, action) => {
              handleUpdateRole({ ...value, permissions: modules, roleId });
            }}
          >
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="title"
                      value={props.values.title}
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      label="Role Title"
                      fullWidth
                      error={
                        props.errors.title && props.touched.title ? true : false
                      }
                      success={
                        props.errors.title && props.touched.title ? false : true
                      }
                      helperText={
                        props.errors.title && props.touched.title
                          ? props.errors.title
                          : null
                      }
                      disabled={isViewOnly}
                    />
                  </Grid>

                  <Grid item sx={{ width: "100%" }}>
                    <DashboardPermissionCard
                      modules={modules}
                      setModules={setModules}
                      isViewOnly={isViewOnly}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <LotsPermissionCard
                      modules={modules}
                      setModules={setModules}
                      isViewOnly={isViewOnly}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <ReportPermissionCard
                      modules={modules}
                      setModules={setModules}
                      isViewOnly={isViewOnly}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <TagsDeployedPermissionCard
                      modules={modules}
                      setModules={setModules}
                      isViewOnly={isViewOnly}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <PermitsPermissionCard
                      modules={modules}
                      setModules={setModules}
                      isViewOnly={isViewOnly}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <NoticePermissionCard
                      modules={modules}
                      setModules={setModules}
                      isViewOnly={isViewOnly}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <DisputedCitationsPermissionCard
                      modules={modules}
                      setModules={setModules}
                      isViewOnly={isViewOnly}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <VisitsPermissionCard
                      modules={modules}
                      setModules={setModules}
                      isViewOnly={isViewOnly}
                    />
                  </Grid>
                  <Grid item sx={{ width: "100%" }}>
                    <RefundPermissionCard
                      modules={modules}
                      setModules={setModules}
                      isViewOnly={isViewOnly}
                    />
                  </Grid>
                  {!isViewOnly && (
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end">
                        <button className="btn DarkBtn">Update</button>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </form>
            )}
          </Formik>
        </DialogContent>
      </Dialog>
    </>
  );
}
