export const INIT = 'init'
export const LOADING = 'loading'
export const SUCCESS = 'success'
export const ERROR = 'error'
export const SAVED = 'saved'

export const STATUS_CLASSES ={
  paid: "paid-green",
  approved: "paid-green",
  pending: "red",
  voided: "voided-red"
}