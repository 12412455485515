import { connect } from "react-redux";
import { user, getAllTireTags } from "../../../store/home/duck";
import TireTags from "./tire_tags";

const TireTagsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    user,
    getAllTireTags
  }
)(TireTags);

export default TireTagsContainer;
