import React, { useEffect } from "react";
import { Grid, Card, Switch, Tooltip, Box, Typography } from "@mui/material";

const VisitsPermissionCard = (props) => {
  const { modules, setModules, isViewOnly = false } = props;
  useEffect(() => {
    if (modules.Visits_view === undefined) {
      setModules((prevModules) => ({
        ...prevModules,
        Visits_view: true,
        Visits_downloadReport: true,
      }));
    }
  }, [modules, setModules]);

  const handleVisitsViewChange = (event) => {
    const isVisitsViewEnabled = event.target.checked;
    setModules({
      ...modules,
      Visits_view: isVisitsViewEnabled,
      ...(isVisitsViewEnabled
        ? {}
        : {
            Visits_downloadReport: false,
          }),
    });
  };

  return (
    <>
      <Card sx={{ minWidth: 275, boxShadow: 3 }}>
        <Box p={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} container alignItems="center">
              <Typography
                color="dark"
                display="block"
                variant="subtitle2"
                fontWeight="bold"
                sx={{ flex: 1 }}
              >
                Visits Module
              </Typography>
              <Tooltip
                title={`${
                  modules.Visits_view ? "Disable" : "Enable"
                } Visits module`}
                placement="top"
              >
                <Switch
                  checked={modules.Visits_view}
                  onChange={handleVisitsViewChange}
                  disabled={isViewOnly}
                />
              </Tooltip>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography variant="caption" fontWeight="regular">
                    Download All Reports
                  </Typography>

                  <Switch
                    checked={modules.Visits_downloadReport}
                    onChange={(event) =>
                      setModules({
                        ...modules,
                        Visits_downloadReport: event.target.checked,
                      })
                    }
                    disabled={!modules.Visits_view || isViewOnly}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
};

export default VisitsPermissionCard;
