import { connect } from "react-redux";
import { get_disputed_citation_by_id } from "../../../store/home/duck";
import AdminDisputedCitationsInformation from "./AdminDisputedCitationsInformation";

const AdminDisputedCitationsInformationContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    get_disputed_citation_by_id
  }
)(AdminDisputedCitationsInformation);

export default AdminDisputedCitationsInformationContainer;
