import { connect } from "react-redux";
// import { logins ,user} from "../../../store/home/api";
import { logins ,user,getuserdetails,getlotdetails, editprofile} from "../../../store/home/duck";
import OperatorListComponent from "./operator_list_component";

const OperatorListContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    getuserdetails,
    getlotdetails,
    editprofile
  }
)(OperatorListComponent);

export default OperatorListContainer;
