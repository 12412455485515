import Loading from "../../utils/loading/Loading";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Pagination } from 'antd';
import { Link, useNavigate } from "react-router-dom";
import { debounce, delayLoading, truncateString } from "../../global";
import LotDeleteModal from "../../utils/modal";
import Edit from "../../images/Dashboard-edit.svg";
import WhiteViewEdit from "../../images/ic-edit-white.svg";
import DarkViewEdit from "../../images/ic-edit-dark.svg";
import Delete from "../../images/ic-delete.svg";
import DarkViewDelete from "../../images/ic-delete-dark.svg";
import WhiteViewDelete from "../../images/ic-delete-white.svg";
import View from "../../images/IC-view.svg";
import DarkView from "../../images/DarkViewImg.svg";
import WhiteView from "../../images/WhiteViewImg.svg";
import searchicon from "../../images/ic-search.svg";
import Header from "../../components/common/Header/container";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Plus from "../../images/Plus.svg";
import Minus from "../../images/Minus.png";
import uploadFile from "../../images/upload-file.svg";
import close from "../../images/Close.svg";
import leftArrow from "../../images/left-arrow.svg";
import CloseWhite from "../../images/closewhite.svg";
import { setAlert, setLoading } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import NoResultFound from "../../utils/NoResultFound/NoResultFound";
import { Button, Tooltip } from "@mui/material";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";

export default function ManagerComponent(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [managers, setManagers] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const initialPageState = {
    total: 0,
    defaultCurrentPage: 1,
    currentPage: 1,
    pageSize: 10
  }

  const [pageState, setPageState] = useReducer((prev, next) => {
    if (next?.currentPage) {
      getAllManagers(next?.currentPage)
    }
    return { ...prev, ...next }
  }, initialPageState)

  useEffect(() => {
    // checkOperator();
    setLoading(true);
    getAllManagers(pageState?.currentPage);
  }, []);



  async function getAllManagers(page) {
    try {
      const { value } = await props.getAllManagers({ page, size: 10 });
      console.log(value, "<<<value")
      if (value?.success) {
        setManagers(value?.managers);
        setPageState({ total: value?.total })
      } else {
        console.log(value?.message);
      }
    } catch (error) {
      console.log(error, "<<<error");
    }
    setIsLoading(false);
  }

  const search = async (query) => {
    try {
      if (query?.length > 2) {
        const { value } = await props?.searchForOperator("manager", query);
        if (value?.success) {
          setManagers(value?.results);
          setPageState({
            total: value?.results?.length,
            pageSize: value?.results?.length,
          });
        } else {
          dispatch(setAlert("error", value?.message));
        }
      } else {
        setPageState({ currentPage: 1 });
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
  };

  const searchInputRef = useRef();

  const handelSwitch = async (manager) => {
    console.log("manager ===>", manager);
    try {
      dispatch(setLoading(true));
      const { value } = await props.token({ email: manager?.email });
      console.log("value ===>", value);
      if (value.success) {
        if (value?.user?.role === "manager") {
          const oldToken = localStorage.getItem("token");
          localStorage.setItem("operatorToken", oldToken);
          localStorage.setItem("token", value?.token);
          navigate("/dashboard");
        } else {
          dispatch(setAlert("error", "Invalid User!"));
        }
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  };

  return (
    <>
      {isLoading && <Loading />}
      <section className="mainWrapper">
        <Header />
        <div className="main-wrapper" id="profile-wrapper">
          <div>
            <h3 className="d-flex align-items-center">
              <img
                src={leftArrow}
                className="left-arrow"
                onClick={() => navigate(-1)}
              />
              Create Users
            </h3>
            <div className="row">
              <div className="search-lot-wrapper">
                <div className="d-flex w-100">
                  <div class="search-lot">
                    <button class="btn your-lot-btn" type="button">
                      <img src={searchicon} className="search-btn" />
                    </button>
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search..."
                      value={searchKey}
                      ref={searchInputRef}
                      onChange={async (e) => {
                        setSearchKey(e?.target?.value);
                        e?.target?.value?.length > 2 &&
                          debounce(await search(e?.target?.value), 2000);
                        e?.target?.value?.length === 0 && getAllManagers(1);
                      }}
                    />
                  </div>    
                     <Link to="/manager/action" className="DarkBtn">
                     Add User
                   </Link>
                </div>
              </div>
            </div>
          </div>
          {
            <div className="lotsWrapper">
              <div className="table-responsive">
                <table class="table StripTable">
                  <thead>
                    <tr>
                      <th>Manager Name</th>
                      <th>Email Address</th>
                      <th>Phone Number</th>
                      <th>Assigned Lots</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {managers?.length > 0 ? (
                      managers?.map((data, index) => {
                        return (
                          <tr key={index}>
                            <td>{data?.fullname || "N/A"}</td>
                            <td>{data?.email || "N/A"}</td>
                            <td>{data?.phone || "N/A"}</td>
                            {/* <td>{truncateString(data?.lots?.map(lot => lot?.lotname)?.join(', '), 25) || "N/A"}</td> */}
                            <td>{data?.lots?.length || "0"}</td>
                            <td>
                              <div
                                className={
                                  data?.status !== "deleted"
                                    ? "green LabelStatus"
                                    : "red LabelStatus"
                                }
                              >
                                {data?.status !== "deleted"
                                  ? "Active"
                                  : "Inactive"}
                              </div>
                            </td>
                            <td>
                              <div className="d-flex align-items-center">
                                <Tooltip
                                  title={`Impersonate To ${data?.fullname} Manager`}
                                  placement="left"
                                >
                                  <Button
                                    variant="outlined"
                                    onClick={() => handelSwitch(data)}
                                    fontSize="small"
                                    color="inherit"
                                  >
                                    <SelfImprovementIcon />
                                  </Button>
                                </Tooltip>
                                <button
                                  className="ActionsButtons"
                                  onClick={() =>
                                    navigate(
                                      `/manager/action/detail/${data?._id}`
                                    )
                                  }
                                >
                                  <img src={DarkView} className="DarkImg" />
                                  <img src={WhiteView} className="WhiteImg" />
                                </button>

                                <button
                                  className="ActionsButtons"
                                  onClick={() =>
                                    navigate(
                                      `/manager/action?managerId=${data?._id}`
                                    )
                                  }
                                >
                                  <img
                                    src={DarkViewEdit}
                                    className="DarkImg"
                                    alt="Edit"
                                  />
                                  <img
                                    src={WhiteViewEdit}
                                    className="WhiteImg"
                                    alt="Edit"
                                  />
                                </button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : managers === null || managers?.length === 0 ? (
                      searchKey && (
                        <div className="dataText">
                          <h5>No Result Found.</h5>
                        </div>
                      )
                    ) : (
                      <div className="dataText">
                        <h5>No Result Found.</h5>
                      </div>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="pagination-block">
                <Pagination
                  hideOnSinglePage
                  defaultCurrent={pageState?.defaultCurrentPage}
                  onChange={(page) => setPageState({ currentPage: page })}
                  current={pageState?.currentPage}
                  pageSize={pageState?.pageSize}
                  total={pageState?.total}
                  showSizeChanger={false}
                />
              </div>
            </div>
          }
        </div>
      </section>
    </>
  );
}
