import React, { useEffect, useReducer, useState, useRef } from "react";
import WhiteViewEdit from "../../images/ic-edit-white.svg";
import DarkViewEdit from "../../images/ic-edit-dark.svg";
import DarkViewDelete from "../../images/ic-delete-dark.svg";
import WhiteViewDelete from "../../images/ic-delete-white.svg";
import RoleDeleteModal from "../../utils/modal";
import Header from "../../components/common/Header/container";
import leftArrow from "../../images/left-arrow.svg";
import { useNavigate } from "react-router-dom";
import { setAlert, setLoading } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import { Pagination } from "antd";
import NoResultFound from "../../utils/NoResultFound/NoResultFound";
import CreateRole from "./Components/create_role";
import UpdateRole from "./Components/update_role";
import DarkView from "../../images/DarkViewImg.svg";
import WhiteView from "../../images/WhiteViewImg.svg";
import PermissionsDialogBox from "./Components/ViewPermissionsDialog";

export default function RoleComponent(props) {
  const { user, addRole, updateRole } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [permissionDialogOpen, setPermissionDialogOpen] = useState(false);
  const [isView, setIsView] = useState(false);
  const [rolePermissions, setRolePermissions] = useState(null);
  const [roleId, setRoleId] = useState("");
  const [data, setData] = useState({});

  const [pageState, updatePageState] = useReducer(
    (prev, next) => {
      if (next?.currentPage) {
        fetchRoles(next?.currentPage);
      }
      return { ...prev, ...next };
    },
    {
      roles: [],
      isActiveSpinner: false,
      editRoleId: null,
      total: 0,
      defaultCurrentPage: 1,
      currentPage: 1,
      pageSize: 10,
    }
  );

  async function fetchRoles(page) {
    dispatch(setLoading(true));
    try {
      const { value } = await props.getRolesByOperator({ page, size: 10 });
      if (value?.success) {
        updatePageState({
          total: value?.total,
          roles: value["roles"],
        });
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  }

  useEffect(() => {
    fetchRoles(1);
  }, []);

  const handleDeleteClick = (id) => {
    setDeleteModal(true);
    setRoleId(id);
  };
  const handleDeleteRole = async () => {
    dispatch(setLoading(true));
    try {
      const { value } = await props.deleteRole(roleId);
      if (value?.success) {
        fetchRoles(1);
        setDeleteModal(false);
        dispatch(setAlert("success", value?.message));
      } else {
        dispatch(setAlert("error", value?.message));
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
    }
    dispatch(setLoading(false));
  };

  const changeRoleStatus = async (roleId, status, index) => {
    updatePageState({ isActiveSpinner: index });
    try {
      const { value } = await props.updateRole(roleId, {
        status,
      });
      if (value?.success) {
        fetchRoles(pageState?.currentPage);
        updatePageState({ isActiveSpinner: false });
      } else {
        dispatch(setAlert("error", value?.message));
        updatePageState({ isActiveSpinner: false });
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
      updatePageState({ isActiveSpinner: false });
    }
  };

  return (
    <>
      <section className="mainWrapper">
        <Header />
        <div className="main-wrapper" id="profile-wrapper">
          <h3>
            <img
              src={leftArrow}
              className="left-arrow"
              onClick={() => navigate(-1)}
            />
            Roles
          </h3>
          <div
            className="offence-wrapper"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <div className="offence-list">
              <button
                className="btn DarkBtn"
                onClick={() => setDialogOpen(true)}
              >
                Create Role
              </button>
            </div>
          </div>
          {pageState.roles?.length > 0 ? (
            <div className="lotsWrapper ">
              <div className="table-responsive">
                <table className="table offence-table StripTable">
                  <thead>
                    <tr>
                      <th>Title</th>
                      <th>Status</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageState["roles"]?.map((role, index) => {
                      return (
                        <tr key={role._id}>
                          <td>{role?.["title"]}</td>
                          <td>
                            <div
                              className={
                                role?.["status"] === "active"
                                  ? "green LabelStatus"
                                  : "red LabelStatus"
                              }
                            >
                              {role?.["status"] === "active"
                                ? "Active"
                                : "Inactive"}
                            </div>
                          </td>
                          <td>
                            <div className="d-flex justify-content-center">
                              <button
                                className="ActionsButtons"
                                onClick={() => {
                                  setIsView(true);
                                  setData(role);
                                  setEditDialogOpen(true);
                                  // setRoleId(role._id);
                                  // setRolePermissions(role.permissions);
                                  // setPermissionDialogOpen(true);
                                }}
                              >
                                <img src={DarkView} className="DarkImg" />
                                <img src={WhiteView} className="WhiteImg" />
                              </button>
                              <button
                                className="ActionsButtons"
                                onClick={() => {
                                  setData(role);
                                  setEditDialogOpen(true);
                                }}
                              >
                                <img
                                  src={DarkViewEdit}
                                  className="DarkImg"
                                  alt="Edit"
                                />
                                <img
                                  src={WhiteViewEdit}
                                  className="WhiteImg"
                                  alt="Edit"
                                />
                              </button>
                              <button
                                className="ActionsButtons"
                                onClick={() => handleDeleteClick(role?._id)}
                              >
                                <img
                                  src={DarkViewDelete}
                                  className="DarkImg"
                                  alt="Delete"
                                />
                                <img
                                  src={WhiteViewDelete}
                                  className="WhiteImg"
                                  alt="Delete"
                                />
                              </button>

                              <button
                                className="your-lots-btn form-check form-switch"
                                onClick={() => {
                                  changeRoleStatus(
                                    role?._id,
                                    role?.["status"] === "active"
                                      ? "deactivate"
                                      : "active",
                                    index
                                  );
                                }}
                              >
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    id="togBtn"
                                    checked={role?.["status"] === "active"}
                                  />
                                  <div className="slider round"></div>
                                </label>
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="pagination-block">
                <Pagination
                  hideOnSinglePage
                  defaultCurrent={pageState?.defaultCurrentPage}
                  onChange={(page) => updatePageState({ currentPage: page })}
                  current={pageState?.currentPage}
                  pageSize={pageState?.pageSize}
                  total={pageState?.total}
                  showSizeChanger={false}
                />
              </div>
            </div>
          ) : (
            <NoResultFound msgType="Role" isSearchResult={false} />
          )}
        </div>
      </section>

      <RoleDeleteModal
        show={deleteModal}
        onClose={() => setDeleteModal(false)}
        onSubmit={handleDeleteRole}
        title="Role"
      />
      <CreateRole
        updateParent={fetchRoles}
        addRole={addRole}
        dialogOpen={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
      <UpdateRole
        updateParent={fetchRoles}
        data={data}
        updateRole={updateRole}
        dialogOpen={editDialogOpen}
        onClose={() => {
          setIsView(false);
          setEditDialogOpen(false);
        }}
        isViewOnly={isView}
      />
      <PermissionsDialogBox
        dialogOpen={permissionDialogOpen}
        onClose={() => {
          setPermissionDialogOpen(false);
        }}
        PermissionsData={rolePermissions}
      />
    </>
  );
}
