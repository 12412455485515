import React, {useState, useEffect} from "react";
import "./style.css"

const CircularProgressBar = ({ percentage }) => {
  return (
    <div className="circular-progress-bar" style={{ '--percentage': `${percentage}%` }}>
      <p>{`${percentage}%`}</p>
    </div>
  );
};

export default CircularProgressBar