import { Record } from "immutable";
import { assign } from "lodash";
import { INIT, LOADING, SUCCESS, ERROR } from "../../constants/phase";

// import { fromPromise } from 'rxjs/observable/fromPromise'
import { from } from "rxjs";

import { of } from "rxjs";
import { mergeMap, flatMap, catchError } from "rxjs/operators";
import { ofType, combineEpics } from "redux-observable";

import * as api from "./api";

/***********************************
 * Action Types
 ***********/
// const cookies = new Cookies()

// PHASE START
export const LOGIN = "home/LOGIN"
export const USER = "home/USER"
export const TOKEN = "home/TOKEN"

export const UPDATEUSER = "home/USER"

export const QUIZ = "home/QUIZ"
export const TRAININGVIDEO = "home/TRAININGVIDEO"
export const FORGOTPASSW = "home/FORGOTPASSW"
export const NEWPASSWORD = "home/NEWPASSWORD"
export const LOTPROVIDER = "home/LOTPROVIDER"
export const DELETEYOURLOT = "home/DELETEYOURLOT"
export const CREATEOPRTRSIGNUP = "home/CREATEOPRTRSIGNUP"
export const ADDLOTS = "home/ADDLOTS"
// export const GETLOTS = "home/GETLOTS"
export const TAGGR_DASHBOARD_LOGIN = "home/TAGGR_DASHBOARD_LOGIN";
export const TAGGR_DASHBOARD_LOGIN_ERROR = "home/TAGGR_DASHBOARD_LOGIN_ERROR";
export const TAGGR_DASHBOARD_LOGIN_SUCCESS = "home/TAGGR_DASHBOARD_LOGIN_SUCCESS";
export const PROVIDERS = "home/PROVIDERS";
export const GET_LOT_BY_ID = "home/GET_LOT_BY_ID";
export const GET_ALL_LOTS_LIST = "home/GET_ALL_LOTS_LIST";
export const UPDATE_LOT_STATUS = "home/UPDATE_LOT_STATUS";
export const UPDATE_GEOFENCE = "home/UPDATE_GEOFENCE";
export const CONNECT_WITH_STRIPE = "home/CONNECT_WITH_STRIPE";
export const ADD_PERMIT = "home/ADD_PERMIT";
export const GET_ALL_ZONES = "home/GET_ALL_ZONES"
export const GET_ZONE_INVENTORY = "home/GET_ZONE_INVENTORY"

export const CONTACT_SUPPORT = "home/CONTACT_SUPPORT";
export const EDITPROFILE = "home/EDITPROFILE"

export const DELETE_PERMIT = "home/ADD_PERMIT";
export const ADD_OFFENCE = "home/ADD_OFFENCE";
export const GET_OFFENCE = "home/GET_OFFENCE";
export const GET_ACTIVE_OFFENCE = "home/GET_ACTIVE_OFFENCE";
export const UPDATE_OFFENCE = "home/UPDATE_OFFENCE";
export const ENFORCEMENT_DETAILS = "home/ENFORCEMENT_DETAILS";
export const DELETE_OFFENCE = "home/DELETE_OFFENCE";
export const GET_PERMIT = "home/ADD_PERMIT";

export const GET_PERMITALL = "home/ADD_PERMIT";

export const ALLCITATIONS = "home/ALLCITATIONS";
export const CITATIONRECONCILIATION = "home/CITATIONRECONCILIATION";
export const ALL_DISPUTED_CITATIONS = "home/ALL_DISPUTED_CITATIONS";
export const GET_DISPUTED_CITATION_BY_ID = "home/GET_DISPUTED_CITATION_BY_ID";
export const SEARCH_DISPUTED_CITATIONS = "home/SEARCH_DISPUTED_CITATIONS";
export const ALL_DISPUTED_CITATIONS_OPERATOR = "home/ALL_DISPUTED_CITATIONS_OPERATOR";
export const GET_DISPUTED_CITATION_BY_ID_OPERATOR = "home/GET_DISPUTED_CITATION_BY_ID_OPERATOR";
export const SEARCH_DISPUTED_CITATIONS_OPERATOR = "home/SEARCH_DISPUTED_CITATIONS_OPERATOR";
export const SEARCH_FOR_CITATIONS = "home/SEARCH_FOR_CITATIONS";
export const SEARCH_FOR_REFUND_REQUEST = "home/SEARCH_FOR_REFUND_REQUEST";
export const GET_SINGLE_CITATION_DETAILS = "home/GET_SINGLE_CITATION_DETAILS";

export const OPERATORCITATION = "home/OPERATORCITATION";

export const GETUSERS = "home/GETUSERS";
export const GETUSERDETAILS = "home/GETUSERDETAILS";
export const GETLOTDETAILS = "home/GETLOTDETAILS";

export const GETTAGGRDETAILS = "home/GETTAGGRDETAILS";
export const GETOPERATOR_TAGGRVISIT = "home/GETOPERATOR_TAGGRVISIT";

export const STATISTICS_OPERATOR = "home/STATISTICS_OPERATOR"
export const STATISTICS_ADMIN = "home/STATISTICS_ADMIN"

export const UPDATE_PERMIT = "home/ADD_PERMIT";
export const BULK_UPLOAD_PERMITS = "home/BULK_UPLOAD_PERMITS";
export const SUBMIT_PROOF = "home/SUBMIT_PROOF";
export const GETPERMITBYID_PERMIT = "home/ADD_PERMIT";
export const GET_EMAIL_BY_BUFFER = "home/GET_EMAIL_BY_BUFFER";
export const CREATE_PASSWORD_BY_OPERATOR = "home/CREATE_PASSWORD_BY_OPERATOR";
export const SEARCH_FOR_OPERATOR = "home/SEARCH_FOR_OPERATOR";
export const REFUND_CITATION = "home/REFUND_CITATION";
export const REFUND_REQUEST = "home/REFUND_REQUEST";
export const GET_REFUND_REQUESTS = "home/GET_REFUND_REQUESTS";
export const APPROVED_REFUND_REQUEST = "home/APPROVED_REFUND_REQUEST";
export const VOID_CITATION = "home/VOID_CITATION";
export const REDUCE_CITATION = "home/REDUCE_CITATION";
export const DENY_CITATION = "home/DENY_CITATION";
export const SEND_SMS = "home/SEND_SMS";
export const PAID_BY_CHEQUE = "home/PAID_BY_CHEQUE";
export const UPDATE_CITATION_VIOLATION = "home/UPDATE_CITATION_VIOLATION";

export const INIT_LOGIN_PHASE = "home/INIT_LOGIN_PHASE";

export const RECONCILATION = "home/RECONCILATION";
export const CURRENT_PAYOUTS = "home/CURRENT_PAYOUTS";
export const PAYOUTRECONCILATION = "home/PAYOUTRECONCILATION";
export const GETPAYOUTDATES = "home/GETPAYOUTDATES";

export const GET_ALL_TIRE_TAGS = "home/GET_ALL_TIRE_TAGS";

export const GET_TAGGR_ACTIVITIES = "home/GET_TAGGR_ACTIVITIES";
// MANAGERS
export const ADD_MANAGER = "home/ADD_MANAGER";
export const GET_MANAGERS = "home/GET_MANAGERS";
export const GET_MANAGER_BY_ID = "home/GET_MANAGER_BY_ID";
export const UPDATE_MANAGER = "home/UPDATE_MANAGER";


export const GET_PAYMENT_RECEIPT = "home/GET_PAYMENT_RECEIPT";
export const ADD_ROLE = "home/ADD_ROLE";
export const GET_ROLES = "home/GET_ROLES";
export const DELETE_ROLE = "home/DELETE_ROLE";
export const UPDATE_ROLE = "home/UPDATE_ROLE";



/***********************************
 * Initial State
 ***********/

// Unlike other ducks we are taking a class style approach
// for creating the InitialState. This is becuase we need to fetch the
// locally stored token in the constructor when it is created
const InitialStateInterface = {
  // We need this here to tell InitialState that there is a token key,
  // but it will be reset below to what is in localStorage, unless a value
  // is passed in when the object is instanciated
  data: {},
  loginPhase: INIT,
  loginData: {},
};

class InitialState extends Record(InitialStateInterface) {
  constructor(desiredValues) {
    // When we construct InitialState, we automatically update it's default value
    // for token to be what is stored in localStorage
    const token = ""; // localStorage.getItem(Config.LocalStorageKeys.Authorization)
    super(assign({ token }, desiredValues));
  }
}

// export const logins = (value) => {
//   return {
//     type: LOGIN,
//     payload: api.logins(value),
//   };
// };

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = new InitialState(), action = {}) {
  switch (action.type) {
    case TAGGR_DASHBOARD_LOGIN: {
      return state.set("loginPhase", LOADING);
    }

    case TAGGR_DASHBOARD_LOGIN_SUCCESS: {
      const { payload } = action;
      return state.set("loginPhase", SUCCESS).set("loginData", payload.results);
    }

    case TAGGR_DASHBOARD_LOGIN_ERROR: {
      return state.set("loginPhase", ERROR);
    }

    case INIT_LOGIN_PHASE: {
      return state.set("loginOtpPhase", INIT);
    }
    default: {
      return state;
    }
  }
}

/***********************************
 * Action Creators
 ***********/

export const logins = (value) => {
  return {
    type: LOGIN,
    payload: api.logins(value),
  };
}

export const token = (value) => {
  return {
    type: TOKEN,
    payload: api.token(value),
  };
}

export const createoprtrsignup = (value) => {
  return {
    type: CREATEOPRTRSIGNUP,
    payload: api.createoprtrsignup(value),
  };
}
export const forgotpassw = (value) => {
  return {
    type: FORGOTPASSW,
    payload: api.forgotpassw(value),
  };
}
export const newpassword = (value) => {
  return {
    type: NEWPASSWORD,
    payload: api.newpassword(value),
  };
}

export const lotprovider = (value) => {
  return {
    type: LOTPROVIDER,
    payload: api.lotprovider(value),
  };
}

export const getAllLotsList = (value) => {
  return {
    type: GET_ALL_LOTS_LIST,
    payload: api.getAllLotsList(value),
  };
}

export const updateLotStatus = (id, value) => {
  return {
    type: UPDATE_LOT_STATUS,
    payload: api.updateLotStatus(id, value)
  }
}

export const updateGeoFence = (id, value) => {
  return {
    type: UPDATE_GEOFENCE,
    payload: api.updateGeoFence(id, value)
  }
}

export const user = (value) => {
  return {
    type: USER,
    payload: api.user(value),
  };
}

export const updateUser = (id, value) => {
  return {
    type: UPDATEUSER,
    payload: api.updateUser(id, value),
  };
}

export const editprofile = (value) => {
  return {
    type: EDITPROFILE,
    payload: api.editprofile(value),
  };
}

export const deleteyourlot = (value) => {
  return {
    type: DELETEYOURLOT,
    payload: api.deleteyourlot(value),
  };
}
export const addlots = (value, id, isEdit) => {
  return {
    type: ADDLOTS,
    payload: api.addlots(value, id, isEdit),
  };
}
// export const getlots = () => {
//   return {
//     type: GETLOTS,
//     payload: api.getlots(),
//   };
// }

export const getProviders = (value) => {
  return {
    type: PROVIDERS,
    payload: api.getProviders(value),
  };
}

export const getLotsById = value => {
  return {
    type: GET_LOT_BY_ID,
    payload: api.getLotsById(value),
  };
}

/**
 * CONNECT WITH STRIPE
 */
export const connectWithStripe = value => {
  return {
    type: CONNECT_WITH_STRIPE,
    payload: api.connectWithStripe(value)
  }
}

export const connectWithStripeExpress = value => {
  return {
    type: CONNECT_WITH_STRIPE,
    payload: api.connectWithStripeExpress(value)
  }
}

/**
 * ADD PERMIT
 */
export const addPermit = value => {
  return {
    type: ADD_PERMIT,
    payload: api.addPermit(value)
  }
}

/*
get permit
*/
export const getPermit = (value, lotId) => {
  return {
    type: GET_PERMIT,
    payload: api.getPermit(value, lotId)
  }
}
export const getPermitAll = (Report) => {
  return {
    type: GET_PERMITALL,
    payload: api.getPermitAll(Report)
  }
}

/* 
delete permit
*/

export const deletePermit = (id) => {
  return {
    type: DELETE_PERMIT,
    payload: api.deletePermit(id)
  }
}
/*
getpermit by id 
*/
export const getPermitbyID = (id) => {
  return {
    type: GETPERMITBYID_PERMIT,
    payload: api.getPermitbyID(id)
  }
}


/*
update permit
*/
export const updatePermit = (id) => {
  return {
    type: UPDATE_PERMIT,
    payload: api.updatePermit(id)
  }
}

/*
Bulk upload permits
*/
export const bulkUploadPermits = (formData) => {
  return {
    type: BULK_UPLOAD_PERMITS,
    payload: api.bulkUploadPermits(formData)
  }
}

export const submitProof = (value) => {
  return {
    type: SUBMIT_PROOF,
    payload: api.submitProof(value)
  }
}


/**
 * ADD OFFENCE
*/
export const addOffence = value => {
  return {
    type: ADD_PERMIT,
    payload: api.addOffence(value)
  }
}

/**
 * GET OFFENCE BY OPERATOR
*/
export const getOffenceByOperator = (value) => {
  return {
    type: GET_OFFENCE,
    payload: api.getOffenceByOperator(value)

  }
}

/**
 * GET ACTIVE OFFENCE BY OPERATOR
*/
export const getActiveOffenceByOperator = () => {
  return {
    type: GET_ACTIVE_OFFENCE,
    payload: api.getActiveOffenceByOperator()

  }
}

/*
update OFFENCE
*/
export const updateOffence = (id, value) => {
  return {
    type: UPDATE_OFFENCE,
    payload: api.updateOffence(id, value)
  }
}

// get enforcement details
export const getEnforcementDetails = () => {
  return {
    type: ENFORCEMENT_DETAILS,
    payload: api.getEnforcementDetails()
  }
}

/**
 * DELETE OFFENCE BY OPERATOR
 */
export const deleteOffence = (id) => {
  return {
    type: DELETE_OFFENCE,
    payload: api.deleteOffence(id)
  }
}

/**
 * DELETE OFFENCE BY OPERATOR
 */
export const getEmailByBuffer = (value) => {
  return {
    type: GET_EMAIL_BY_BUFFER,
    payload: api.getEmailByBuffer(value)
  }
}


/**
 * CREATE PASSWORD FOR OPERATOR
 */
export const createPasswordForOperator = (token, value) => {
  return {
    type: CREATE_PASSWORD_BY_OPERATOR,
    payload: api.createPasswordForOperator(token, value)
  }
}

/**
 * SEARCH API FOR OPERATOR
 */
export const searchForOperator = (searchType, query, citationType) => {
  return {
    type: SEARCH_FOR_OPERATOR,
    payload: api.searchForOperator(searchType, query, citationType)
  }
}

export const refund_citation = (citationId, reason,refund_role,taggrfee, amount) => {
  return {
    type: REFUND_CITATION,
    payload: api.refund_citation(citationId, reason,refund_role,taggrfee, amount)
  }
}

export const refund_request = ({
  citationId,
  reason,
  requestedAmount,
  withholdTaggrFee,
  requestType
}) => {
  return {
    type: REFUND_REQUEST,
    payload: api.refund_request(
      citationId,
      reason,
      requestType,
      requestedAmount,
      withholdTaggrFee
    )
  }
}

export const get_all_refund_requests = (value) => {
  return {
    type: GET_REFUND_REQUESTS,
    payload: api.get_all_refund_requests(value)
  }
}

export const approved_refund_request = (requestId, status) => {
  return {
    type: APPROVED_REFUND_REQUEST,
    payload: api.approved_refund_request(requestId, status)
  }
}

export const search_refund_request = (searchType, query) => {
  return {
    type: SEARCH_FOR_REFUND_REQUEST,
    payload: api.search_refund_request(searchType, query)
  }
}

export const void_citation = (citationId, reason, taggrfee, payment_source_id) => {
  return {
    type: VOID_CITATION,
    payload: api.void_citation(citationId, reason, taggrfee, payment_source_id )
  }
}

export const reduce_citation = (citationId, reason) => {
  return {
    type: REDUCE_CITATION,
    payload: api.reduce_citation(citationId, reason)
  }
}

export const deny_citation = (citationId, reason) => {
  return {
    type: DENY_CITATION,
    payload: api.deny_citation(citationId, reason)
  }
}

export const send_sms = (to,text) => {
  return {
    type: SEND_SMS,
    payload: api.send_sms(to,text)
  }
}

export const paid_by_cheque = (value) => {
  return {
    type: PAID_BY_CHEQUE,
    payload: api.paid_by_cheque(value)
  }
}
export const addManager = (payload) => {
  return {
    type: ADD_MANAGER,
    payload: api.addManager(payload)
  }
}

export const getAllManagers = (payload) => {
  return {
    type: GET_MANAGERS,
    payload: api.getAllManagers(payload)
  }
}

export const getManagerById = (payload) => {
  return {
    type: GET_MANAGER_BY_ID,
    payload: api.getManagerById(payload)
  }
}

export const updateManager = (managerId, payload) => {
  return {
    type: UPDATE_MANAGER,
    payload: api.updateManager(managerId, payload)
  }
}

/***********************************
 * Epics
 ***********************************/
const loginEpic = (action$) =>
  action$.pipe(
    ofType(TAGGR_DASHBOARD_LOGIN),
    mergeMap((action) => {
      return from(api.logins(action.payload)).pipe(
        flatMap((payload) => [
          {
            type: TAGGR_DASHBOARD_LOGIN_SUCCESS,
            payload,
          },
        ]),
        catchError((error) =>
          of({
            type: TAGGR_DASHBOARD_LOGIN_ERROR,
            payload: { error },
          })
        )
      );
    })
  );

export const taggrEpic = combineEpics(loginEpic);


/*contact support */
export const contactsupport = value => {
  return {
    type: CONTACT_SUPPORT,
    payload: api.contactsupport(value)
  }
}

// export const checkin = value => {
//   return {
//     type: CHECKIN,
//     payload: api.checkin(value)
//   }
// }

/*********************************
 * global states for alert and loading 
 **********************************/


const initialState = {
  isLoading: false
}

export const loadingReducer = (state = initialState, action) => {

  if (action.type === 'setLoading') {

    return {
      ...state,
      isLoading: action.payload
    }
  }
  else {
    return state
  }
}


const alertInitialState = {
  message: "",
  status: "hide"
}

export const alertReducer = (state = alertInitialState, action) => {

  if (action.type === 'setAlert') {

    return {
      ...state,
      message: action.payload.message,
      status: action.payload.status
    }
  }
  else {
    return state
  }
}



/************************************************************** 
      ADMIN DASHBORD API INTEGRATION
 *************************************************************/

/*
GET USERS
*/

export const getusers = (role, value) => {
  return {
    type: GETUSERS,
    payload: api.getusers(role, value),
  };
}

/*
GET OPERATOR AND TAGGR VISITS
*/

export const get_operator_taggr_visit = (role, value) => {
  return {
    type: GETOPERATOR_TAGGRVISIT,
    payload: api.get_operator_taggr_visit(role, value),
  };
}

export const getuserdetails = (id, value) => {
  return {
    type: GETUSERDETAILS,
    payload: api.getuserdetails(id, value)
  }
}


export const getlotdetails = (id, value) => {
  return {
    type: GETLOTDETAILS,
    payload: api.getlotdetails(id, value)
  }
}

export const gettaggrdetails = (id, value) => {
  return {
    type: GETTAGGRDETAILS,
    payload: api.gettaggrdetails(id, value)
  }
}

export const allCitations  =(value) => {
  return {
    type: ALLCITATIONS,
    payload: api.allCitations(value)
  }
}

export const citation_reconcilation = (role, page, limit, value) => {
  return {
    type: CITATIONRECONCILIATION,
    payload: api.citation_reconcilation(role, page, limit , value),
  };
}

export const getAllTireTags = () => {
  return {
    type: GET_ALL_TIRE_TAGS,
    payload: api.getAllTireTags()
  }
}

export const searchallCitations = (searchType, query, citationType) => {
  return {
    type: SEARCH_FOR_CITATIONS,
    payload: api.searchallCitations(searchType, query, citationType)
  }
}

export const get_single_citation_details = (id) => {
  return {
    type: GET_SINGLE_CITATION_DETAILS,
    payload: api.get_single_citation_details(id)
  }
}

export const operatoCitation = (value) => {
  return {
    type: OPERATORCITATION,
    payload: api.operatoCitation(value)
  }
}

export const statistics_operator  =(value) => {
  return {
    type: STATISTICS_OPERATOR,
    payload: api.statistics_operator(value)
  }
}

export const statistics_admin  =(value) => {
  return {
    type: STATISTICS_ADMIN,
    payload: api.statistics_admin(value)
  }
}

export const update_citation_violation = (citationId,offenceId) => {
  return {
    type: UPDATE_CITATION_VIOLATION,
    payload: api.update_citation_violation(citationId,offenceId)
  }
}

/*
GET DISPUTED CITATIONS
*/

export const get_all_disputed_citations = ({ page, limit, filter }) => {
  return {
    type: ALL_DISPUTED_CITATIONS,
    payload: api.get_all_disputed_citations({ page, limit, filter }),
  };
}

/*
GET DISPUTED CITATION BY ID
*/

export const get_disputed_citation_by_id = (id) => {
  return {
    type: GET_DISPUTED_CITATION_BY_ID,
    payload: api.get_disputed_citation_by_id(id),
  };
}

/*
SEARCH DISPUTED CITATIONS
*/

export const search_disputed_citations = (query, filter = "") => {
  return {
    type: SEARCH_DISPUTED_CITATIONS,
    payload: api.search_disputed_citations(query, filter),
  };
}

/*
GET DISPUTED CITATIONS OPERATOR
*/

export const get_all_disputed_citations_operator = ({ page, limit }) => {
  return {
    type: ALL_DISPUTED_CITATIONS_OPERATOR,
    payload: api.get_all_disputed_citations_operator({ page, limit }),
  };
}

/*
GET DISPUTED CITATION BY ID OPERATOR
*/

export const get_disputed_citation_by_id_operator = (id) => {
  return {
    type: GET_DISPUTED_CITATION_BY_ID_OPERATOR,
    payload: api.get_disputed_citation_by_id_operator(id),
  };
}

/*
SEARCH DISPUTED CITATIONS OPERATOR
*/

export const search_disputed_citations_operator = (query) => {
  return {
    type: SEARCH_DISPUTED_CITATIONS_OPERATOR,
    payload: api.search_disputed_citations_operator(query),
  };
}

// RECONCILATION

export const reconcilation = (role, value) => {
  return {
    type: RECONCILATION,
    payload: api.reconcilation(role, value),
  };
}

export const current_payout = (role, page, limit, value) => {
  return {
    type: CURRENT_PAYOUTS,
    payload: api.current_payout(role, page, limit, value),
  };
}

export const payout_reconcilation = (role, page, limit, value, userId) => {
  return {
    type: PAYOUTRECONCILATION,
    payload: api.payout_reconcilation(role, page, limit , value, userId),
  };
}

export const get_payout_dates = () => {
  return {
    type: GETPAYOUTDATES,
    payload: api.get_payout_dates(),
  };
}


export const getTaggrActivities  =(value) => {
  return {
    type: GET_TAGGR_ACTIVITIES,
    payload: api.getTaggrActivities(value)
  }
}

export const getPaymentReceipt  =(value) => {
  return {
    type: GET_PAYMENT_RECEIPT,
    payload: api.getPaymentReceipt(value)
  }
}


// zones
export const getAllZones = (value) => {
  return {
    type: GET_ALL_ZONES,
    payload: api.getAllZones(value),
  };
}
export const getZoneInventory = (value) => {
  return {
    type: GET_ZONE_INVENTORY,
    payload: api.getZoneInventory(value),
  };
}



/**
 * ADD ROLE
*/
export const addRole = value => {
  return {
    type: ADD_ROLE,
    payload: api.addRole(value)
  }
}

export const getRolesByOperator = (value) => {
  return {
    type: GET_ROLES,
    payload: api.getRoleByOperator(value)

  }
}

export const updateRole = (id, value) => {
  return {
    type: UPDATE_ROLE,
    payload: api.updateRole(id, value)
  }
}

export const deleteRole = (id) => {
  return {
    type: DELETE_ROLE,
    payload: api.deleteRole(id)
  }
}
