import { connect } from "react-redux";
import { logins, user, allCitations, searchallCitations, refund_citation, void_citation, send_sms } from "../../../store/home/duck";
import AdminMailInCitationsComponent from "./admin_mail_in_citation_component";

const AdminMailInCitationsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,
    user,
    allCitations,
    searchallCitations,
    refund_citation,
    void_citation,
    send_sms
  }
)(AdminMailInCitationsComponent);

export default AdminMailInCitationsContainer;
