import { connect } from "react-redux";
import { user ,updateUser, submitProof} from "../../store/home/duck";

import SubmitProofComponent from "./submit_proof";

const SubmitProofContainer = connect(
  (state) => ({
  }),
  {
    user,
    updateUser,
    submitProof
  }
)(SubmitProofComponent);

export default SubmitProofContainer;
