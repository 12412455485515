import React from "react";
import close from "../../../src/images/Close.svg"

const TextModal = ({ title, show, onClose, onSubmit, phoneNumber, setPhoneNumber }) => {
  return (
    <div>
      <div className={`modalContainer ${show ? "show" : ""}`} onClick={onClose}>
        <div className="modalContent" onClick={(e) => e.stopPropagation()}>
          <div className="modaHeader">
            <h5 className="text-center">{title}</h5>
            <button onClick={onClose} className='RefundCloseHeader'><img src={close} /></button>
          </div>
          <div className="modalBody">
            <h5 className="mb-4">Enter Cellphone Number</h5>
            <div className="TextareaWrapper">
              <textarea onChange={e => setPhoneNumber(e.target.value)} value={phoneNumber} />
            </div>
          </div>
          <div className="modalFooter">
            <button className="btn PrimaryBtn" onClick={onSubmit}>
              Send
            </button>
            <button className="btn PrimaryBtn" onClick={onClose}>
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TextModal;
