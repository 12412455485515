import React, { useEffect, useReducer, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import leftArrow from "../../images/left-arrow.svg";
import Header from "../../components/common/Header/container";
import Form from "react-bootstrap/Form";
import { setAlert } from "../../store/actioncreator";
import { useDispatch } from "react-redux";
import PolygonMap from "../../utils/googleMap/PolygonMap";
export default function EditLotComponent(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [payload, setPayload] = useState(null)
  const initialPayload = {
    coordinates: { lat: null, lang: null },
    isLoading: false
  };

  const [state, updateState] = useReducer((prev, next) => {
    return { ...prev, ...next };
  }, initialPayload);

  useEffect(() => {
    getLotsById();
  }, []);

  async function getLotsById() {
    try {
      const [lotdata] = await Promise.all([props.getLotsById(id)]);
      if (lotdata?.value?.success) {
        const { lot } = lotdata?.value;
        updateState({
          coordinates: lot?.coordinates,
        });
      } else {
        dispatch(setAlert("error", lotdata?.value?.message));
        updateState({ isLoading: false });
      }
    } catch (error) {
      dispatch(setAlert("error", error?.message));
      updateState({ isLoading: false });
    }
  }

  const addGeoCoordinates = async () => {
    updateState({ isLoading: true });
    let err;
    if (!err) {
      updateState({ isLoading: true });
      try {
        const { value } = await props.updateGeoFence(id, {coordinates: payload});
        if (value?.success) {
          updateState(initialPayload);
          dispatch(setAlert("success", value?.message));
          navigate(-1);
        } else {
          updateState({ isLoading: false });
          dispatch(setAlert("error", value?.message));
        }
      } catch (error) {
        updateState({ isLoading: false });
        dispatch(setAlert("error", error?.message));
      }
    }
  };
  return (
    <>
      <section className="mainWrapper">
        <Header />
        <div className="main-wrapper paddzero" id="profile-wrapper">
          <div className="HeadingSection addlote-title">
            <img
              src={leftArrow}
              className="left-arrow"
              onClick={() => navigate(-1)}
            />
            <h4>Add/Update Coordinates</h4>
          </div>
          {state?.isLoading ? (
            <div className="add-lot-block DetailsWrapper">Loading...</div>
          ) : (
            <div className="add-lot-block DetailsWrapper">
              <Form className="row add-lots">
                <PolygonMap
                  latitude={state?.coordinates?.lat}
                  longitude={state?.coordinates?.lng}
                  setPolygonCoords={setPayload}
                />
                <Form.Group
                  className="col-12"
                  controlId="exampleForm.ControlTextarea1"
                >
                  <button
                    type="button"
                    className="btn DarkBtn"
                    onClick={() => addGeoCoordinates()}
                  >
                  {payload ? "Save" : "Save (Radius boundary)"}
                  </button>
                </Form.Group>
              </Form>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
