import { connect } from "react-redux";
import { logins ,user,operatoCitation,searchForOperator,refund_citation, void_citation, getAllLotsList, refund_request} from "../../store/home/duck";
import OperatorCitationsComponent from "./operator_citation_component";

const OperatorCitationsContainer = connect(
  // Map state to props
  (state) => ({
    loginPhase: state.home.loginPhase,
  }),
  // Map actions to dispatch and props
  {
    logins,user,operatoCitation,searchForOperator,refund_citation,void_citation,
    refund_request,
    getAllLotsList
  }
)(OperatorCitationsComponent);

export default OperatorCitationsContainer;
