import { connect } from "react-redux";
import { addOffence, user, getOffenceByOperator, deleteOffence, updateOffence, getEnforcementDetails } from "../../store/home/duck";
import OffenceComponent from "./notices_component";

const OffenceContainer = connect( 
  // Map state to props
  (state) => ({
  }),
  // Map actions to dispatch and props
  {
    user,
    addOffence,
    getOffenceByOperator,
    deleteOffence,
    updateOffence,
    getEnforcementDetails
  }
)(OffenceComponent);

export default OffenceContainer;
